import React, { useContext, useEffect, useState } from "react";
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../atoms/Loader";
import { UserContext } from "../../../context/UserProvider";
import { WorkOrderDataContext } from "../../../context/WorkOrderDataProvider";
import LocationSelectModal from "./LocationSelectModal";
import ModalForm1 from "../../molecules/ModalForm1";

export default function EditUserProfile() {
  let { workorderID } = useParams();
  const [workOrderData, setWorkOrderData] = useState("loading");
  const { errMsg, resetAuthErr } = useContext(UserContext);
  const {
    fetchWorkOrderAsyncNoAdvance,
    fnDeleteSubjectID,
    fetchAllLocationAsync,
    modifyWorkOrderAsync,
  } = useContext(WorkOrderDataContext);
  const [locationNamePreview, setLocatioNamePreview] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [allLocations, setAllLocations] = useState("loading");
  const [locationSearchField, setLocationSearchField] = useState("");
  const [locationField, setLocationField] = useState("");
  const [otherJobTypeField, setOtherJobTypeField] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  // const [jobTypeField, setJobTypeField] = useState("spring startup");
  const navigate = useNavigate();
  /** Delete Modal **/
  const [deleteModal, setDeleteModal] = useState(false);
  const [workOrderToDelete, setWordOrderToDelete] = useState();

  function openModal() {
    setErrorMessage([]); // Reset
    setIsOpen(true);
  }

  const handleNoClick = () => {
    setIsOpen(false);
  };

  const handleYesClick = () => {
    setIsOpen(false);
    fnDeleteSubjectID(workorderID, "/workorders", navigate);
  };
  useEffect(() => {
    resetAuthErr();
    fetchWorkOrderAsyncNoAdvance(setWorkOrderData, workorderID);
    fetchAllLocationAsync(setAllLocations);
  }, []);

  useEffect(() => {
    const types = ["other", "spring startup", "fall shutdown", "normal"];
    if (
      workOrderData.content &&
      !types.includes(workOrderData.content[0].type)
    ) {
      setOtherJobTypeField(workOrderData.content[0].type);
    }

    // console.log(workOrderData.content[0])
  }, [workOrderData]);

  return (
    <Loader loading={workOrderData === "loading"}>
      {errMsg ? (
        errMsg
      ) : (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          {workOrderData.content && (
            <>
              <div className="flex justify-between items-center p-4">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Work Order ID: #
                    {workOrderData.content[0]._id.slice(-5).toUpperCase()}
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Edit Work Order Details Information.
                  </p>
                </div>
                <button
                  className="bg-red-400 text-white px-2 py-1 rounded hover:bg-red-500"
                  onClick={openModal}
                >
                  Delete
                </button>
                <ModalForm1 open={isOpen} setOpen={setIsOpen}>
                  <p className="my-5 text-center font-semibold ">
                    Are you sure?
                  </p>
                  <div className="w-full max-w-xs">
                    <div className="mt-5 float-right">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-8 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={handleNoClick}
                      >
                        No
                      </button>
                      <button
                        type="button"
                        className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-red-600 px-8 py-2 text-sm font-medium text-white hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                        onClick={handleYesClick}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </ModalForm1>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 flex items-center">
                      Location
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <span
                        className="text-blue-500 cursor-pointer"
                        onClick={() => {
                          setLocationModalOpen(true);
                        }}
                      >
                        Click here to select a different location
                      </span>
                      {(locationNamePreview.trim() !== "" && (
                        <div className="mt-1">
                          <span className="text-gray-500">
                            Currently Selected: {locationNamePreview}
                          </span>
                        </div>
                      )) || (
                        <>
                          {workOrderData !== "loading" &&
                            workOrderData.content && (
                              <div className="mt-1">
                                <span className="text-gray-500">
                                  Currently Selected:{" "}
                                  {`${
                                    workOrderData.content[0].location_id
                                      .mailing_address
                                  }${
                                    workOrderData.content[0].location_id.mailing_address_2.trim() !==
                                    ""
                                      ? " " +
                                        workOrderData.content[0].location_id
                                          .mailing_address_2
                                      : ""
                                  }`}
                                </span>
                              </div>
                            )}
                        </>
                      )}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 flex items-center">
                      Job Type
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <select
                        id="type"
                        name="type"
                        className="relative block w-full rounded-none rounded-t-md rounded-b-md border-gray-300 bg-transparent focus:z-10 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        onChange={(e) => {
                          if (e.target.value !== "other") {
                            setOtherJobTypeField("");
                            setWorkOrderData((oldData) => ({
                              content: [
                                {
                                  ...oldData.content[0],
                                  type: e.target.value,
                                },
                              ],
                            }));
                          } else {
                            setWorkOrderData((oldData) => ({
                              content: [
                                {
                                  ...oldData.content[0],
                                  type: e.target.value,
                                },
                              ],
                            }));
                          }
                        }}
                        value={
                          workOrderData.content[0].type == "spring startup" ||
                          workOrderData.content[0].type == "fall shutdown" ||
                          workOrderData.content[0].type == "normal"
                            ? workOrderData.content[0].type
                            : "other"
                        }
                      >
                        <option value="spring startup">Spring Startup</option>
                        <option value="fall shutdown">Fall Shutdown</option>
                        <option value="normal">Normal</option>
                        <option value="other">Other</option>
                      </select>
                    </dd>
                  </div>
                  {workOrderData.content[0].type !== "spring startup" &&
                    workOrderData.content[0].type !== "fall shutdown" &&
                    workOrderData.content[0].type !== "normal" && (
                      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500 flex items-center">
                          Other Job Type
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          <input
                            type="text"
                            name="otherjobfield"
                            id="otherjobfield"
                            onChange={(e) =>
                              setOtherJobTypeField(e.target.value)
                            }
                            value={otherJobTypeField}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </dd>
                      </div>
                    )}
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 flex items-center">
                      Due Date
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <input
                        type="date"
                        name="duedatefield"
                        id="duedatefield"
                        onChange={(e) =>
                          setWorkOrderData((oldData) => ({
                            content: [
                              {
                                ...oldData.content[0],
                                date_due: new Date(
                                  e.target.value
                                ).toISOString(),
                              },
                            ],
                          }))
                        }
                        value={
                          workOrderData !== "loading" &&
                          workOrderData.content[0].date_due !== null &&
                          new Date(workOrderData.content[0].date_due).toISOString().substring(0, 10)
                        }
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 flex items-center">
                      Scheduled Date
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <input
                        type="date"
                        name="scheduleddatefield"
                        id="scheduleddatefield"
                        onChange={(e) =>
                          setWorkOrderData((oldData) => ({
                            content: [
                              {
                                ...oldData.content[0],
                                date_scheduled: new Date(
                                  e.target.value
                                ).toISOString(),
                              },
                            ],
                          }))
                        }
                        value={
                          workOrderData !== "loading" &&
                          workOrderData.content[0].date_scheduled !== null &&
                          workOrderData.content[0].date_scheduled.substring(
                            0,
                            10
                          )
                        }
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 flex items-center">
                      Status
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <select
                        id="status"
                        name="status"
                        className="relative block w-full rounded-none rounded-t-md rounded-b-md border-gray-300 bg-transparent focus:z-10 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        onChange={(e) =>
                          setWorkOrderData((oldData) => ({
                            content: [
                              {
                                ...oldData.content[0],
                                status: parseInt(e.target.value),
                              },
                            ],
                          }))
                        }
                        value={workOrderData.content[0].status}
                      >
                        <option value="0">Not Started</option>
                        <option value="1">In Progress</option>
                        <option value="2">Completed</option>
                      </select>
                    </dd>
                  </div>

                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500 flex items-center">
                      Description
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <textarea
                        id="about"
                        name="about"
                        rows={3}
                        onChange={(e) =>
                          setWorkOrderData((oldData) => ({
                            content: [
                              {
                                ...oldData.content[0],
                                notes: e.target.value,
                              },
                            ],
                          }))
                        }
                        value={workOrderData.content[0].notes}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        placeholder="Write about this work order for the details page and for your driver to see."
                      />
                    </dd>
                  </div>

                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Actions
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 cursor-pointer">
                      <div
                        onClick={() => {
                          let modificationObj = workOrderData.content[0];
                          if (locationField != "") {
                            modificationObj = {
                              ...modificationObj,
                              location_id: locationField,
                            };
                          }
                          if (otherJobTypeField.trim() !== "") {
                            modificationObj = {
                              ...modificationObj,
                              type: otherJobTypeField,
                            };
                          }
                          modifyWorkOrderAsync(
                            workorderID,
                            `/workorders/${workorderID}`,
                            modificationObj,
                            navigate
                          );
                        }}
                        className="mt-1 max-w-2xl text-sm text-green-600 inline-flex justify-content-center mr-5"
                      >
                        <CheckBadgeIcon className="w-4 inline mr-1" />
                        Save
                      </div>
                      <Link
                        to={`/workorders/${workorderID}`}
                        className="mt-1 max-w-2xl text-sm text-red-500 inline-flex justify-content-center"
                      >
                        <XCircleIcon className="w-4 inline mr-1" /> Cancel
                      </Link>
                    </dd>
                  </div>
                </dl>
              </div>
            </>
          )}
        </div>
      )}
      <LocationSelectModal
        setLocationModalOpen={setLocationModalOpen}
        locationModalOpen={locationModalOpen}
        allLocations={allLocations.content}
        locationSearchField={locationSearchField}
        setLocationSearchField={setLocationSearchField}
        openModal={() => {}}
        setLocationField={setLocationField}
        setLocatioNamePreview={setLocatioNamePreview}
      />
    </Loader>
  );
}
