import React, {Fragment, useContext, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import Loader from "../../atoms/Loader";
import ExportExcel from "../../molecules/excel/ExportExcel";
import {PartDataContext} from "../../../context/PartDataProvider";
import UserProvider, {UserContext} from "../../../context/UserProvider";
import ErrorBox from "../../molecules/ErrorBox";
import {Cog6ToothIcon, MagnifyingGlassIcon, PlusIcon} from "@heroicons/react/20/solid";
import ExportExcelLink from "../../molecules/excel/ExportExcelLink";
import Unauthorized from "../Unauthorized";

export default function Parts() {
    const {errMsg, resetAuthErr} = useContext(UserContext);
    let [isOpen, setIsOpen] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const {
        fetchAllPartAsync,
        postNewPart,
        deletePartAsync,
        modifyPartAsyncNoRedirect,
    } = useContext(PartDataContext);
    const {user} = useContext(UserContext);
    const [selectedPart, setSelectedPart] = useState(null);
    const [partToDelete, setPartToDelete] = useState(null);
    const [allPartData, setAllPartData] = useState("loading");
    const [itemDescription, setItemDescription] = useState("");
    const [issues, setIssues] = useState([]);
    const [searchParams, setSearchParams] = useState({itemDescription: ""});

    function closeDelete() {
        setDeleteModal(false);
    }

    function closeModal() {
        setIsOpen(false);
        setIssues([]);
        setItemDescription("");
    }

    function openModal() {
        setItemDescription("");
        setIssues([]);
        setIsOpen(true);
    }

    useEffect(() => {
        fetchAllPartAsync(setAllPartData);
        resetAuthErr();
    }, []);

    useEffect(() => {
        if (selectedPart) {
            setItemDescription(selectedPart.itemDescription);
        } else {
            setItemDescription("");
        }
    }, [selectedPart]);

    return (
        <Loader loading={allPartData === "loading"}>
            {allPartData !== "loading" && user.account_level < 2 ? (
                <>
                    {/*Note, this is entirely trust-based that the client side information is correct. **Technically** they can edit it from the driver side of things already.*/}
                    <Unauthorized />
                </>
            ) : (
                <div className="px-6 z-10 sm:px-6 lg:px-2">
                    <div className="sm:flex mb-4 sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-xl ml-2 font-medium leading-6 text-gray-900">
                                Parts / Repairs
                            </h3>
                        </div>
                        <div className="mt-3 sm:mt-0 sm:ml-4">
                            <label htmlFor="desktop-search-candidate" className="sr-only">
                                Search
                            </label>
                            <div className="flex rounded-md shadow">
                                <div className="relative flex-grow focus-within:z-10">
                                    <div
                                        className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <MagnifyingGlassIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        name="mobile-search-candidate"
                                        id="mobile-search-candidate"
                                        className="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-blue-500 focus:ring-blue-500 sm:hidden"
                                        placeholder="Search"
                                        value={searchParams.itemDescription}
                                        onChange={(e) => {
                                            setSearchParams(oldParams => ({
                                                ...oldParams,
                                                itemDescription: e.target.value
                                            }));
                                        }}
                                    />
                                    <input
                                        type="text"
                                        name="desktop-search-candidate"
                                        id="desktop-search-candidate"
                                        className="hidden w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-blue-500 focus:ring-blue-500 sm:block sm:text-sm"
                                        placeholder="Search parts or repairs"
                                        value={searchParams.itemDescription}
                                        onChange={(e) => {
                                            setSearchParams(oldParams => ({
                                                ...oldParams,
                                                itemDescription: e.target.value
                                            }));
                                        }}
                                    />
                                </div>

                                <button
                                    type="button"
                                    className="relative -ml-px inline-flex items-center border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                    onClick={() => {
                                        setSelectedPart(null);
                                        openModal();
                                    }}
                                >
                                    <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    <span className="ml-1 hidden md:inline">Add Part / Repair</span>
                                </button>
                                <ExportExcelLink
                                    excelData={allPartData.content}
                                    fileName={"Parts_Excel"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-100">
                                        <thead className="bg-white border-b">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-sm font-semibold text-gray-600"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-sm font-semibold text-gray-600"
                                            >
                                                Date Added
                                            </th>
                                            <th scope="col" className="relative px-6 py-3">
                                                <span className="sr-only">Delete</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                        {allPartData !== "loading" &&
                                            allPartData.content &&
                                            allPartData.content.filter(part => part.itemDescription.toLowerCase().includes(searchParams.itemDescription.toLowerCase())).map((part) => (
                                                <tr key={part._id}>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                        {part.itemDescription}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <time dateTime={part.dateAdded}>
                                                            {new Date(part.dateAdded).toDateString()}
                                                        </time>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                setSelectedPart(part);
                                                                openModal();
                                                            }}
                                                            className="z-50 items-center rounded-md border border-gray-300 bg-white px-4 py-1 mr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                                        >
                                                            Edit
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                setPartToDelete(part);
                                                                setDeleteModal(true);
                                                            }}
                                                            className="z-50 items-center rounded-md border border-transparent bg-red-400 text-white py-1.5 px-3 text-sm font-medium leading-4 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                        >
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Transition appear show={deleteModal} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={closeDelete}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black bg-opacity-25"/>
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Dialog.Panel
                                            className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                                            <div className="my-5 text-center font-semibold">
                                                <p>Are you sure?</p>
                                            </div>

                                            <div className="mt-10 flex justify-center  ">
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-8 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={closeDelete}
                                                >
                                                    No
                                                </button>
                                                <button
                                                    type="button"
                                                    className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-red-600 px-8 py-2 text-sm font-medium text-white hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                                    onClick={() => {
                                                        if (partToDelete) {
                                                            deletePartAsync(partToDelete._id);
                                                            setAllPartData((oldParts) => ({
                                                                ...oldParts,
                                                                content: oldParts.content.filter(
                                                                    (_part) => _part._id !== partToDelete._id
                                                                ),
                                                            }));
                                                        }
                                                        closeDelete();
                                                        setPartToDelete(null);
                                                    }}
                                                >
                                                    Yes
                                                </button>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>

                    <Transition appear show={isOpen} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={closeModal}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black bg-opacity-25"/>
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Dialog.Panel
                                            className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-lg font-medium leading-6 text-gray-600"
                                            >
                                                {selectedPart ? "Edit Item" : "Add Item"}
                                            </Dialog.Title>

                                            {/* form */}
                                            <div>
                                                <label
                                                    htmlFor="name"
                                                    className="mt-3 block text-sm font-medium text-gray-700"
                                                >
                                                    Item Name
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        onChange={(e) => setItemDescription(e.target.value)}
                                                        value={itemDescription}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                        placeholder="Enter Part/Repair Name"
                                                    />
                                                </div>
                                                {issues.length > 0 && <ErrorBox errorsArr={issues}/>}
                                            </div>

                                            <div className="mt-4 float-right">
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={closeModal}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={() => {
                                                        let okay = true;
                                                        setIssues([]);
                                                        if (okay) {
                                                            if (selectedPart && selectedPart !== null) {
                                                                modifyPartAsyncNoRedirect(selectedPart._id, {
                                                                    itemDescription,
                                                                });
                                                                setAllPartData("loading");
                                                                fetchAllPartAsync(setAllPartData);
                                                            } else {
                                                                postNewPart(setAllPartData, {
                                                                    itemDescription: itemDescription,
                                                                });
                                                            }
                                                            closeModal();
                                                        }
                                                    }}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                </div>
            )}
        </Loader>
    );
}
