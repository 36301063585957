import React, {Fragment, useContext, useEffect, useState} from "react";
import {BuildingOffice2Icon, HomeIcon, PaperClipIcon, UserIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {Dialog, Transition} from "@headlessui/react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Loader from "../../atoms/Loader";
import {UserContext} from "../../../context/UserProvider";
import {LocationDataContext} from "../../../context/LocationDataProvider";
import EmptyContentPage from "../../templates/EmptyContentPage";
import ContentPageHeader from "../../organisms/ContentPageHeader";
import {
  formatEventTypeRaw,
  tempTimeline,
} from "../../../context/EventTypeDataFormatUtil";

const attachedCustomers = [
  {
    name: "Logan Johnson",
    _id: "#",
  },
  {
    name: "Christian Van Loon",
    _id: "#",
  },
];

export default function LocationProfile() {
  let {locationID} = useParams();
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState("loading");
  const [locationComments, setLocationComments] = useState("loading");
  const [locationCustomers, SetLocationCustomers] = useState("loading");
  const [locationWorkOrders, setLocationWorkOrders] = useState("loading");
  const [timeline, setTimeline] = useState(tempTimeline);
  const [inputNote, setInputNote] = useState("");
  const {
    fetchLocationAsync,
    fetchAllLocationComments,
    postLocationComment,
    deleteLocationComment,
    fetchLocationCustomersAsync,
    fetchAllLocationWorkOrders,
    modifyLocationAsyncNon,
  } = useContext(LocationDataContext);
  const {user} = useContext(UserContext);
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);
  let [isOpen, setIsOpen] = useState(false);

  function closeDelete() {
    setDeleteModal(false);
  }

  function openDeleteModal() {
    setIsOpen(true);
  }

  function closeDeleteModal() {
    setIsOpen(false);
  }

  const handleToggleLocation = () => {
    modifyLocationAsyncNon(locationID, {
      active_location: !userData.content[0].active_location,
    });
    setUserData((oldData) => ({
      ...oldData,
      content: [
        {
          ...userData.content[0],
          active_location: !userData.content[0].active_location,
        },
      ],
    }));
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    formatEventTypeRaw(timeline);
    fetchLocationAsync(setUserData, locationID);
    fetchAllLocationComments(setLocationComments, locationID);
    fetchLocationCustomersAsync(SetLocationCustomers, locationID);
    fetchAllLocationWorkOrders(setLocationWorkOrders, locationID);
  }, []);

  useEffect(() => { /* Scroll to bottom of notes section whenever it updates. Always */
    if (document.getElementById("scrollbox")) { /* Prevent issues with div not being rendered yet */
      document.getElementById("scrollbox").scrollTop = document.getElementById("scrollbox").scrollHeight;
    }
  }, [locationComments]);

  const submitLocationComment = (locationID, message) => {
    postLocationComment(setLocationComments, {
      user_ref: user._id,
      location_ref: locationID,
      content: message,
    });
    setInputNote("");
  };

  return (
    <Loader loading={userData === "loading"}>
      {userData.content && (
        <EmptyContentPage
          gridCols={2}
          rightCol={
            <section aria-labelledby="notes-title">
              <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="notes-title"
                      className="text-lg font-medium text-gray-900"
                    >
                      Notes
                    </h2>
                  </div>
                  <Loader loading={locationComments === "loading"}>
                    {locationComments.content &&
                      locationComments.content.length > 0 &&
                      locationComments !== "loading" && (
                        <div className="px-4 py-6 sm:px-6 max-h-60 min-h-60 overflow-y-auto"
                             id={"scrollbox"}>
                          <ul role="list" className="space-y-8">
                            {locationComments.content.map((comment) => (
                              <li key={comment._id}>
                                <div className="flex space-x-3">
                                  <div className="flex justify-between w-full">
                                    <div>
                                      <div className="text-sm">
                                        <a
                                          href="#"
                                          className="font-medium text-gray-900"
                                        >
                                          {comment.user_ref.name}
                                        </a>
                                      </div>
                                      <div className="mt-1 text-sm text-gray-700">
                                        <p>{comment.content}</p>
                                      </div>
                                      <div className="mt-2 space-x-2 text-sm">
                      <span className="font-medium text-gray-500">
                        {new Date(
                          comment.dateAdded
                        ).toDateString()}{" "}
                        (
                        {new Date(
                          comment.dateAdded
                        ).toLocaleTimeString()}
                        )
                      </span>
                                      </div>
                                    </div>
                                    <XMarkIcon
                                      className="h-4 w-4 text-gray-400 cursor-pointer"
                                      onClick={() => {
                                        setCommentToDelete(comment);
                                        openDeleteModal();
                                      }}
                                    />
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    {locationComments.content &&
                      locationComments.content.length === 0 && (
                        <>
                          <div className="text-center py-8">
                            <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                vectorEffect="non-scaling-stroke"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                              />
                            </svg>
                            <h3 className="mt-2 text-sm font-medium text-gray-900">
                              No Notes
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                              This location has no active notes on profile.
                            </p>
                          </div>
                        </>
                      )}
                  </Loader>



                </div>
                <div className="bg-gray-50 px-4 py-6 sm:px-6">
                  <div className="flex space-x-3">
                    <div className="min-w-0 flex-1">
                      <form action="#">
                        <div>
                          <label htmlFor="comment" className="sr-only">
                            About
                          </label>
                          <textarea
                            id="comment"
                            name="comment"
                            rows={3}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            placeholder="Add a note"
                            value={inputNote}
                            onChange={(e) => setInputNote(e.target.value)}
                          />
                        </div>
                        <div className="mt-3 flex items-center justify-between">
                          <button
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault();
                              submitLocationComment(locationID, inputNote);
                            }}
                            className="inline-flex items-end ml-auto justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          >
                            Submit new note
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          }
          header={
            <ContentPageHeader
              name={userData.content[0].mailing_address}
              dateAdded={userData.content[0].date_added}
              rightCol={
                <>
                  <button
                    type="button"
                    onClick={() => navigate(`/locations/edit/${locationID}`)}
                    className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      if (userData.content[0].active_location) {
                        openModal();
                      } else {
                        handleToggleLocation();
                      }
                    }}
                    className={`inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100`}
                  >
                    Toggle Location{" "}
                    {userData.content[0].active_location
                      ? "Inactive"
                      : "Active"}
                  </button>
                </>
              }
            >
              Location Since
            </ContentPageHeader>
          }
          timeline={timeline}
        >
          {/* Description list*/}
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="applicant-information-title"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Location Information
                </h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Personal information and work details.
                </p>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <Loader loading={locationCustomers === "loading"}>
                    {locationCustomers !== "loading" &&
                      locationCustomers &&
                      locationCustomers.content &&
                      locationCustomers.content.length > 0 && (
                        <>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Phone
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {locationCustomers.content[0].customer_ref
                                .phone !== ""
                                ? locationCustomers.content[0].customer_ref
                                  .phone
                                : "No phone set."}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Email
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {locationCustomers.content[0].customer_ref
                                .email !== ""
                                ? locationCustomers.content[0].customer_ref
                                  .email
                                : "No email set."}
                            </dd>
                          </div>
                        </>
                      )}
                  </Loader>

                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Address
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {userData.content[0].mailing_address !== "" ? (
                        <>
                          {userData.content[0].mailing_address}{" "}
                          {userData.content[0].mailing_address2 &&
                            userData.content[0].mailing_address2}
                          <br/>
                          {userData.content[0].mailing_city}{" "}
                          {userData.content[0].mailing_state},{" "}
                          {userData.content[0].mailing_zip}
                        </>
                      ) : (
                        "No address set."
                      )}
                    </dd>
                  </div>

                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Customer Name
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {userData.content[0].name !== "" ? (
                        <>{userData.content[0].name}</>
                      ) : (
                        "No name set"
                      )}
                    </dd>
                  </div>

                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Customer Phone
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {userData.content[0].phone !== "" ? (
                        <>{userData.content[0].phone}</>
                      ) : (
                        "No phone set"
                      )}
                    </dd>
                  </div>

                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Customer Email
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {userData.content[0].email !== "" ? (
                        <>{userData.content[0].email}</>
                      ) : (
                        "No Email set"
                      )}
                    </dd>
                  </div>

                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Customer Status
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {/*#176 Start*/}
                      {userData.content[0].status && userData.content[0].status !== "" ? (
                        <>{userData.content[0].status}</>
                      ) : (
                        "Not Paid"
                      )}
                    </dd>
                  </div>

                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500 flex items-center justify-between">
                      Attached Work Orders
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <Loader loading={locationWorkOrders === "loading"}>
                        {locationWorkOrders !== "loading" &&
                          locationWorkOrders.content && (
                            <ul
                              role="list"
                              className="divide-y divide-gray-200 rounded-md border border-gray-200"
                            >
                              {locationWorkOrders.content.map((workorder) => (
                                <li
                                  key={workorder._id}
                                  className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                                >
                                  <div className="flex w-0 flex-1 items-center">
                                    <PaperClipIcon
                                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <span className="ml-2 w-0 flex-1 truncate">
                                      {workorder._id}
                                    </span>
                                  </div>
                                  <div className="ml-4 flex-shrink-0">
                                    <Link
                                      to={`/workorders/${workorder._id}`}
                                      className="font-medium text-blue-600 hover:text-blue-500"
                                    >
                                      View
                                    </Link>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                      </Loader>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </section>
        </EmptyContentPage>
      )}
      {/* modal */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeDelete}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25"/>
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="my-5 text-center font-semibold">
                    <p>Are you sure?</p>
                  </div>

                  <div className="mt-10 flex justify-center  ">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-8 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeDeleteModal}
                    >
                      No
                    </button>
                    <button
                      type="button"
                      className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-red-600 px-8 py-2 text-sm font-medium text-white hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={async () => {
                        if (commentToDelete) {
                          setLocationComments((oldComments) => ({
                            ...oldComments,
                            content: oldComments.content.filter((_comment) => _comment._id !== commentToDelete._id),
                          }));
                          await deleteLocationComment(commentToDelete._id);
                          closeDeleteModal();
                        }
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShowModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25"/>
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="my-5 text-center font-semibold">
                    <p>Are you sure?</p>
                  </div>

                  <div className="mt-10 flex justify-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-8 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => setShowModal(false)}
                    >
                      No
                    </button>
                    <button
                      type="button"
                      className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-red-600 px-8 py-2 text-sm font-medium text-white hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={handleToggleLocation}
                    >
                      Yes
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Loader>
  );
}
