export default function TextInput({label = "", name = "", id = "", placeholder = "", value = "", onChange = ""}) {
    return (
        <div
            className="relative rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600">
            <label
                htmlFor={name}
                className="absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900">
                {label}
            </label>
            <input
                type="text"
                name={name}
                value={value}
                id={id !== "" ? id : name}
                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    )
}
