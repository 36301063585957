import {fnPostToSubject, fnDeleteSubjectID, fnModifyAsyncNoRedirect, fnFetchSubjectID} from './CrudContextProviderUtil';
import React from 'react';
import axios from 'axios';
export const UserDataContext = React.createContext();

const userAxios = axios.create();

userAxios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
})

userAxios.interceptors.response.use(response => {
    if (response.data.isActive === false) {
        response.data = {};
    }
    return response;
})

export default function UserDataProvider(props) {

    const getUserById = (userID, setFunc) => {
        userAxios.get(`/api/user/${userID}`)
        .then(res => {
            setFunc(res.data);
        })
        .catch(err => console.log(err));
    }

    const getAllUsers = (setFunc) => {
        userAxios.get(`/api/user`)
        .then(res => {
            if(!res.data.errMsg) {
                res.data.content = res.data.content.filter(user => user.account_level < 3);
            }
            setFunc(res.data);
        })
        .catch(err => console.log(err));
    }
    

    /**
     * Note: This could be redefined on server to be more restrictive, this was for MVP usage specifically to push a working app.
     * @param {Identifier} userID User ID in database
     * @param {Object} data Modification Object
     * @param {Object} navigate History object for redirect
     * @param {String} redirectURL RedirectURL upon completion
     */
    const modifyUserAsync = (userID, data, redirectURL, navigate) =>{
        userAxios.put(`/api/user/${userID}`, data)
        .then(res => {
            // <Navigate to={redirectURL} />
            navigate(redirectURL);
        })
        .catch(err => console.log(err));
    }


    return (
        <UserDataContext.Provider value={ 
            {
                getUserById,
                getAllUsers,
                modifyUserAsync,
                fnDeleteSubjectID: fnDeleteSubjectID(userAxios, "user"),
                fnCreateNewUser: fnPostToSubject(userAxios, "user"),
                modifyUserPasswordAsyncNoRedirect: fnModifyAsyncNoRedirect(userAxios, "user/changepassword"),
                fetchAllWorkOrdersToday: fnFetchSubjectID(userAxios, "workorder/user/today")
            } }>
            { props.children }
        </UserDataContext.Provider>
    )
}
