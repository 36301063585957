import { Dialog } from "@headlessui/react";
import TextInput from "../../atoms/Inputs/TextInput";
import Loader from "../../atoms/Loader";
import ModalForm1 from "../../molecules/ModalForm1";

const LocationSelectModal = ({setLocationModalOpen, locationModalOpen, allLocations, locationSearchField, setLocationSearchField,openModal,setLocationField,setLocatioNamePreview}) => {
    return ( 
        <ModalForm1 setOpen={setLocationModalOpen} open={locationModalOpen}>
            <div className="mb-5">
                <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Select A Location
                    </Dialog.Title>
                    <div className="mt-2" style={{maxHeight: '450px', overflowY: 'scroll'}}>
                        <Loader loading={allLocations === "loading"}>
                            {allLocations !== "loading" &&
                            <>
                                <div className="my-3 mx-2">
                                    <TextInput 
                                        name="searchLocations"
                                        id="searchLocations"
                                        placeholder="Search Locations"
                                        value={locationSearchField}
                                        onChange={e => setLocationSearchField(e.target.value)}
                                    />
                                </div>
                                <ul
                                    role="list"
                                    className="divide-y divide-gray-200 rounded-md border border-gray-200">
                                    {allLocations && allLocations.filter(location => `${location.name} ${location.mailing_address}${location.mailing_address_2.trim() !== "" ? ' ' + location.mailing_address_2 : ""} ${location.mailing_city} ${location.mailing_state}, ${location.mailing_zip}`.toLowerCase().includes(locationSearchField.toLowerCase())).map((locationItem) => (
                                        <li
                                            key={locationItem._id}
                                            className="flex items-center justify-between py-3 pl-3 pr-4 text-sm cursor-pointer hover:bg-gray-200"
                                            onClick={() => {
                                                setLocatioNamePreview(`${locationItem.name} (${locationItem.mailing_address}${locationItem.mailing_address_2.trim() !== "" ? ' ' + locationItem.mailing_address_2 : ""})`);
                                                setLocationField(locationItem._id);
                                                setLocationModalOpen(false);
                                                openModal();
                                            }}
                                            >
                                            <div className="flex w-0 flex-1 items-center">
                                                <span className="ml-2 w-0 flex-1 truncate"
                                                ><b>{locationItem.name ? locationItem.name : "(Missing Name)"}</b> - {`${locationItem.mailing_address ? locationItem.mailing_address : "(Missing Address)"}${locationItem.mailing_address_2.trim() !== "" ? ' ' + locationItem.mailing_address_2 : ""} ${locationItem.mailing_city ? locationItem.mailing_city : "(Missing City)"} ${locationItem.mailing_state ? locationItem.mailing_state : "(Missing State)"}, ${locationItem.mailing_zip ? locationItem.mailing_zip : "(Missing Zip)"}`}</span>
                                            </div>
                                            <div className="ml-4 flex-shrink-0">
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </>}
                        </Loader>
                    </div>
                </div>
            </div>
            <div
                className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 col-span-2 sm:mt-0 sm:text-sm"
                    onClick={() => {
                        setLocationModalOpen(false);
                        openModal();
                    }}
                    >
                    Done
                </button>
            </div>
        </ModalForm1>
    );
}

export default LocationSelectModal;
