
import React, { useContext, useEffect, useState } from "react";
import DescriptionListRowWithLabel from "../../../molecules/DescriptionListRowWithLabel";
import TextInput from "../../../atoms/Inputs/TextInput";
import Button from "../../../atoms/Buttons/Button";
import LocationSelectModal from "../LocationSelectModal";
import { LocationDataContext } from "../../../../context/LocationDataProvider";
import { useNavigate } from "react-router-dom";
import ErrorBox from "../../../molecules/ErrorBox";
import { WorkOrderDataContext } from "../../../../context/WorkOrderDataProvider";

const CreateMassWorkOrder = () => {
    const [forWhom,setForWhom] = useState("");
    const [locationModalOpen,setLocationModalOpen] = useState(false);
    const [allLocations,setAllLocations] = useState([]);
    const [locationSearchField,setLocationSearchField] = useState("");
    const [locationField,setLocationField] = useState("");
    const [locationNamePreview,setLocationNamePreview] = useState("");
    const [issues, setIssues] = useState([]);
    const navigate = useNavigate();

    const { fetchAllLocationAsync } = useContext(LocationDataContext);

    const { postNewMassJobCreate } = useContext(WorkOrderDataContext);

    useEffect(() => {
      fetchAllLocationAsync(setAllLocations);
    }, []);

    const [postObj, setPostObj] = useState({
      who: "All",
      type: "spring startup",
      othertype: "",
    });

    const handleSubmission = () => {
      if(postObj.type != "other") { setPostObj(oldObj => ({...oldObj, othertype: ""})); }
      console.log("The form:", postObj);

      // setIssues(["Test"]);

      if(issues.length === 0) {
        postNewMassJobCreate(navigate, postObj);
      }
    }

    return(
    <>
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Mass Work Order Creation
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Enter information for mass-work order creation
          </p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <DescriptionListRowWithLabel label={"Who to create mass work orders for"}>
              <select
                id="forwhom"
                name="forwhom"
                className="relative block w-full rounded-none rounded-t-md rounded-b-md border-gray-300 bg-transparent focus:z-10 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                onChange={(e) => {
                  setPostObj({ ...postObj, who: e.target.value });
                }}
                // value={postObj.who}
              >
                <option value="All">All</option>
                <option value="Paid">Paid</option>
                <option value="Not Paid">Not Paid</option>
                <option value="On">On</option>
                <option value="Off">Off</option>
              </select>
            </DescriptionListRowWithLabel>

                {/* {forWhom === "Zip" && (
                <DescriptionListRowWithLabel label={"Create work order for this zip code..."}>
                  <TextInput
                    name="zipCode"
                    placeholder="(enter zip code here...)"
                    // value={postObj.othertype}
                    // onChange={(e) =>
                    //   setPostObj({ ...postObj, othertype: e.target.value })
                    // }
                  />
                </DescriptionListRowWithLabel>
              )} */}

            <DescriptionListRowWithLabel label={"Job Type"}>
              <select
                id="status"
                name="status"
                className="relative block w-full rounded-none rounded-t-md rounded-b-md border-gray-300 bg-transparent focus:z-10 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                onChange={(e) => {
                  // Reset the value of otherType if the type is not other....
                  if(e.target.value != "other") { setPostObj(oldObj => ({...oldObj, othertype: ""})); }
                  setPostObj({ ...postObj, type: e.target.value });
                }}
                value={postObj.type}
              >
                <option value="spring startup">Spring Startup</option>
                <option value="fall shutdown">Fall Shutdown</option>
                <option value="service">Service</option>
                <option value="other">Other</option>
              </select>
            </DescriptionListRowWithLabel>

            {postObj.type !== "spring startup" &&
              postObj.type !== "fall shutdown" &&
              postObj.type !== "service" && (
                <DescriptionListRowWithLabel label={"Job Type"}>
                  <TextInput
                    name="othetype"
                    placeholder="(Enter other type here...)"
                    value={postObj.othertype}
                    onChange={(e) =>
                      setPostObj({ ...postObj, othertype: e.target.value })
                    }
                  />
                </DescriptionListRowWithLabel>
              )}

            <DescriptionListRowWithLabel label={"Due Date (Leave blank for none)"}>
              <input
                type={"date"}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                name="duedate"
                placeholder="(Enter due date here...)"
                // value={postObj.date_due}
                // onChange={(e) =>
                //   setPostObj({ ...postObj, date_due: e.target.value })
                // }
              />
            </DescriptionListRowWithLabel>

            {/* <DescriptionListRowWithLabel label={"Scheduled Date"}>
              <input
                type={"date"}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                name="scheduleddate"
                placeholder="(Enter scheduled date here...)"
                // value={postObj.date_scheduled}
                // onChange={(e) =>
                //   setPostObj({ ...postObj, date_scheduled: e.target.value })
                // }
              />
            </DescriptionListRowWithLabel> */}

            <DescriptionListRowWithLabel label={"About"}>
              <textarea
                id="about"
                name="about"
                rows={3}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                placeholder="Write about this customer for the customer hub page"
                // value={postObj.notes}
                // onChange={(e) =>
                //   setPostObj({ ...postObj, notes: e.target.value })
                // }
              />
            </DescriptionListRowWithLabel>

            {/* <DescriptionListRowWithLabel label={"Assign Job To Driver"}> */}
              {/* <Loader loading={userList === "loading"}>
                {userList && userList !== "loading" && (
                  <select
                    id="status"
                    name="status"
                    className="relative block w-full rounded-none rounded-t-md rounded-b-md border-gray-300 bg-transparent focus:z-10 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    onChange={(e) =>
                      setPostObj({
                        ...postObj,
                        selected_driver: e.target.value,
                      })
                    }
                    value={postObj.selected_driver}
                  >
                    <option key={"base"} value={""}>
                      Select A Driver...
                    </option>
                    {userList.content.map(
                      (user) =>
                        user.account_level < 3 && (
                          <option key={user._id} value={user._id}>
                            {user.name}
                          </option>
                        )
                    )}
                  </select>
                )}
              </Loader> */}
            {/* </DescriptionListRowWithLabel> */}

            <DescriptionListRowWithLabel label={"Actions"}>
              {issues.length > 0 && (
                <div className={"mb-3"}>
                  <ErrorBox errorsArr={issues} />
                </div>
              )}
              <Button
                type="lg"
                color="blue"
                onClick={() => handleSubmission()}
                className="mr-2"
              >
                Create Mass Work Order
              </Button>
              <Button
                type="lg"
                color="red"
                onClick={() => navigate("/workorders/create")}
              >
                Cancel
              </Button>
            </DescriptionListRowWithLabel>
          </dl>
        </div>
      </div>
      <LocationSelectModal
        setLocationModalOpen={setLocationModalOpen}
        locationModalOpen={locationModalOpen}
        allLocations={allLocations.content || []}
        locationSearchField={locationSearchField}
        setLocationSearchField={setLocationSearchField}
        openModal={function () {}}
        setLocationField={setLocationField}
        setLocatioNamePreview={setLocationNamePreview}
      />
    </>)
}

export default CreateMassWorkOrder;
