import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../../context/UserProvider";
import {WorkOrderDataContext} from "../../../../context/WorkOrderDataProvider";
import Loader from "../../../atoms/Loader";
import {Link, useNavigate, useNavigation} from "react-router-dom";
import {scheduleAutoRefresh} from "../../../../context/AutoRefreshScheduler";

export function NavigateJobsComponent({thisJob,navigate}) {
  const {fetchAllWorkOrdersTodayUser} = useContext(WorkOrderDataContext);
  const {user} = useContext(UserContext);
  const [workOrderData, setWorkOrderData] = useState("loading");
  const [prevButtonID, setPrevButtonID] = useState("");
  const [nextButtonID, setNextButtonID] = useState("");
  const [nextButtonText, setNextButtonText] = useState("Next Job");

  useEffect(() => {
    fetchAllWorkOrdersTodayUser(setWorkOrderData, user._id);
    // Note for (05-02-2023): Talked with Anthony on the phone, we're going to comment this out to see how it does for tomorrow without
    // scheduleAutoRefresh(() => {
    //   fetchAllWorkOrdersTodayUser(setWorkOrderData, user._id);
    // }, 300); // Every 5 minutes, refresh Job
  }, []);

  useEffect(() => {
    if(prevButtonID.trim() === "" || nextButtonID.trim() === "") {
      if(workOrderData !== "loading") {
        let indexofWorkorder = workOrderData.content.map(function(e) { return e.workorder_id; }).map(function(e) { return e._id; }).indexOf(thisJob);

        if(indexofWorkorder + 1 < workOrderData.content.length) {
          setNextButtonID(workOrderData.content[indexofWorkorder + 1].workorder_id._id);
          setNextButtonText("Next Job");
        } else {
          setNextButtonID("complete");
          setNextButtonText("Finish Routes");
        }

        if(indexofWorkorder > 0) {
          setPrevButtonID(workOrderData.content[indexofWorkorder - 1].workorder_id._id);
        } else {
          // We are on first page here
        }

      }
    }
  }, [workOrderData]);

    return <div className={workOrderData === "loading" ? "mb-3" : ""}>
      <Loader loading={workOrderData === "loading"}>
        {workOrderData !== "loading" && <>

          <div className="sm:flex sm:items-center sm:justify-between mb-6">
            <h3 className="text-base font-semibold leading-6 text-gray-900">Workorder Progress: {workOrderData.content.map(function(e) { return e.workorder_id; }).map(function(e) { return e._id; }).indexOf(thisJob) + 1} / {workOrderData.content.length}</h3>
            <div className="mt-3 flex sm:ml-4 sm:mt-0">
              {workOrderData.content.map(function(e) { return e.workorder_id; }).map(function(e) { return e._id; }).indexOf(thisJob) > 0 && <a
                  href={`/workorders/${prevButtonID}`}
                  className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Prev Job
              </a>}
              <a
                  href={`/workorders/${nextButtonID}`}
                  className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {nextButtonText}
              </a>
            </div>
          </div>

        </>}

      </Loader>
    </div>;
}