import { useNavigate, useParams } from 'react-router-dom';
import HistoryFeed from '../organisms/HistoryFeed';

const EmptyContentPage = (props) => {
    let {customerID} = useParams();
    const navigate = useNavigate();
    return (
        <div className="min-h-full">
            <main className={`${props.header && "pb-10"}`}>
                {props.header && props.header}
                <div
                    className={`mx-auto mt-8 grid max-w-3xl grid-cols-${props.gridCols ? props.gridCols : 1} gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-${props.rightCol ? props.leftCol ? props.leftCol : "3" : "1"} `}>
                    <div className={`space-y-6 lg:col-span-2 lg:col-start-1`}>
                        {props.children}
                    </div>
                    
                    {props.rightCol && props.rightCol}
                    
                </div>
            </main>
        </div>
    );
}

export default EmptyContentPage;