import React, {useContext, useEffect, useState, Fragment} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {
    CheckCircleIcon,
    ChevronRightIcon,
    EnvelopeIcon,
    XCircleIcon,
    PlusIcon,
} from "@heroicons/react/20/solid";
import {UserDataContext} from "../../context/UserDataProvider";
import Loader from "../atoms/Loader";
import {Link} from "react-router-dom";
import {getAccountAccessLabel} from "../../utils/ReturnText";
import AuthError from "../pages/Login/AuthError";
import ErrorBox from "../molecules/ErrorBox";
import Unauthorized from "../pages/Unauthorized";

export default function UserList({buttonText}) {
    let [isOpen, setIsOpen] = useState(false);

    const {getAllUsers, fnCreateNewUser} = useContext(UserDataContext);
    const [userData, setUserData] = useState("loading");
    const [usernameField, setUsernameField] = useState("");
    const [nameField, setNameField] = useState("");
    const [passwordField, setPasswordField] = useState("");
    const [accessField, setAccessField] = useState("");
    const [errorMessage, setErrorMessage] = useState([]);

    function closeModal() {
        setIsOpen(false);
        setUsernameField("");
        setPasswordField("");
        setNameField("");
        setAccessField("");
        setErrorMessage([]); // Reset
    }

    function openModal() {
        setErrorMessage([]); // Reset
        setIsOpen(true);
    }

    useEffect(() => {
        getAllUsers(setUserData);
    }, []);

    return (
        <Loader loading={userData === "loading"}>
            {userData !== "loading" && userData.errMsg ?
                (<>
                    <Unauthorized />
                </>)
                :
                (<>
                    <div className="mb-4 sm:mt-0 sm:ml-16 sm:flex-none ">
                        <button
                            type="button"
                            onClick={openModal}
                            className="inline-flex items-right justify-right float-right border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                        >
                            <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                            <span className="ml-1 hidden md:inline">Add User</span>
                        </button>
                    </div>
                    <h3 className="text-xl ml-2 font-medium leading-6 text-gray-900 mb-6">
                        Users
                    </h3>
                    <div className="overflow-hidden bg-white shadow sm:rounded-md">
                        <header className="bg-white border-b">
                            <div className="px-4 py-5 sm:px-6"></div>
                        </header>
                        <ul role="list" className="divide-y divide-gray-200">
                            {userData !== "loading" &&
                                userData.content &&
                                userData.content
                                    .filter((user) => user.account_level < 3)
                                    .map((user) => (
                                        <li key={user._id}>
                                            <Link
                                                to={`/users/${user._id}`}
                                                className="block hover:bg-gray-50"
                                            >
                                                <div className="flex items-center px-4 py-4 sm:px-6">
                                                    <div className="flex min-w-0 flex-1 items-center">
                                                        {/* <div className="flex-shrink-0">
                      <img
                        className="h-12 w-12 rounded-full"
                        src={user.profileImg}
                        alt=""
                      />
                    </div> */}
                                                        <div
                                                            className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                            <div className="flex items-center">
                                                                <p className="truncate text-sm font-medium text-blue-600">
                                                                    {user.name} ({user.username})
                                                                </p>
                                                                {/* <p className="mt-2 flex items-center text-sm text-gray-500">
                          <EnvelopeIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          />Something Here
                          <span className="truncate">
                            {user.active}
                          </span>
                        </p> */}
                                                            </div>
                                                            <div className="hidden md:block">
                                                                <div>
                                                                    <p className="text-sm text-gray-900">
                                                                        User Since{" "}
                                                                        <time dateTime={user.member_since}>
                                                                            {new Date(user.member_since).toDateString()}
                                                                        </time>
                                                                    </p>
                                                                    <p className="mt-2 flex items-center text-sm text-gray-500">
                                                                        <CheckCircleIcon
                                                                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"/>
                                                                        Access Level:{" "}
                                                                        {getAccountAccessLabel(user.account_level)}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <ChevronRightIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                        </ul>
                    </div>
                    <Transition appear show={isOpen} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={closeModal}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black bg-opacity-25"/>
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Dialog.Panel
                                            className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-lg font-medium leading-6 text-gray-6 00"
                                            >
                                                Create New User
                                            </Dialog.Title>

                                            {/* form */}
                                            <div>
                                                <label
                                                    htmlFor="name"
                                                    className="mt-3 block text-sm font-medium text-gray-700"
                                                >
                                                    Full Name
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        value={nameField}
                                                        onChange={(e) => setNameField(e.target.value)}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                        placeholder="Full Name"
                                                    />
                                                </div>
                                                <label
                                                    htmlFor="username"
                                                    className="mt-3 block text-sm font-medium text-gray-700"
                                                >
                                                    Username
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="username"
                                                        id="username"
                                                        value={usernameField}
                                                        onChange={(e) => setUsernameField(e.target.value)}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                        placeholder="Username"
                                                    />
                                                </div>
                                                <label
                                                    htmlFor="password"
                                                    className="mt-3 block text-sm font-medium text-gray-700"
                                                >
                                                    Password
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                        value={passwordField}
                                                        onChange={(e) => setPasswordField(e.target.value)}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                        placeholder="Password"
                                                    />
                                                </div>
                                            </div>
                                            {/* date */}
                                            <div className="flex">
                                                <div className="flex-1">
                                                    <div>
                                                        <label
                                                            htmlFor="access"
                                                            className="block mt-3 text-sm font-medium text-gray-700"
                                                        >
                                                            Access Level
                                                        </label>
                                                        <select
                                                            id="access"
                                                            name="access"
                                                            value={accessField}
                                                            onChange={(e) => setAccessField(e.target.value)}
                                                            className="mt-1 mb-3 block w-48 rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                                                            defaultValue="0"
                                                        >
                                                            <option value="" disabled={true}>
                                                                Choose an option
                                                            </option>
                                                            <option value="0">No access</option>
                                                            <option value="1">Driver App Only</option>
                                                            <option value="2">Administrator</option>
                                                        </select>

                                                        {errorMessage.length > 0 && (
                                                            <ErrorBox errorsArr={errorMessage}/>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-4 float-right">
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={closeModal}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={() => {
                                                        setErrorMessage([]); // Reset
                                                        /** Validation */
                                                        !accessField &&
                                                        setErrorMessage((oldErrors) => [
                                                            ...oldErrors,
                                                            "Specify a user account level.",
                                                        ]);
                                                        usernameField.trim() === "" &&
                                                        setErrorMessage((oldErrors) => [
                                                            ...oldErrors,
                                                            "Specify a username.",
                                                        ]);
                                                        passwordField.trim() === "" &&
                                                        setErrorMessage((oldErrors) => [
                                                            ...oldErrors,
                                                            "Specify a password.",
                                                        ]);

                                                        /** Submission */
                                                        let okay = true;
                                                        if (
                                                            usernameField.trim() === "" ||
                                                            passwordField.trim() === "" ||
                                                            nameField.trim() === "" ||
                                                            accessField.trim() === ""
                                                        ) {
                                                            // alert("There were problems with your submission.");
                                                            okay = false;
                                                        }
                                                        if (okay) {
                                                            fnCreateNewUser(setUserData, {
                                                                username: usernameField,
                                                                name: nameField,
                                                                password: passwordField,
                                                                account_level: parseInt(accessField),
                                                            });
                                                            setUsernameField("");
                                                            setPasswordField("");
                                                            setNameField("");
                                                            setAccessField("");
                                                            closeModal();
                                                        }
                                                    }}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                </>)

            }
        </Loader>
    );
}
