import {CheckBadgeIcon} from "@heroicons/react/20/solid";

const CompletePage = () => {
    return (
        <>
            <div className="px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
                <div className="mx-auto max-w-max">
                    <main className="sm:flex">
                        <div className="sm:ml-6">
                            <div className="sm:pl-6">
                                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl"><CheckBadgeIcon className={"tracking-tight text-blue-600 inline"} style={{height: "60px", marginBottom: "4px"}}/> Routes Complete</h1>
                                <p className="mt-1 text-base text-gray-500">If you need to modify a route, then please click the button to go home and select a route to modify one.</p>
                            </div>
                            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                                <a
                                    href="/workorders"
                                    className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                >
                                    Go back home
                                </a>
                                {/*<a*/}
                                {/*    href="https://web-factory.net/contact"*/}
                                {/*    target="_blank"*/}
                                {/*    className="inline-flex items-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"*/}
                                {/*>*/}
                                {/*    Contact support*/}
                                {/*</a>*/}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}

export default CompletePage;
