import {
    CheckIcon,
    HandThumbUpIcon,
    InformationCircleIcon,
    UserIcon
} from '@heroicons/react/20/solid';

export const eventTypes = {
    applied: {
        icon: UserIcon,
        bgColorClass: 'bg-gray-400'
    },
    advanced: {
        icon: HandThumbUpIcon,
        bgColorClass: 'bg-blue-500'
    },
    completed: {
        icon: CheckIcon,
        bgColorClass: 'bg-green-500'
    },
    info: {
        icon: InformationCircleIcon,
        bgColorClass: 'bg-gray-400'
    }
};

export function formatEventTypeRaw (dataObj) {
    if(!dataObj) throw new Error("formatEventTypeRaw[Err]: No Data object specified!"); // Edge Casing, for sanity sake. Please don't feed an empty invoke of the function, but just incase.
    if(typeof dataObj != "object") throw new Error("formatEventTypeRaw[Err]: Data Object must be an object array!"); // Needs to be a data type of object (Array)
    for (let i = 0; i < dataObj.length; i++) {
        console.log(dataObj[i].type);
        switch (dataObj[i].type) {
            case "info":
                dataObj[i].type = eventTypes.info;
                break;
            case "applied":
                dataObj[i].type = eventTypes.applied;
                break;
            case "advanced":
                dataObj[i].type = eventTypes.advanced;
                break;
            case "completed":
                dataObj[i].type = eventTypes.completed;
                break;
            default:
                dataObj[i].type = eventTypes.info;
                break;
        }
    }
    return dataObj;
}

export const tempTimeline = [
    {
        id: 1,
        type: "applied",
        content: 'Customer Created',
        target: '',
        date: 'Sep 20',
        datetime: '2020-09-20'
    }, {
        id: 2,
        type: "advanced",
        content: 'Advanced to phone screening by',
        target: 'Bethany Blake',
        date: 'Sep 22',
        datetime: '2020-09-22'
    }, {
        id: 3,
        type: "completed",
        content: 'Completed phone screening with',
        target: 'Martha Gardner',
        date: 'Sep 28',
        datetime: '2020-09-28'
    }, {
        id: 4,
        type: "advanced",
        content: 'Advanced to interview by',
        target: 'Bethany Blake',
        date: 'Sep 30',
        datetime: '2020-09-30'
    }, {
        id: 5,
        type: "completed",
        content: 'Completed interview with',
        target: 'Katherine Snyder',
        date: 'Oct 4',
        datetime: '2020-10-04'
    }
];
