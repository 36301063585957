import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
// import {Tooltip} from "@mui/material";

const ExportExcelLink = ({ excelData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      {/* <Tooltip title="Export"> */}
      <button
        variant="contained"
        className="relative -ml-px inline-flex items-center border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
        onClick={() => exportToExcel(fileName)}
        color="primary"
        style={{ cursor: "pointer", fontSize: 14 }}
      >
        Export
      </button>
      {/* </Tooltip> */}
    </>
  );
};

export default ExportExcelLink;
