import {Outlet} from "react-router-dom";
import LocationDataProvider from "../context/LocationDataProvider";

const ReportsOutlet = () => {
    return (
        <LocationDataProvider>
            <Outlet/>
        </LocationDataProvider>
    );
}

export default ReportsOutlet;
