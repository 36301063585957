import React, {useState} from "react";
import axios from "axios";
import {
  fnDeleteSubjectID,
  fnFetchAllSubject,
  fnFetchSubjectID,
  fnModifyAsyncNoRedirect,
  fnModifyAsyncRedirect,
  fnPostAsyncRedirect,
  fnPostToSubject
} from "./CrudContextProviderUtil";

export const LocationDataContext = React.createContext();

const userAxios = axios.create();

userAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export const fnModifyLocationCustomer = (
  axiosObj,
  path,
  errorStateSetter = function (e) {
    console.log(e);
  }
) => {
  return (subjectID, postObj, navigate) => {
    axiosObj
      .put(`/api/${path}/${subjectID}`, postObj)
      .then((res) => {
        navigate(`/locations/${subjectID}`);
      })
      .catch((err) => errorStateSetter(err));
  };
};

export default function UserDataProvider(props) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <LocationDataContext.Provider
      value={{
        modifyLocationAsync: fnModifyAsyncRedirect(userAxios, "location"),
        modifyLocationAsyncNon: fnModifyAsyncNoRedirect(userAxios, "location"),
        fetchAllLocationAsync: fnFetchAllSubject(userAxios, "location", e => {
          console.log(e)
        }, setIsLoading),
        fetchLocationAsync: fnFetchSubjectID(userAxios, "location"),
        fetchAllLocationComments: fnFetchSubjectID(userAxios, "location/note/fetchall"),
        postLocationComment: fnPostToSubject(userAxios, "location/note"),
        deleteLocationComment: fnDeleteSubjectID(userAxios, "location/note"),
        postNewLocation: fnPostToSubject(userAxios, "location"),
        fetchLocationCustomersAsync: fnFetchSubjectID(userAxios, "maplocationcustomer/location"),
        fetchAllCustomerAsync: fnFetchAllSubject(userAxios, "customer"),
        modifyCustomerAsyncNon: fnModifyAsyncNoRedirect(userAxios, "customer"),
        fetchAllLocationWorkOrders: fnFetchSubjectID(userAxios, "location/workorders"),
        fetchCustomerDataEditPage: fnFetchSubjectID(userAxios, "location"),
        fnModifyCustomerData: fnModifyLocationCustomer(userAxios, "location"),
        fnDeleteSubjectID: fnDeleteSubjectID(userAxios, "location"),
        fnPostAsyncRedirect: fnPostAsyncRedirect(userAxios, "location"),
        isLoading
      }}
    >
      {props.children}
    </LocationDataContext.Provider>
  );
}
