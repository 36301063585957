import {Navigate, Route, Routes} from "react-router-dom";
import "./App.css";
import ContentList from "./components/molecules/ContentList";
import UserList from "./components/organisms/UserList";
import LocationList from "./components/organisms/LocationList";
import Parts from "./components/pages/Parts/Parts";
import Login from "./components/pages/Login";
import ProtectedRoute from "./utils/ProtectedRoute";
import {UserContext} from "./context/UserProvider";
import {useContext} from "react";
import UserProfile from "./components/pages/Users";
import WorkOrderProfile from "./components/pages/WorkOrders";
import EditUserProfile from "./components/pages/Users/EditUserProfile";
import EditLocationProfile from "./components/pages/Locations/EditLocationProfile";
import EditWorkOrderProfile from "./components/pages/WorkOrders/EditWorkOrderProfile";
import NotFound from "./components/pages/404";
import WorkOrderList from "./components/pages/WorkOrders/WorkOrderList";
import LocationProfile from "./components/pages/Locations";
import CreateNewCustomerAndLocation from "./components/pages/Customers/CreateNewCustomerAndLocation";
import ReportsOutlet from "./routes/ReportsOutlet";
import WorkOrdersOutlet from "./routes/WorkOrdersOutlet";
import UsersOutlet from "./routes/UsersOutlet";
import LocationsOutlet from "./routes/LocationsOutlet";
import PartsOutlet from "./routes/PartsOutlet";
import CreateNewWorkOrder from "./components/pages/WorkOrders/CreateNewWorkOrder";
import CompletePage from "./components/pages/WorkOrders/DriverApp/CompletePage";
import MassWorkOrderCreatePage from "./components/pages/WorkOrders/CreateMassWorkOrder";

const App = () => {
    const {token} = useContext(UserContext);
    return (
        <Routes>
            <Route path="/login" element={token ? <Navigate to="/workorders"/> : <Login/>}/>
            <Route element={<ProtectedRoute redirectTo={"/login"} token={token}/>}>

                {/* <Route index element={<Home />}/> */}

                <Route index element={<Navigate to="/workorders"/>}/>
                <Route path="parts" element={<PartsOutlet/>}>
                    <Route index element={<Parts/>}/>
                </Route>

                {/*Note: this reports page has issues in JS, needs fixed...*/}
                {/* <Route path="reports" element={<ReportsOutlet/>}>
                    <Route index element={<ContentList/>}/>
                </Route> */}

                <Route path="locations" element={<LocationsOutlet/>}>
                    <Route index element={<LocationList/>}/>
                    <Route path=":locationID" element={<LocationProfile/>}/>
                    <Route path="edit/:locationID" element={<EditLocationProfile/>}/>
                    <Route path="create" element={<CreateNewCustomerAndLocation/>}/>
                </Route>

                <Route path="users" element={<UsersOutlet/>}>
                    <Route index element={<UserList/>}/>
                    <Route path=":userID" element={<UserProfile/>}/>
                    <Route path="edit/:userID" element={<EditUserProfile/>}/>
                </Route>

                <Route path="workorders" element={<WorkOrdersOutlet/>}>
                    <Route index element={<WorkOrderList/>}/>
                    <Route path=":workorderID" element={<WorkOrderProfile/>}/>
                    <Route path="edit/:workorderID" element={<EditWorkOrderProfile/>}/>
                    <Route path="create" element={<CreateNewWorkOrder />}/>
                    <Route path="complete" element={<CompletePage />} />
                    <Route path="masscreate" element={<MassWorkOrderCreatePage />}/>
                </Route>

            </Route>
            <Route path="*" element={token ? <NotFound/> : <Login/>}/>
        </Routes>
    );
};

export default App;
