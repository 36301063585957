import React, { Fragment, useContext, useEffect, useState } from "react";
import {
    CheckCircleIcon,
    ChevronRightIcon,
    Cog6ToothIcon,
    ExclamationCircleIcon,
    MagnifyingGlassIcon,
    PlusIcon,
    XCircleIcon,
} from "@heroicons/react/20/solid";
import { WorkOrderDataContext } from "../../../context/WorkOrderDataProvider";
import Loader from "../../atoms/Loader";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import LocationSelectModal from "./LocationSelectModal";
import SlideboxSlider from "../../molecules/SideboxSlider";
import DropdownChecked from "../../atoms/Inputs/DropdownChecked";
import ErrorBox from "../../molecules/ErrorBox";
import { UserContext } from "../../../context/UserProvider";
import { UserDataContext, userData } from "../../../context/UserDataProvider";
import ModalForm1 from "../../molecules/ModalForm1";
import Pagination from "../../molecules/Pagination";

export default function LocationList() {
    const { fetchAllWorkOrderAsync, postWorkOrderAsync, fetchAllLocationAsync, postNewMassJobCreate, isLoading } =
        useContext(WorkOrderDataContext);
    const { user } = useContext(UserContext);
    const [inputValue, setInputValue] = useState("");
    const [workOrderData, setWorkOrderData] = useState("loading");
    const [locationField, setLocationField] = useState("");
    const [jobStatusField, setJobStatusField] = useState("0");
    const [jobTypeField, setJobTypeField] = useState("spring startup");
    const [otherJobTypeField, setOtherJobTypeField] = useState("");
    const [searchWorkOrder, setSearchWorkOrder] = useState("");
    const [dueDateField, setDueDateField] = useState("");
    const [scheduledDateField, setScheduledDateField] = useState("");
    const [descriptionField, setDescriptionField] = useState("");
    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const [filterListOpen, setFilterListOpen] = useState(false);
    const [allLocations, setAllLocations] = useState("loading");
    const [locationSearchField, setLocationSearchField] = useState("");
    const [locationNamePreview, setLocatioNamePreview] = useState("");
    const [selectedJobTypeFilter, setSelectedJobTypeFilter] = useState(
        JSON.parse(localStorage.getItem("selectedJobTypeFilter")) || { name: "All" }
    );
    const interpretJobStatusName = (statusNum) => {
        switch (statusNum) {
            case 0:
                return "Not Started";
            case 1:
                return "In Progress";
            case 2:
                return "Completed";
            case "All":
                return "All";
            default:
                return statusNum;
        }
    };
    const [jobStatusFilter, setJobStatusFilter] = useState(
        JSON.parse(localStorage.getItem("jobStatusFilter"))
            ? { name: interpretJobStatusName(JSON.parse(localStorage.getItem("jobStatusFilter")).name) }
            : {
                  name: "Not Started",
              }
    );
    const [jobFiledFilter, setJobFiledFilter] = useState(
        JSON.parse(localStorage.getItem("jobFiledFilter")) || { name: "All" }
    );
    const [driverSelectedFilter, setDriverSelectedFilter] = useState(
        JSON.parse(localStorage.getItem("driverSelectedFilter")) || { name: "All" }
    );
    const [sortSelectedFilter, setSortSelectedFilter] = useState(
        JSON.parse(localStorage.getItem("sortSelectedFilter")) || { name: "None" }
    );

    const [issues, setIssues] = useState([]);

    let [isOpen, setIsOpen] = useState(false);

    const [searchParams, setSearchParam] = useState({
        _id: "",
        searchName: "",
        type: JSON.parse(localStorage.getItem("selectedJobTypeFilter"))
            ? JSON.parse(localStorage.getItem("selectedJobTypeFilter")).name
            : "",
        status: JSON.parse(localStorage.getItem("jobStatusFilter"))
            ? JSON.parse(localStorage.getItem("jobStatusFilter")).name
            : 0,
        filed_status: JSON.parse(localStorage.getItem("jobFiledFilter"))
            ? JSON.parse(localStorage.getItem("jobFiledFilter")).name === "Filed"
                ? "true"
                : "false"
            : "",
        driverName: JSON.parse(localStorage.getItem("driverSelectedFilter"))
            ? JSON.parse(localStorage.getItem("driverSelectedFilter")).name
            : "",
        sort: JSON.parse(localStorage.getItem("sortSelectedFilter"))
            ? JSON.parse(localStorage.getItem("sortSelectedFilter")).name
            : "None",
    });
    const [massJobCreate, setMassJobCreate] = useState(false);

    const navigate = useNavigate();

    function resetInputFields() {
        setLocationField("");
        setJobStatusField("0");
        setJobTypeField("spring startup");
        setOtherJobTypeField("");
        setDueDateField("");
        setScheduledDateField("");
        setDescriptionField("");
        setLocationSearchField("");
        setLocatioNamePreview("");
    }

    function closeModal() {
        setIsOpen(false);
        setMassJobCreate(false);
        resetInputFields();
        setIssues([]);
    }

    function openModal() {
        setIssues([]);
        setIsOpen(true);
    }

    const jobFiledFilterOptions = [{ name: "All" }, { name: "Not Filed" }, { name: "Filed" }];

    const [driverSelectedFilterOptions, setDriverSelectedFilterOptions] = useState([{ name: "All" }]);

    const [sortSelectedFilterOptions, setSortSelectedFilterOptions] = useState([
        { name: "None" },
        { name: "Ascending Date" },
        { name: "Descending Date" },
    ]);

    const jobTypeFilterOptions = [
        { name: "All" },
        { name: "Spring Startup" },
        { name: "Fall Shutdown" },
        { name: "Service" },
        // {name: "Other"},
    ];

    const jobStatusFilterOptions = [
        { name: "All" },
        { name: "Not Started" },
        { name: "In Progress" },
        { name: "Completed" },
    ];

    const { getAllUsers } = useContext(UserDataContext);

    const [usersResults, setUsersResults] = useState("loading");

    useEffect(() => {
        fetchAllLocationAsync(setAllLocations);
        getAllUsers(setUsersResults);
    }, []);

    useEffect(() => {
        setDriverSelectedFilterOptions([{ name: "All" }]);
        setTimeout(() => {
            if (usersResults.content && usersResults.content.length > 0) {
                // Logan [12/26/2023 8:05AM]: This can probably be removed. Let's verify the necessity for this to remainn within the code for work order admin.
                // usersResults.content.foreach(user => {
                //     if(user.account_level < 3) {
                //         setDriverSelectedFilterOptions(oldOptions => [
                //             ...oldOptions,
                //             {name: user.name}
                //         ]);
                //     }
                // })
                for (let i = 0; i < usersResults.content.length; i++) {
                    let user = usersResults.content[i];
                    if (user.account_level < 3) {
                        setDriverSelectedFilterOptions((oldOptions) => [...oldOptions, { name: user.name }]);
                    }
                }
            }
        }, 200);
    }, [usersResults]);

    useEffect(() => {
        switch (jobStatusFilter.name) {
            case "Not Started":
                localStorage.setItem("jobStatusFilter", JSON.stringify({ name: 0 }));
                setSearchParam({ ...searchParams, status: 0 });
                break;
            case "In Progress":
                localStorage.setItem("jobStatusFilter", JSON.stringify({ name: 1 }));
                setSearchParam({ ...searchParams, status: 1 });
                break;
            case "Completed":
                localStorage.setItem("jobStatusFilter", JSON.stringify({ name: 2 }));
                setSearchParam({ ...searchParams, status: 2 });
                break;
            case "All":
                localStorage.setItem("jobStatusFilter", JSON.stringify({ name: "All" }));
                setSearchParam({ ...searchParams, status: "All" });
                break;
            default:
                localStorage.setItem("jobStatusFilter", JSON.stringify({ name: 0 }));
                setSearchParam({ ...searchParams, status: 0 });
                break;
        }
    }, [jobStatusFilter]);

    useEffect(() => {
        let x;
        if (selectedJobTypeFilter.name !== "All") {
            x = selectedJobTypeFilter.name.toLowerCase();
        }

        if (x && x !== null) {
            setSearchParam({ ...searchParams, type: x });
            localStorage.setItem("selectedJobTypeFilter", JSON.stringify({ name: x }));
        } else {
            let params = searchParams;
            delete params.type;
            setSearchParam(params);
            fetchAllWorkOrderAsync(setWorkOrderData, searchParams, 50, 1);
            if (localStorage.getItem("selectedJobTypeFilter")) {
                localStorage.removeItem("selectedJobTypeFilter");
            }
        }
    }, [selectedJobTypeFilter]);

    useEffect(() => {
        let newParams = searchParams;
        switch (jobFiledFilter.name) {
            case "All":
                if (searchParams.filed_status) {
                    if (localStorage.getItem("jobFiledFilter")) {
                        localStorage.removeItem("jobFiledFilter");
                    }
                    delete newParams.filed_status;
                    setSearchParam(newParams);
                    fetchAllWorkOrderAsync(setWorkOrderData, searchParams, 50, 1);
                }
                break;
            case "Not Filed":
                setSearchParam((oldParams) => ({ ...oldParams, filed_status: "false" }));
                localStorage.setItem("jobFiledFilter", JSON.stringify({ name: "Not Filed" }));
                break;
            case "Filed":
                setSearchParam((oldParams) => ({ ...oldParams, filed_status: "true" }));
                localStorage.setItem("jobFiledFilter", JSON.stringify({ name: "Filed" }));
                break;
        }
    }, [jobFiledFilter]);

    useEffect(() => {
        let newParams = searchParams;
        switch (driverSelectedFilter.name) {
            case "All":
                if (searchParams.driverName) {
                    if (localStorage.getItem("driverSelectedFilter")) {
                        localStorage.removeItem("driverSelectedFilter");
                    }
                    delete newParams.driverName;
                    setSearchParam(newParams);
                    fetchAllWorkOrderAsync(setWorkOrderData, searchParams, 50, 1);
                }
                break;
            default: /**The driver name */
                setSearchParam((oldParams) => ({ ...oldParams, driverName: driverSelectedFilter.name }));
                localStorage.setItem("driverSelectedFilter", JSON.stringify({ name: driverSelectedFilter.name }));
                break;
        }
    }, [driverSelectedFilter]);

    useEffect(() => {
        // let newParams = searchParams;
        // switch (sortSelectedFilter.name) {
        // default: /**The driver name */
        setSearchParam((oldParams) => ({ ...oldParams, sort: sortSelectedFilter.name }));
        localStorage.setItem("sortSelectedFilter", JSON.stringify({ name: sortSelectedFilter.name }));
        // break;
        // }
    }, [sortSelectedFilter]);

    useEffect(() => {
        fetchAllWorkOrderAsync(setWorkOrderData, searchParams, 50, 1);
    }, [searchParams]);

    const getFormat = (type) => {
        switch (type.trim()) {
            case "spring startup":
                return "Spring Startup";
            case "fall shutdown":
                return "Fall Shutdown";
            case "service":
                return "service";
            default:
                return type;
        }
    };

    return (
        <Loader loading={workOrderData === "loading"}>
            <ModalForm1 setOpen={setMassJobCreate} open={massJobCreate}>
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-6 00">
                    Create Mass Work Order
                </Dialog.Title>

                {/* form */}
                <div>
                    <label htmlFor="type" className="mt-3 block text-sm font-medium text-gray-700">
                        Job Type
                    </label>
                    <div className="mt-1">
                        <select
                            id="type"
                            name="type"
                            className="relative block w-full rounded-none rounded-t-md rounded-b-md border-gray-300 bg-transparent focus:z-10 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            onChange={(e) => {
                                setJobTypeField(e.target.value);
                                if (e.target.value !== "other") {
                                    setOtherJobTypeField("");
                                }
                            }}
                            value={jobTypeField}
                        >
                            <option value="spring startup">Spring Startup</option>
                            <option value="fall shutdown">Fall Shutdown</option>
                            <option value="service">Service</option>
                            <option value="other">Other</option>
                        </select>
                    </div>

                    {jobTypeField === "other" && (
                        <>
                            <label htmlFor="otherjobfield" className="mt-3 block text-sm font-medium text-gray-700">
                                Other Job Type
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="otherjobfield"
                                    id="otherjobfield"
                                    onChange={(e) => setOtherJobTypeField(e.target.value)}
                                    value={otherJobTypeField}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                            </div>
                        </>
                    )}
                    <div className="mt-1">
                        <label htmlFor="MailingAddress" className="mt-3 block text-sm font-medium text-gray-700">
                            Description
                        </label>
                        <textarea
                            id="about"
                            name="about"
                            rows={3}
                            onChange={(e) => setDescriptionField(e.target.value)}
                            value={descriptionField}
                            className="block mb-3 mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            placeholder="Write about this work order for the details page and for your driver to see."
                        />
                        {issues.length > 0 && <ErrorBox errorsArr={issues} />}
                    </div>
                </div>

                <div className="mt-4 float-right">
                    <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={() => {
                            let postObj = {
                                notes: descriptionField,
                            };
                            // JSX can only have 1 parent, so cant use double and in this exp to represent 2 truthy values, defaulting to false to skip.
                            if (jobTypeField.trim() !== "") {
                                postObj.type = jobTypeField.trim();
                            }
                            if (descriptionField.trim() !== "") {
                                postObj.notes = descriptionField.trim();
                            }
                            if (otherJobTypeField.trim() !== "") {
                                postObj.type = otherJobTypeField;
                            }

                            let okay = true;

                            if (jobTypeField.trim() === "") {
                                setIssues((oldIssues) => [...oldIssues, "Specify a Job Type"]);
                            }

                            /** Validation to prevent submission on the form */
                            if (jobTypeField.trim() === "") {
                                // alert("There were problems with your submission.");
                                okay = false;
                            }

                            if (okay) {
                                postNewMassJobCreate(setWorkOrderData, postObj);
                                // Reset Fields
                                resetInputFields();
                                setMassJobCreate(false);
                                alert("This may take up to 5 minutes to take effect.");
                            }
                        }}
                    >
                        Submit
                    </button>
                </div>
            </ModalForm1>

            <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                <h3 className="text-xl ml-2 font-medium leading-6 text-gray-900">Work Orders</h3>

                {user.account_level === 2 || user.account_level === 3 ? (
                    <div className="mt-3 sm:mt-0 sm:ml-4">
                        <label htmlFor="mobile-search-candidate" className="sr-only">
                            Search
                        </label>
                        <label htmlFor="desktop-search-candidate" className="sr-only">
                            Search
                        </label>
                        <div className="flex rounded-md shadow-sm">
                            <div className="relative flex-grow focus-within:z-10">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                                <input
                                    type="text"
                                    name="mobile-search-candidate"
                                    id="mobile-search-candidate"
                                    className="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-blue-500 focus:ring-blue-500 sm:hidden"
                                    placeholder="Search"
                                    value={inputValue}
                                    onChange={(e) => {
                                        setInputValue(e.target.value);
                                        if (e.target.value.length >= 2 || e.target.value.length === 0) {
                                            setSearchParam({
                                                ...searchParams,
                                                searchName: e.target.value,
                                            });
                                        }
                                    }}
                                />
                                <input
                                    type="text"
                                    name="desktop-search-candidate"
                                    id="desktop-search-candidate"
                                    className="hidden w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-blue-500 focus:ring-blue-500 sm:block sm:text-sm"
                                    placeholder="Search work orders"
                                    value={inputValue}
                                    onChange={(e) => {
                                        setInputValue(e.target.value);
                                        if (e.target.value.length >= 2 || e.target.value.length === 0) {
                                            setSearchParam({
                                                ...searchParams,
                                                searchName: e.target.value,
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <button
                                type="button"
                                className="relative -ml-px inline-flex items-center border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                onClick={() => navigate("/workorders/create")}
                            >
                                <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span className="ml-1 hidden md:inline">Add Work Order</span>
                            </button>
                            <button
                                type="button"
                                className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                onClick={() => setFilterListOpen(true)}
                            >
                                <Cog6ToothIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>

            <div className="overflow-y-auto bg-white shadow sm:rounded-md">
                <header className="bg-white border-b">
                    <div className="px-4 py-5 sm:px-6"></div>
                </header>
                <Loader loading={isLoading === true}>
                    {workOrderData !== "loading" && workOrderData.content && workOrderData.content.length > 0 ? (
                        <ul role="list" className="divide-y divide-gray-200">
                            {workOrderData.content.map((workOrder) => {
                                if (workOrder.location_id) {
                                    return (
                                        <li key={workOrder._id}>
                                            <Link
                                                to={`/workorders/${workOrder._id}`}
                                                className="block hover:bg-gray-50"
                                            >
                                                <div className="flex items-center px-4 py-4 sm:px-6">
                                                    <div className="flex min-w-0 flex-1 items-center">
                                                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                            <div className="flex items-center">
                                                                <div>
                                                                    <p className="truncate mb-0 text-sm font-medium text-blue-600">
                                                                        {getFormat(workOrder.type)} -{" "}
                                                                        {workOrder.location_id.name &&
                                                                            workOrder.location_id.name}
                                                                        &nbsp;
                                                                    </p>
                                                                    <p className={"text-gray-500 text-xs"}>
                                                                        {workOrder.location_id.mailing_address &&
                                                                            workOrder.location_id.mailing_address}
                                                                        ,{" "}
                                                                        {workOrder.location_id.mailing_city &&
                                                                            workOrder.location_id.mailing_city}{" "}
                                                                        {workOrder.location_id.mailing_state &&
                                                                            workOrder.location_id.mailing_state}{" "}
                                                                        {workOrder.location_id.mailing_zip &&
                                                                            workOrder.location_id.mailing_zip}
                                                                    </p>
                                                                    <p className={"text-gray-500 text-xs"}>
                                                                        {"Assigned To: " +
                                                                            workOrder.assignedPeople
                                                                                .map((person) => person.name)
                                                                                .join(", ")}
                                                                        {workOrder.assignedPeople &&
                                                                            workOrder.assignedPeople.length < 1 &&
                                                                            "Nobody"}
                                                                        {/* Order #{workOrder._id.slice(-5).toUpperCase()} */}
                                                                        <div>
                                                                        <p className="text-sm text-gray-900 bg-yellow-300">
                                                                <b>
                                                                    {workOrder.notes &&
                                                                        workOrder.notes
                                                                            .toLowerCase()
                                                                            .includes("start with") &&
                                                                        workOrder.notes.split(".")[0]}
                                                                </b>
                                                            </p>
                                                                            {/* Filed
                                            Status: {workOrder.filed_status ? "Filed" : "Not Filed"} */}
                                                                        </div>
                                                                        <div className={"flex items-center md:hidden"}>
                                                                            {workOrder.status === 0 && (
                                                                                <>
                                                                                    <XCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400" />{" "}
                                                                                    Not Started
                                                                                </>
                                                                            )}
                                                                            {workOrder.status === 1 && (
                                                                                <>
                                                                                    <ExclamationCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400" />{" "}
                                                                                    In Progress
                                                                                </>
                                                                            )}
                                                                            {workOrder.status === 2 && (
                                                                                <>
                                                                                    <CheckCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400" />{" "}
                                                                                    Completed
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="hidden md:block">
                                                                <div>
                                                                    <p className="text-sm text-gray-900">
                                                                        Work Order Due:{" "}
                                                                        <span>
                                                                            {new Date(
                                                                                workOrder.date_due
                                                                            ).toDateString()}
                                                                        </span>
                                                                    </p>
                                                                    <p className="mt-2 flex items-center text-sm text-gray-500">
                                                                        {workOrder.status === 0 && (
                                                                            <>
                                                                                <XCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400" />{" "}
                                                                                Not Started
                                                                            </>
                                                                        )}
                                                                        {workOrder.status === 1 && (
                                                                            <>
                                                                                <ExclamationCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400" />{" "}
                                                                                In Progress
                                                                            </>
                                                                        )}
                                                                        {workOrder.status === 2 && (
                                                                            <>
                                                                                <CheckCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400" />{" "}
                                                                                Completed
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <ChevronRightIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    );
                                } else {
                                    // alert(workOrder._id);
                                }
                            })}
                        </ul>
                    ) : workOrderData.content && workOrderData.content.errMsg ? (
                        <ErrorBox
                            mainMessage={"Issues Loading Work Orders. Please call the office to have these fixed."}
                            errorsArr={[
                                workOrderData.content.violations.length + " workorder(s) have incorrect addresses.",
                                "Incorrect List (listed below): ",
                                ...workOrderData.content.violations.map((violation, i) => {
                                    return (
                                        (i > 0 ? ", " : " ") + violation.name + " (" + violation.mailing_address + ")"
                                    );
                                }),
                            ]}
                        />
                    ) : (
                        <div className="flex flex-col items-center justify-center border-dashed border-2 border-spacing-1.5 border-gray-400 py-10 rounded text-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-12 w-12 text-gray-400 mb-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                            </svg>
                            <p className="text-xl text-gray-600">No work orders currently</p>
                            <p className="text-sm text-gray-500 mt-2">
                                Please check back later or contact your supervisor for more information.
                            </p>
                        </div>
                    )}
                    <Pagination
                        fetchFunc={fetchAllWorkOrderAsync}
                        searchParams={searchParams}
                        pageCount={workOrderData.pageCount}
                        maxPage={Math.ceil(workOrderData.pageCountLength / workOrderData.pageCount)}
                        page={workOrderData.page}
                        setFunc={setWorkOrderData}
                        pageCountLength={workOrderData.pageCountLength}
                        total={workOrderData.total}
                    />
                </Loader>
            </div>
            {/* Modals */}
            <SlideboxSlider title={"Filter Settings"} open={filterListOpen} setOpen={setFilterListOpen}>
                <DropdownChecked
                    title={"Job Type"}
                    classes="mb-3"
                    setSelected={setSelectedJobTypeFilter}
                    selected={selectedJobTypeFilter}
                    options={jobTypeFilterOptions}
                />
                <DropdownChecked
                    title={"Job Status"}
                    classes="mb-3"
                    setSelected={setJobStatusFilter}
                    selected={jobStatusFilter}
                    options={jobStatusFilterOptions}
                />
                <DropdownChecked
                    title={"Filed Status"}
                    classes="mb-3"
                    setSelected={setJobFiledFilter}
                    selected={jobFiledFilter}
                    options={jobFiledFilterOptions}
                />
                <DropdownChecked
                    title={"Driver"}
                    classes="mb-3"
                    setSelected={setDriverSelectedFilter}
                    selected={driverSelectedFilter}
                    options={driverSelectedFilterOptions}
                />

                <DropdownChecked
                    title={"Sort"}
                    classes="mb-3"
                    setSelected={setSortSelectedFilter}
                    selected={sortSelectedFilter}
                    options={sortSelectedFilterOptions}
                />
            </SlideboxSlider>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-6 00">
                                        Create New Work Order
                                    </Dialog.Title>

                                    {/* form */}
                                    <div>
                                        <div className="border-b border-gray-200 pb-5 pt-2">
                                            <p className="mt-2 max-w-4xl text-sm text-gray-500">
                                                <span
                                                    className="text-blue-500 cursor-pointer"
                                                    onClick={() => {
                                                        setIsOpen(false);
                                                        resetInputFields();
                                                        setIssues([]);
                                                        setMassJobCreate(true);
                                                    }}
                                                >
                                                    Click Here to create a mass work order issue.
                                                </span>{" "}
                                                Or fill out the form below for creation of jobs for one location.
                                            </p>
                                        </div>

                                        <label className="mt-3 block text-sm font-medium text-gray-700">Location</label>
                                        <div className="mt-1">
                                            <span
                                                className="text-blue-500 cursor-pointer"
                                                onClick={() => {
                                                    closeModal();
                                                    setLocationModalOpen(true);
                                                }}
                                            >
                                                Click here to select a
                                                {locationNamePreview.trim() !== "" ? " different" : null} location
                                            </span>
                                        </div>

                                        {locationNamePreview.trim() !== "" && (
                                            <div className="mt-1">
                                                <span className="text-gray-500">
                                                    Currently Selected: {locationNamePreview}
                                                </span>
                                            </div>
                                        )}

                                        <label
                                            htmlFor="status"
                                            className="mt-3 block text-sm font-medium text-gray-700"
                                        >
                                            Status
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="status"
                                                name="status"
                                                className="relative block w-full rounded-none rounded-t-md rounded-b-md border-gray-300 bg-transparent focus:z-10 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                onChange={(e) => setJobStatusField(e.target.value)}
                                                value={jobStatusField}
                                            >
                                                <option value="0">Not Started</option>
                                                <option value="1">In Progress</option>
                                                <option value="2">Completed</option>
                                            </select>
                                        </div>

                                        <label htmlFor="type" className="mt-3 block text-sm font-medium text-gray-700">
                                            Job Type
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="type"
                                                name="type"
                                                className="relative block w-full rounded-none rounded-t-md rounded-b-md border-gray-300 bg-transparent focus:z-10 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                onChange={(e) => {
                                                    setJobTypeField(e.target.value);
                                                    if (e.target.value !== "other") {
                                                        setOtherJobTypeField("");
                                                    }
                                                }}
                                                value={jobTypeField}
                                            >
                                                <option value="spring startup">Spring Startup</option>
                                                <option value="fall shutdown">Fall Shutdown</option>
                                                <option value="service">Service</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>

                                        {jobTypeField === "other" && (
                                            <>
                                                <label
                                                    htmlFor="otherjobfield"
                                                    className="mt-3 block text-sm font-medium text-gray-700"
                                                >
                                                    Other Job Type
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="otherjobfield"
                                                        id="otherjobfield"
                                                        onChange={(e) => setOtherJobTypeField(e.target.value)}
                                                        value={otherJobTypeField}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                    />
                                                </div>
                                            </>
                                        )}

                                        <label
                                            htmlFor="duedatefield"
                                            className="mt-3 block text-sm font-medium text-gray-700"
                                        >
                                            Due Date
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="date"
                                                name="duedatefield"
                                                id="duedatefield"
                                                onChange={(e) => setDueDateField(e.target.value)}
                                                value={dueDateField}
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                            />
                                        </div>

                                        <label
                                            htmlFor="scheduleddatefield"
                                            className="mt-3 block text-sm font-medium text-gray-700"
                                        >
                                            Scheduled Date (Leave blank if none)
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="date"
                                                name="scheduleddatefield"
                                                id="scheduleddatefield"
                                                onChange={(e) => setScheduledDateField(e.target.value)}
                                                value={scheduledDateField}
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                            />
                                        </div>

                                        <label
                                            htmlFor="MailingAddress"
                                            className="mt-3 block text-sm font-medium text-gray-700"
                                        >
                                            Description
                                        </label>
                                        <div className="mt-1">
                                            <textarea
                                                id="about"
                                                name="about"
                                                rows={3}
                                                onChange={(e) => setDescriptionField(e.target.value)}
                                                value={descriptionField}
                                                className="block mb-3 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                placeholder="Write about this work order for the details page and for your driver to see."
                                            />
                                            {issues.length > 0 && <ErrorBox errorsArr={issues} />}
                                        </div>
                                    </div>

                                    <div className="mt-4 float-right">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => {
                                                let postObj = {
                                                    location_id: locationField,
                                                    notes: descriptionField,
                                                };
                                                // JSX can only have 1 parent, so cant use double and in this exp to represent 2 truthy values, defaulting to false to skip.
                                                if (jobStatusField !== "0") {
                                                    postObj.status = parseInt(jobStatusField);
                                                }
                                                if (jobTypeField.trim() !== "") {
                                                    postObj.type = jobTypeField.trim();
                                                }
                                                if (descriptionField.trim() !== "") {
                                                    postObj.notes = descriptionField.trim();
                                                }
                                                if (dueDateField.trim() !== "") {
                                                    postObj.date_due = new Date(dueDateField).toISOString();
                                                }
                                                if (jobStatusField.trim() === "2") {
                                                    postObj.date_completed = new Date().toISOString();
                                                }
                                                if (scheduledDateField.trim() !== "") {
                                                    postObj.date_scheduled = new Date(scheduledDateField).toISOString();
                                                }
                                                if (otherJobTypeField.trim() !== "") {
                                                    postObj.type = otherJobTypeField;
                                                }

                                                let okay = true;

                                                /** Validation for Error Box Messaging */
                                                if (locationField.trim() === "") {
                                                    setIssues((oldIssues) => [...oldIssues, "Specify a Location"]);
                                                }
                                                if (jobTypeField.trim() === "") {
                                                    setIssues((oldIssues) => [...oldIssues, "Specify a Job Type"]);
                                                }

                                                /** Validation to prevent submission on the form */
                                                if (locationField.trim() === "" || jobTypeField.trim() === "") {
                                                    // alert("There were problems with your submission.");
                                                    okay = false;
                                                }

                                                if (okay) {
                                                    postWorkOrderAsync(setWorkOrderData, postObj);
                                                    // Reset Fields
                                                    resetInputFields();
                                                    setMassJobCreate(false);
                                                    closeModal();
                                                }
                                            }}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
            <LocationSelectModal
                setLocationModalOpen={setLocationModalOpen}
                locationModalOpen={locationModalOpen}
                allLocations={allLocations.content || []}
                locationSearchField={locationSearchField}
                setLocationSearchField={setLocationSearchField}
                openModal={openModal}
                setLocationField={setLocationField}
                setLocatioNamePreview={setLocatioNamePreview}
            />
        </Loader>
    );
}
