import React, {useState} from "react";
import axios from "axios";
import {
    fnDeleteSubjectID,
    fnDeleteSubjectIDNoRedirect,
    fnFetchAllSubject,
    fnFetchSubjectID,
    fnModifyAsyncNoRedirect,
    fnModifyAsyncRedirect, fnPostAsyncRedirect,
    fnPostToSubject,
} from "./CrudContextProviderUtil";

export const WorkOrderDataContext = React.createContext();

const userAxios = axios.create();

userAxios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

// Special Edge Case for mass job
export const fnMassJobCreateSubject = (
    axiosObj,
    path,
    errorStateSetter = function (e) {
        console.log(e);
    }
) => {
    return (navigate, postObject) => {
        axiosObj
            .post(`/api/${path}`, postObject)
            .then((res) => {
                if(res.status !== 500) {
                    navigate("/workorders");                
                }
            })
            .catch((err) => errorStateSetter(err));
    };
};

export default function UserDataProvider(props) {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <WorkOrderDataContext.Provider
            value={{
                modifyWorkOrderAsync: fnModifyAsyncRedirect(userAxios, "workorder", function(){}, setIsLoading),
                modifyWorkOrderAsyncNoRedirect: fnModifyAsyncNoRedirect(userAxios, "workorder", function(){}, setIsLoading),
                fetchAllWorkOrderAsync: fnFetchAllSubject(userAxios, "workorder", function(){}, setIsLoading),
                fetchWorkOrderAsync: fnFetchSubjectID(userAxios, "workorder", function(){}, setIsLoading),
                fetchWorkOrderAsyncNoAdvance: fnFetchSubjectID(userAxios, "workorder/noadvance", function(){}, setIsLoading),
                postWorkOrderAsync: fnPostToSubject(userAxios, "workorder", function(){}, setIsLoading),
                fetchAllLocationAsync: fnFetchAllSubject(userAxios, "location", function(){}, setIsLoading),
                fetchAllWorkOrderNotesAsync: fnFetchSubjectID(userAxios, "workorder/note", function(){}, setIsLoading),
                postWorkOrderCommentAsync: fnPostToSubject(userAxios, "workorder/note", function(){}, setIsLoading),
                editWorkOrderCommentAsync: fnModifyAsyncNoRedirect(userAxios, "workorder/note", function(){}, setIsLoading),
                deleteWorkOrderCommentAsync: fnDeleteSubjectIDNoRedirect(userAxios, "workorder/note", function(){}, setIsLoading),
                fetchAllWorkOrderAssignmentAsync: fnFetchSubjectID(userAxios, "workorderassignment/getuser", function(){}, setIsLoading),
                deleteWorkOrderAssignmentAsyncNoRedirect: fnDeleteSubjectIDNoRedirect(userAxios, "workorderassignment", function(){}, setIsLoading),
                modifyWorkOrderAssignmentAsyncNoRedirect: fnModifyAsyncNoRedirect(userAxios, "workorderassignment", function(){}, setIsLoading),
                postWorkOrderAssignmentAsync: fnPostToSubject(userAxios, "workorderassignment", function(){}, setIsLoading),
                fetchAllUsersAsync: fnFetchAllSubject(userAxios, "user", function(){}, setIsLoading),
                fetchAllPartsAsync: fnFetchAllSubject(userAxios, "part", function(){}, setIsLoading),
                fetchPartsWorkOrderAsync: fnFetchSubjectID(userAxios, "mapPartWorkOrder/workorder", function(){}, setIsLoading),
                postPartsWorkOrderAsync: fnPostToSubject(userAxios, "mapPartWorkOrder", function(){}, setIsLoading),
                modifyPartsWorkOrderAsyncNoRedirect: fnModifyAsyncNoRedirect(userAxios, "mapPartWorkOrder", function(){}, setIsLoading),
                deletePartsWorkOrderAsyncNoRedirect: fnDeleteSubjectIDNoRedirect(userAxios, "mapPartWorkOrder", function(){}, setIsLoading),
                fetchAllWorkOrdersTodayUser: fnFetchSubjectID(userAxios, "workorder/user/today", function(){}, setIsLoading),
                postNewMassJobCreate: fnMassJobCreateSubject(userAxios, "utils/mass-create-workorders", function(){}, setIsLoading),
                fnDeleteSubjectID: fnDeleteSubjectID(userAxios, "workorder", function(){}, setIsLoading),
                isLoading,
                postNewWorkOrderRedirect: fnPostAsyncRedirect(userAxios, "workorder")
            }}
        >
            {props.children}
        </WorkOrderDataContext.Provider>
    );
}
