import React, {useContext} from "react";
import {UserContext} from "../../../context/UserProvider";
import WorkOrderListDriver from "./DriverApp/WorkOrderListDriverHome";
import WorkOrderListAdmin from "./WorkOrderListAdmin";

export default function LocationList() {
    const {user} = useContext(UserContext);

    const WorkOrderComponent = () => {
        switch (user.account_level) {
            case 1:
                return <WorkOrderListDriver/>
            case 2:
                return <WorkOrderListAdmin/>
            case 3:
                return <WorkOrderListAdmin/>
            default:
                return <WorkOrderListDriver/>
        }
    };

    return (
        <WorkOrderComponent/>
    );
}
