const getAccountAccessLabel = num => {
    switch(parseInt(num)) {
        case 0:
            return "No Access";
        case 1:
            return "Driver App Only";
        case 2:
            return "Administrator";
        default:
            return "Developer";
    }
}

module.exports = {
    getAccountAccessLabel
}