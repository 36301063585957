import { useContext } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import ApplicationShell from '../components/templates/ApplicationShell';
import { UserContext } from "../context/UserProvider";

export default function ProtectedRoute({ redirectTo, token }){
    const { user, getNameOfUser } = useContext(UserContext);
    return token ? <ApplicationShell title={`Welcome, ${user.name}`}><Outlet /></ApplicationShell>
        :
    <Navigate to={redirectTo}/>;
}