/**
 * Create Asyncronous modification generic CRUD function, redirect on Success
 * @returns function
 */
export const fnModifyAsyncRedirect = (axiosObj, path, errorStateSetter = function (e) {
    console.log(e);
}, setIsLoading = function(paramIsLoading){}) => {
    return (subjectID, redirectURL, modificationObject, navigate) => {
        axiosObj.put(`/api/${path}/${subjectID}`, modificationObject)
            .then(res => {
                navigate(redirectURL);
            })
            .catch(err => errorStateSetter(err));
    }
}  

export const fnPostAsyncRedirect = (axiosObj, path, errorStateSetter = function (e) {
    console.log(e);
}, setIsLoading = function(paramIsLoading){}) => {
    return (postObj, redirectURL, navigate) => {
        axiosObj.post(`/api/${path}`, postObj)
            .then(res => {
                navigate(redirectURL);
            })
            .catch(err => errorStateSetter(err));
    }
}

export const fnModifyAsyncNoRedirect = (axiosObj, path, errorStateSetter = function (e) {
    console.log(e);
}, setIsLoading = function(paramIsLoading){}) => {
    return (subjectID, modificationObject) => {
        axiosObj.put(`/api/${path}/${subjectID}`, modificationObject)
            .then(res => {
            })
            .catch(err => errorStateSetter(err));
    }
}

/**
 * Create Asyncronous GETTER function, set stateObject parameter function on success
 * @returns function
 */
export const fnFetchAllSubject = (axiosObj, path, errorStateSetter = function (e) {
    console.log(e);
}, setIsLoading = function(paramIsLoading){}) => {
    return (setFunc, filters = {}, pageCount = null, page = null) => {
        pageCount = pageCount !== null ? `pageCount=${pageCount}` : "";
        page = page !== null ? `page=${page}` : "";
        if (pageCount !== null && page !== null && pageCount.trim() !== "" && page.trim() !== "") {
            page = `&${page}`;
        }

        setIsLoading(true);

        let filterParams = [];
        if (page.trim() != "") {
            filterParams.push(page);
        }
        if (pageCount.trim() != "") {
            filterParams.push(pageCount);
        }
        for (const [key, value] of Object.entries(filters)) {
            if (typeof value == "number") {
                filterParams.push(`${key}=${value}`);
            } else if (value && value.trim() != "") {
                filterParams.push(`${key}=${value}`);
            }
        }
        filterParams = filterParams.join("&");
        if (filterParams.charAt(0) === "&") {
            filterParams = filterParams.slice(1);
        }
        axiosObj.get(` /api/${path}?${filterParams}`)
            .then(res => {
                setIsLoading(false);
                setFunc(res.data);
            })
            .catch(err => errorStateSetter(err));
    }
}

/**
 * Create Asyncronous GETTER function, set stateObject parameter function on success
 * @returns function
 */
export const fnFetchSubjectID = (axiosObj, path, errorStateSetter = function (e) {
    console.log(e);
}, setIsLoading = function(paramIsLoading){}) => {
    return (setFunc, subjectID) => {
        axiosObj.get(` /api/${path}/${subjectID}`)
            .then(res => {
                setFunc({content: res.data});
            })
            .catch(err => errorStateSetter(err));
    }
}

export const fnDeleteSubjectID = (axiosObj, path, errorStateSetter = function (e) {
    console.log(e);
}, setIsLoading = function(paramIsLoading){}) => {
    return (subjectID, redirectURL, navigate) => {
        axiosObj.put(` /api/${path}/${subjectID}`, {active: false})
            .then(res => {
                navigate(redirectURL);
            })
            .catch(err => errorStateSetter(err));
    }
}

export const fnDeleteSubjectIDNoRedirect = (axiosObj, path, errorStateSetter = function (e) {
    console.log(e);
}, setIsLoading = function(paramIsLoading){}) => {
    return (subjectID) => {
        axiosObj.put(`/api/${path}/${subjectID}`, {active: false})
            .then(res => {
            })
            .catch(err => errorStateSetter(err));
    }
}

/**
 * Create Asyncronous POST function, set stateObject parameter function on success
 * @returns function
 */
export const fnPostToSubject = (axiosObj, path, errorStateSetter = function (e) {
    console.log(e);
}, setIsLoading = function(paramIsLoading){}) => {
    return (setFunc, postObject) => {
        axiosObj.post(`/api/${path}`, postObject)
            .then(res => {
                setFunc(oldData => ({...oldData, content: [...oldData.content, res.data]}));
            })
            .catch(err => errorStateSetter(err));
    }
}
