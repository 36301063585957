import React, { useContext, useEffect, useState } from "react";
import {
  CheckBadgeIcon, PaperClipIcon,
  ShieldExclamationIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../atoms/Loader";
import { UserContext } from "../../../context/UserProvider";
import { UserDataContext } from "../../../context/UserDataProvider";
import TextInput from "../../atoms/Inputs/TextInput";
import { Dialog } from "@headlessui/react";
import ModalForm1 from "../../molecules/ModalForm1";

export default function EditUserProfile() {
  let { userID } = useParams();
  const [userData, setUserData] = useState("loading");
  const { errMsg, resetAuthErr } = useContext(UserContext);
  const { getUserById, modifyUserAsync, modifyUserPasswordAsyncNoRedirect } = useContext(UserDataContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();
  const [openModal, SetOpenModal] = useState(false);
  const [inputState, setInputState] = useState({});
  const [newPasswordField, setNewPasswordField] = useState("");

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (e.name === "account_level") {
      value = parseInt(value);
    }
    setInputState((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  useEffect(() => {
    resetAuthErr();
    getUserById(userID, setUserData);
  }, []);

  // Valid States:
  // "loading" - Loading User Defined
  // "new" - Creation of new user
  // ...{} - Obj of pre-fetched user

  return (
    <Loader loading={userData === "loading" && isUpdating === false}>
      {errMsg ? (
        errMsg
      ) : (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <ModalForm1 setOpen={SetOpenModal} open={openModal}>
            <div className="mb-5">
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                <ShieldExclamationIcon
                  className="h-6 w-6 text-yellow-600"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Change Password
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500 mb-5">
                    This will change the user's password to access their
                    account. Be sure to give them this new password, it will not
                    be automatically sent to them.
                  </p>
                  <TextInput
                    label={"Password"}
                    value={newPasswordField}
                    onChange={e => setNewPasswordField(e.target.value)}
                    placeholder={"Enter new password..."}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                onClick={() => {
                  modifyUserPasswordAsyncNoRedirect(userID, { password: newPasswordField });
                  setNewPasswordField("");
                  SetOpenModal(false);
                }}
              >
                Change Password
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                onClick={() => SetOpenModal(false)}
              >
                Cancel
              </button>
            </div>
          </ModalForm1>
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Currently Editing: {userData[0].name}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Personal details and login information.
            </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 flex items-center">
                  Full Name
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <TextInput
                    name="name"
                    placeholder="Name"
                    value={inputState.name || userData[0].name || ""}
                    onChange={handleChange}
                  />
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500 flex items-center">
                  Username
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <TextInput
                    name="username"
                    placeholder="Username"
                    value={inputState.username || userData[0].username || ""}
                    onChange={handleChange}
                  />
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Account Access
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <select
                    id="account_level"
                    name="account_level"
                    className="relative block w-full rounded-none rounded-t-md rounded-b-md border-gray-300 bg-transparent focus:z-10 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    onChange={handleChange}
                  >
                    <option
                      value="0"
                      selected={
                        userData[0].account_level === 0 ? "selected" : false
                      }
                    >
                      No Access
                    </option>
                    <option
                      value="1"
                      selected={
                        userData[0].account_level === 1 ? "selected" : false
                      }
                    >
                      Driver App Only
                    </option>
                    <option
                      value="2"
                      selected={
                        userData[0].account_level >= 2 ? "selected" : false
                      }
                    >
                      Administratrator
                    </option>
                  </select>
                </dd>
              </div>

              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Password</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  Passwords are private & encrypted, click{" "}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      SetOpenModal(!openModal);
                    }}
                    className="text-blue-500"
                  >
                    here
                  </a>{" "}
                  to create a new one to change this User's Password.
                </dd>
              </div>

              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Member Since
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {new Date(userData[0].member_since).toDateString()}
                </dd>
              </div>

              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Actions</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 cursor-pointer">
                  <div
                    onClick={() => {
                      setIsUpdating(true);
                      modifyUserAsync(
                        userID,
                        inputState,
                        `/users/${userID}`,
                        navigate
                      );
                    }}
                    className="mt-1 max-w-2xl text-sm text-green-600 inline-flex justify-content-center mr-5"
                  >
                    <CheckBadgeIcon className="w-4 inline mr-1" /> Save
                  </div>
                  <Link
                    to={`/users/${userID}`}
                    className="mt-1 max-w-2xl text-sm text-red-500 inline-flex justify-content-center"
                  >
                    <XCircleIcon className="w-4 inline mr-1" /> Cancel
                  </Link>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}
    </Loader>
  );
}
