
    const ButtonComponent = ({type, color = "gray", className = "", children, onClick = () => {}}) => {
        switch (type) {
            case "xs":
                return <button
                    type="button"
                    onClick={onClick}
                    className={`rounded bg-${color}-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-${color}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${color}-600 ${className}`}>{children}</button>
            case "sm":
                return <button
                    type="button"
                    onClick={onClick}
                    className={`rounded bg-${color}-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-${color}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${color}-600 ${className}`}>{children}</button>
            case "md":
                return <button
                    type="button"
                    onClick={onClick}
                    className={`rounded-md bg-${color}-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-${color}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${color}-600 ${className}`}>{children}</button>
            case "lg":
                return <button
                    type="button"
                    onClick={onClick}
                    className={`rounded-md bg-${color}-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${color}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${color}-600 ${className}`}>{children}</button>
            case "xl":
                return <button
                    type="button"
                    onClick={onClick}
                    className={`rounded-md bg-${color}-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-${color}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${color}-600 ${className}`}>{children}</button>
            default:
                return <button
                    type="button"
                    onClick={onClick}
                    className={`rounded-md bg-${color}-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-${color}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${color}-600 ${className}`}>{children}</button>
        };
    }

    export default ButtonComponent;
