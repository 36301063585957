import React, {useState} from 'react';
import axios from 'axios';

export const UserContext = React.createContext();

const userAxios = axios.create();

userAxios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
})

export default function UserProvider(props) {
    const initState = {
        user: JSON.parse(localStorage.getItem('user')) || {},
        token: localStorage.getItem('token') || '',
        errMsg: ''
    }
    const [userState, setuserState] = useState(initState);

    const handleAuthErr = errMsg => {
        setuserState(prevState => ({
            ...prevState,
            errMsg
        }))

    }

    const resetAuthErr = () => {
        setuserState(prevState => ({
            ...prevState,
            errMsg: ''
        }))
    }

    const signup = credentials => {
        axios.post('/auth/signup', credentials)
            .then(res => {
                const {user, token} = res.data;
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(user));
                setuserState(prevUserState => ({
                    ...prevUserState,
                    user,
                    token
                }))
            })
            .catch(err => handleAuthErr(err.response.data.errMsg))
    }

    const login = credentials => {
        axios.post('/auth/login', credentials)
            .then(res => {
                const {user, token} = res.data;
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(user));
                setuserState(prevUserState => ({
                    ...prevUserState,
                    user,
                    token
                }))
            })
            .catch(err => handleAuthErr(err.response.data.errMsg))
    }

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setuserState({user: {}, token: ''})
    }

    /**
     * Refresh user information based on token, this is live data and does not require re-authentication.
     */
    const refreshUserInformation = () => {
        if (userState && userState.user !== {}) {
            userAxios.get('/api/jwt-token')
                .then(res => {
                    setuserState(oldUserState => ({...oldUserState, user: res.data}))
                })
                .catch(err => console.log(err));
        }
    }

    const getUserById = (userID, setFunc) => {
        userAxios.get(`/api/user/${userID}`)
            .then(res => {
                setFunc(res.data);
            })
            .catch(err => console.log(err));
    }

    const getNameOfUser = () => {
        return userState.user.username;
    }

    return (
        <UserContext.Provider value={
            {
                ...userState,
                signup,
                login,
                logout,
                resetAuthErr,
                getUserById,
                getNameOfUser,
            }}>
            {props.children}
        </UserContext.Provider>
    )
}