import React, {useState} from "react";
import axios from 'axios';
import { fnFetchAllSubject, fnModifyAsyncRedirect, fnModifyAsyncNoRedirect, fnFetchSubjectID, fnDeleteSubjectIDNoRedirect, fnPostToSubject } from './CrudContextProviderUtil';
export const PartDataContext = React.createContext();

const userAxios = axios.create();

userAxios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
})

export default function PartDataProvider(props) {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <PartDataContext.Provider value={ 
            {
                modifyPartAsync: fnModifyAsyncRedirect(userAxios, "part", function(){}, setIsLoading),
                modifyPartAsyncNoRedirect: fnModifyAsyncNoRedirect(userAxios, "part", function(){}, setIsLoading),
                fetchAllPartAsync: fnFetchAllSubject(userAxios, "part", function(){}, setIsLoading),
                fetchPartAsync: fnFetchSubjectID(userAxios, "part", function(){}, setIsLoading),
                postNewPart: fnPostToSubject(userAxios, "part", function(){}, setIsLoading),
                deletePartAsync: fnDeleteSubjectIDNoRedirect(userAxios, "part", function(){}, setIsLoading),
                isLoading,
            } }>
            { props.children }
        </PartDataContext.Provider>
    )
}
