import React, { useContext, useEffect, useState } from "react";
import { CheckCircleIcon, ChevronRightIcon, ExclamationCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { WorkOrderDataContext } from "../../../../context/WorkOrderDataProvider";
import Loader from "../../../atoms/Loader";
import { Link } from "react-router-dom";
import { UserContext } from "../../../../context/UserProvider";
import Button from "../../../atoms/Buttons/Button";
import { scheduleAutoRefresh } from "../../../../context/AutoRefreshScheduler";
import ErrorBox from "../../../molecules/ErrorBox";

export default function LocationList() {
    const { fetchAllWorkOrdersTodayUser } = useContext(WorkOrderDataContext);
    const { user } = useContext(UserContext);
    const [workOrderData, setWorkOrderData] = useState("loading");

    useEffect(() => {
        fetchAllWorkOrdersTodayUser(setWorkOrderData, user._id);
        scheduleAutoRefresh(() => {
            fetchAllWorkOrdersTodayUser(setWorkOrderData, user._id);
        }, 300);
    }, []);

    const getFormat = (type) => {
        switch (type.trim()) {
            case "spring startup":
                return "Spring Startup";
            case "fall shutdown":
                return "Fall Shutdown";
            case "service":
                return "service";
            default:
                return type;
        }
    };

    return (
        <Loader loading={workOrderData === "loading"}>
            <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Your Scheduled Work Orders for Today ({new Date().toLocaleDateString()})
                </h3>
                {workOrderData !== "loading" &&
                    workOrderData.content.errMsg === undefined &&
                    workOrderData.content.length > 0 && (
                        <Link
                            type="button"
                            to={`/workorders/${workOrderData.content[0].workorder_id._id}`}
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-400 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-green-600  hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                        >
                            Start Routes
                        </Link>
                    )}
            </div>

            <div className="overflow-hidden bg-white shadow sm:rounded-md">
                <ul role="list" className="divide-y divide-gray-200">
                    {workOrderData.content && workOrderData.content.errMsg && (
                        <ErrorBox
                            mainMessage={"Issues Loading Work Orders. Please call the office to have these fixed."}
                            errorsArr={[
                                workOrderData.content.violations.length + " workorder(s) have incorrect addresses.",
                                "Incorrect List (listed below): ",
                                ...workOrderData.content.violations.map((violation, i) => {
                                    return (
                                        (i > 0 ? ", " : " ") + violation.name + " (" + violation.mailing_address + ")"
                                    );
                                }),
                            ]}
                        />
                    )}
                    {workOrderData !== "loading" &&
                        workOrderData &&
                        workOrderData.content &&
                        workOrderData.content.errMsg === undefined &&
                        (workOrderData.content.length > 0 ? (
                            workOrderData.content.map((workOrder, i) => (
                                <li key={workOrder.workorder_id._id}>
                                    <Link
                                        to={`/workorders/${workOrder.workorder_id._id}`}
                                        className="block hover:bg-gray-50"
                                    >
                                        <div className="flex items-center px-4 py-4 sm:px-6">
                                            <div className="flex min-w-0 flex-1 items-center">
                                                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                    <div className="flex items-center">
                                                        <div>
                                                            <p className="truncate mb-0 text-sm font-medium text-blue-600">
                                                                #{i + 1} {getFormat(workOrder.workorder_id.type)} -{" "}
                                                                {workOrder.workorder_id.location_id.name}&nbsp;
                                                            </p>
                                                            <p className={"text-gray-500 text-xs"}>
                                                                {workOrder.workorder_id.location_id.mailing_address},{" "}
                                                                {workOrder.workorder_id.location_id.mailing_city}{" "}
                                                                {workOrder.workorder_id.location_id.mailing_state}{" "}
                                                                {workOrder.workorder_id.location_id.mailing_zip}
                                                            </p>
                                                            <p className={"text-gray-500 text-xs"}>
                                                                Order #
                                                                {workOrder.workorder_id._id.slice(-5).toUpperCase()}
                                                                <div className={"flex items-center md:hidden"}>
                                                                    {workOrder.workorder_id.status === 0 && (
                                                                        <>
                                                                            <XCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400" />{" "}
                                                                            Not Started
                                                                        </>
                                                                    )}
                                                                    {workOrder.workorder_id.status === 1 && (
                                                                        <>
                                                                            <ExclamationCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400" />{" "}
                                                                            In Progress
                                                                        </>
                                                                    )}
                                                                    {workOrder.workorder_id.status === 2 && (
                                                                        <>
                                                                            <CheckCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400" />{" "}
                                                                            Completed
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </p>

                                                            <p className="text-sm text-gray-900 bg-yellow-300">
                                                                <b>
                                                                    {workOrder.workorder_id.notes &&
                                                                        workOrder.workorder_id.notes
                                                                            .toLowerCase()
                                                                            .includes("start with") &&
                                                                        workOrder.workorder_id.notes.split(".")[0]}
                                                                </b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="hidden md:block">
                                                        <div>
                                                            <p className="text-sm text-gray-900">
                                                                Work Order Due:{" "}
                                                                <span>
                                                                    {new Date(workOrder.workorder_id.date_due).toDateString()}
                                                                </span>
                                                            </p>
                                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                                {workOrder.workorder_id.status === 0 && (
                                                                    <>
                                                                        <XCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400" />{" "}
                                                                        Not Started
                                                                    </>
                                                                )}
                                                                {workOrder.workorder_id.status === 1 && (
                                                                    <>
                                                                        <ExclamationCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400" />{" "}
                                                                        In Progress
                                                                    </>
                                                                )}
                                                                {workOrder.workorder_id.status === 2 && (
                                                                    <>
                                                                        <CheckCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400" />{" "}
                                                                        Completed
                                                                    </>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <ChevronRightIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            ))
                        ) : (
                            <div className="flex flex-col items-center justify-center border-dashed border-2 border-spacing-1.5 border-blue-400 py-10 rounded text-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-12 w-12 text-gray-400 mb-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M12 4v16m8-8H4"
                                    />
                                </svg>
                                <p className="text-xl text-gray-600">No work orders currently</p>
                                <p className="text-sm text-gray-500 mt-2">
                                    Please check back later or contact your supervisor for more information.
                                </p>
                            </div>
                        ))}
                </ul>
            </div>
        </Loader>
    );
}
