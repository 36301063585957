import React, {Fragment, useContext, useEffect, useState} from "react";
import {
    CheckCircleIcon,
    ChevronRightIcon,
    Cog6ToothIcon,
    MagnifyingGlassIcon,
    PlusIcon,
    XCircleIcon,
} from "@heroicons/react/20/solid";
import {LocationDataContext} from "../../context/LocationDataProvider";
import Loader from "../atoms/Loader";
import {Link, useNavigate} from "react-router-dom";
import {Dialog, Transition} from "@headlessui/react";
import SlideboxSlider from "../molecules/SideboxSlider";
import DropdownChecked from "../atoms/Inputs/DropdownChecked";
import ErrorBox from "../molecules/ErrorBox";
import Pagination from "../molecules/Pagination";
import SimplePagination from "../molecules/SimplePagination";
import ExportExcelLink from "../molecules/excel/ExportExcelLink";
import Unauthorized from "../pages/Unauthorized";

export default function LocationList() {
    let [isOpen, setIsOpen] = useState(false);

    const {fetchAllLocationAsync, postNewLocation, isLoading} =
        useContext(LocationDataContext);
    const [locationData, setLocationData] = useState("loading");
    const [mailingAddress, setMailingAddress] = useState("");
    const [mailingAddress2, setMailingAddress2] = useState("");
    const [mailingCity, setMailingCity] = useState("");
    const [mailingState, setMailingState] = useState("");
    const [mailingZip, setMailingZip] = useState("");
    const [locationType, setLocationType] = useState("");
    const [errorMessage, setErrorMessage] = useState([]);
    const [inputValue, setInputValue] = useState("");

    const [searchLocation, setSearchLocation] = useState("");
    const [filterListOpen, setFilterListOpen] = useState(false);
    const [servicingStatusFilter, setServicingStatusFilter] = useState(JSON.parse(localStorage.getItem('servicingStatusFilter')) || {
        name: "Currently Servicing",
    });
    const [statusStatusFilter, setStatusStatusFilter] = useState(JSON.parse(localStorage.getItem('statusStatusFilter')) || {
        name: "All",
    });
    const servicingSortOptions = [
        {name: "Currently Servicing"},
        {name: "Not Currently Servicing"},
        // { name: "No Sort" },
    ];
    const statusSortOptions = [
        {name: "All"},
        {name: "Not Paid"},
        {name: "Paid"},
        {name: "Off"},
        {name: "On"},
    ];
    const [searchParams, setSearchParam] = useState({
        mailing_address: searchLocation,
        active_location:
            servicingStatusFilter.name === "Currently Servicing" ? "true" : "false",
        status: JSON.parse(localStorage.getItem('statusStatusFilter')) ? JSON.parse(localStorage.getItem('statusStatusFilter')).name : "",
    });

    useEffect(() => {
        localStorage.setItem("servicingStatusFilter", JSON.stringify({name: servicingStatusFilter.name}));
        setSearchParam({
            ...searchParams,
            active_location: servicingStatusFilter.name === "Currently Servicing" ? "true" : "false"
        })
    }, [servicingStatusFilter]);

    useEffect(() => {
        if(statusStatusFilter.name !== "All") {
            localStorage.setItem("statusStatusFilter", JSON.stringify({name: statusStatusFilter.name}));
        } else {
            localStorage.removeItem("statusStatusFilter");
        }
        setSearchParam({
            ...searchParams,
            status: statusStatusFilter.name === "All" ? "" : statusStatusFilter.name
        })
    }, [statusStatusFilter]);

    const navigate = useNavigate();

    function closeModal() {
        setIsOpen(false);
        setMailingAddress("");
        setMailingAddress2("");
        setMailingCity("");
        setMailingState("");
        setMailingZip("");
        setLocationType("");
        setErrorMessage([]); // Reset
    }

    function openModal() {
        setErrorMessage([]); // Reset
        setIsOpen(true);
    }

    useEffect(() => {
        fetchAllLocationAsync(setLocationData, searchParams, 50, 1);
    }, [searchParams]);

    return (
        <Loader loading={locationData === "loading"}>
            {locationData !== "loading" && locationData.errMsg ?
                (<>
                    <Unauthorized/>
                </>)
                :
                (<>
                    <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                        <h3 className="text-xl ml-2 font-medium leading-6 text-gray-900">
                            Locations
                        </h3>
                        <div className="mt-3 sm:mt-0 sm:ml-4">
                            <label htmlFor="desktop-search-candidate" className="sr-only">
                                Search
                            </label>
                            <div className="flex rounded-md shadow-sm">
                                <div className="relative flex-grow focus-within:z-10">
                                    <div
                                        className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <MagnifyingGlassIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <input
                                        type="text"
                                        name="mobile-search-candidate"
                                        id="mobile-search-candidate"
                                        className="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-blue-500 focus:ring-blue-500 sm:hidden"
                                        placeholder="Search"
                                        value={inputValue}
                                        onChange={(e) => {
                                            setInputValue(e.target.value);
                                            if (
                                                e.target.value.length >= 2 ||
                                                e.target.value.length === 0
                                            ) {
                                                setSearchParam({
                                                    ...searchParams,
                                                    mailing_address: e.target.value,
                                                });
                                            }
                                        }}
                                    />
                                    <input
                                        type="text"
                                        name="desktop-search-candidate"
                                        id="desktop-search-candidate"
                                        className="hidden w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-blue-500 focus:ring-blue-500 sm:block sm:text-sm"
                                        placeholder="Search locations"
                                        value={inputValue}
                                        onChange={(e) => {
                                            setInputValue(e.target.value);
                                            if (
                                                e.target.value.length >= 2 ||
                                                e.target.value.length === 0
                                            ) {
                                                setSearchParam({
                                                    ...searchParams,
                                                    mailing_address: e.target.value,
                                                });
                                            }
                                        }}
                                    />
                                </div>

                                <button
                                    type="button"
                                    className="relative -ml-px inline-flex items-center border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                    onClick={() => {
                                        navigate("/locations/create");
                                    }}
                                >
                                    <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    <span className="ml-1 hidden md:inline">Add Location</span>
                                </button>
                                <ExportExcelLink
                                    excelData={locationData.content}
                                    fileName={"Locations_Excel"}
                                />
                                <button
                                    type="button"
                                    className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                    onClick={() => setFilterListOpen(true)}
                                >
                                    <Cog6ToothIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="overflow-hidden bg-white shadow sm:rounded-md">
                        <SimplePagination
                            fetchFunc={fetchAllLocationAsync}
                            searchParams={searchParams}
                            pageCount={locationData.pageCount}
                            maxPage={Math.ceil(
                                locationData.pageCountLength / locationData.pageCount
                            )}
                            page={locationData.page}
                            setFunc={setLocationData}
                            pageCountLength={locationData.pageCountLength}
                        />
                        <Loader loading={isLoading === true}>
                            <ul role="list" className="divide-y divide-gray-200">
                                {locationData !== "loading" &&
                                    locationData.content &&
                                    locationData.content
                                        .filter((location) =>
                                            `${location.mailing_address} ${location.mailing_city} ${location.mailing_state}, ${location.mailing_zip}`
                                                .toLowerCase()
                                                .includes(searchLocation.toLowerCase())
                                        )
                                        .filter((location) => {
                                            /** Is servicing Currently filter */
                                            let filter = servicingStatusFilter.name;
                                            if (filter === "Currently Servicing") {
                                                if (location.active_location) {
                                                    return location;
                                                }
                                            }
                                            if (filter === "Not Currently Servicing") {
                                                if (!location.active_location) {
                                                    return location;
                                                }
                                            }
                                            if (filter === "No Sort") {
                                                return location;
                                            }
                                        })
                                        .map((location) => (
                                            <li key={location._id}>
                                                <Link
                                                    to={`/locations/${location._id}`}
                                                    className="block hover:bg-gray-50"
                                                >
                                                    <div className="flex items-center px-4 py-4 sm:px-6">
                                                        <div className="flex min-w-0 flex-1 items-center">
                                                            <div
                                                                className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                                <div className="flex items-center">
                                                                    <div>
                                                                        <p className="truncate text-sm font-medium text-blue-900">
                                                                            {location.name}
                                                                        </p>
                                                                        <p className="truncate text-sm font-medium text-blue-500">
                                                                            {location.mailing_address} {location.mailing_city}{" "}
                                                                            {location.mailing_state}, {location.mailing_zip}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="hidden md:block">
                                                                    <div>
                                                                        <p className="text-sm text-gray-900">
                                                                            Location Created on{" "}
                                                                            <time dateTime={location.date_added}>
                                                                                {new Date(location.date_added).toDateString()}
                                                                            </time>
                                                                        </p>
                                                                        <p className="mt-2 flex items-center text-sm text-gray-500">
                                                                            {location.active_location ? (
                                                                                <CheckCircleIcon
                                                                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"/>
                                                                            ) : (
                                                                                <XCircleIcon
                                                                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"/>
                                                                            )}
                                                                            {location.active_location ? "Currently" : "Not"}{" "}
                                                                            Servicing{" "}
                                                                            ({location.status ? location.status : "Not Paid"})
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <ChevronRightIcon
                                                                className="h-5 w-5 text-gray-400"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                            </ul>
                        </Loader>
                        <Pagination
                            searchParams={searchParams}
                            fetchFunc={fetchAllLocationAsync}
                            pageCount={locationData.pageCount}
                            maxPage={Math.ceil(
                                locationData.pageCountLength / locationData.pageCount
                            )}
                            page={locationData.page}
                            setFunc={setLocationData}
                            total={locationData.pageCountLength}
                        />
                    </div>
                    <SlideboxSlider
                        title={"Filter Settings"}
                        open={filterListOpen}
                        setOpen={setFilterListOpen}
                    >
                        <DropdownChecked
                            title={"Servicing"}
                            classes="mb-3"
                            setSelected={setServicingStatusFilter}
                            selected={servicingStatusFilter}
                            options={servicingSortOptions}
                        />
                        <DropdownChecked
                            title={"Status"}
                            classes="mb-3"
                            setSelected={setStatusStatusFilter}
                            selected={statusStatusFilter}
                            options={statusSortOptions}
                        />
                    </SlideboxSlider>
                    <Transition appear show={isOpen} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={closeModal}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black bg-opacity-25"/>
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Dialog.Panel
                                            className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-lg font-medium leading-6 text-gray-6 00"
                                            >
                                                Create New Location
                                            </Dialog.Title>

                                            {/* form */}
                                            <div>
                                                <label
                                                    htmlFor="MailingAddress"
                                                    className="mt-3 block text-sm font-medium text-gray-700"
                                                >
                                                    Address Line 1
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="MailingAddress"
                                                        id="MailingAddress"
                                                        value={mailingAddress}
                                                        onChange={(e) => setMailingAddress(e.target.value)}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                        placeholder="123 Main St."
                                                    />
                                                </div>
                                                <label
                                                    htmlFor="MailingAddress2"
                                                    className="mt-3 block text-sm font-medium text-gray-700"
                                                >
                                                    Address Line 2
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="MailingAddress2"
                                                        id="MailingAddress2"
                                                        value={mailingAddress2}
                                                        onChange={(e) => setMailingAddress2(e.target.value)}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                        placeholder="Apt 22, ..."
                                                    />
                                                </div>
                                                <label
                                                    htmlFor="MailingCity"
                                                    className="mt-3 block text-sm font-medium text-gray-700"
                                                >
                                                    City
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="MailingCity"
                                                        id="MailingCity"
                                                        value={mailingCity}
                                                        onChange={(e) => setMailingCity(e.target.value)}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                        placeholder="Brandenburg"
                                                    />
                                                </div>
                                                <label
                                                    htmlFor="MailingState"
                                                    className="mt-3 block text-sm font-medium text-gray-700"
                                                >
                                                    State
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="MailingState"
                                                        id="MailingState"
                                                        value={mailingState}
                                                        onChange={(e) => setMailingState(e.target.value)}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                        placeholder="KY"
                                                    />
                                                </div>
                                                <label
                                                    htmlFor="MailingZip"
                                                    className="mt-3 block text-sm font-medium text-gray-700"
                                                >
                                                    Zip
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        type="text"
                                                        name="MailingZip"
                                                        id="MailingZip"
                                                        value={mailingZip}
                                                        onChange={(e) => setMailingZip(e.target.value)}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                        placeholder="40175"
                                                    />
                                                </div>

                                                <div className="mt-1">
                                                    <label
                                                        htmlFor="locationType"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Location
                                                    </label>
                                                    <select
                                                        id="locationType"
                                                        name="locationType"
                                                        className="mt-1 mb-3 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                                                        onChange={(e) => setLocationType(e.target.value)}
                                                    >
                                                        <option value="" disabled={true}>
                                                            Chose one...
                                                        </option>
                                                        <option value="Residential">Residential</option>
                                                        <option value="Commercial">Commercial</option>
                                                        <option value="Other">Other</option>
                                                    </select>

                                                    {errorMessage.length > 0 && (
                                                        <ErrorBox errorsArr={errorMessage}/>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="mt-4 float-right">
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={closeModal}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                    onClick={() => {
                                                        let okay = true;

                                                        /** Validation */
                                                        if (mailingAddress.trim() === "") {
                                                            setErrorMessage((oldErrors) => [
                                                                ...oldErrors,
                                                                "Specify a address",
                                                            ]);
                                                        }
                                                        if (mailingCity.trim() === "") {
                                                            setErrorMessage((oldErrors) => [
                                                                ...oldErrors,
                                                                "Specify a City",
                                                            ]);
                                                        }
                                                        if (mailingState.trim() === "") {
                                                            setErrorMessage((oldErrors) => [
                                                                ...oldErrors,
                                                                "Specify an State",
                                                            ]);
                                                        }
                                                        if (mailingZip.trim() === "") {
                                                            setErrorMessage((oldErrors) => [
                                                                ...oldErrors,
                                                                "Specify a Zip",
                                                            ]);
                                                        }
                                                        if (locationType.trim() === "") {
                                                            setErrorMessage((oldErrors) => [
                                                                ...oldErrors,
                                                                "Specify a Location Type",
                                                            ]);
                                                        }

                                                        /** Submission */
                                                        if (
                                                            mailingAddress.trim() === "" ||
                                                            mailingCity.trim() === "" ||
                                                            mailingState.trim() === "" ||
                                                            mailingZip.trim() === "" ||
                                                            locationType.trim() === ""
                                                        ) {
                                                            // alert("There were problems with your submission.");
                                                            okay = false;
                                                        }

                                                        if (okay) {
                                                            postNewLocation(setLocationData, {
                                                                mailing_address: mailingAddress,
                                                                mailing_address_2: mailingAddress2,
                                                                mailing_city: mailingCity,
                                                                mailing_state: mailingState,
                                                                mailing_zip: mailingZip,
                                                                location_type: locationType,
                                                            });
                                                            setMailingAddress("");
                                                            setMailingAddress2("");
                                                            setMailingCity("");
                                                            setMailingState("");
                                                            setMailingZip("");
                                                            setLocationType("");
                                                            closeModal();
                                                        }
                                                    }}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                </>)}
        </Loader>
    );
}
