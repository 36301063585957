import React, { useContext, useEffect, useState } from "react";
import Loader from "../../atoms/Loader";
import DescriptionListRowWithLabel from "../../molecules/DescriptionListRowWithLabel";
import TextInput from "../../atoms/Inputs/TextInput";
import ErrorBox from "../../molecules/ErrorBox";
import Button from "../../atoms/Buttons/Button";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "../../../context/UserDataProvider";
import LocationSelectModal from "./LocationSelectModal";
import { LocationDataContext } from "../../../context/LocationDataProvider";
import { WorkOrderDataContext } from "../../../context/WorkOrderDataProvider";

const CreateNewWorkOrder = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [issues, setIssues] = useState([]);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [locationNamePreview, setLocationNamePreview] = useState("");
  const [userList, setUserList] = useState("loading");
  const [locationSearchField, setLocationSearchField] = useState("");
  const [locationField, setLocationField] = useState("");
  const [allLocations, setAllLocations] = useState("loading");

  const { getAllUsers } = useContext(UserDataContext);
  const { fetchAllLocationAsync } = useContext(LocationDataContext);
  const { postNewWorkOrderRedirect } = useContext(WorkOrderDataContext);

  useEffect(() => {
    getAllUsers(setUserList);
    fetchAllLocationAsync(setAllLocations);
  }, []);

  const navigate = useNavigate();

  const [postObj, setPostObj] = useState({
    /** Defaults **/
    status: 0,
    type: "spring startup",
    othertype: "",
  });

  const handleSubmission = () => {
    let finalSubmitObj = postObj;
    delete finalSubmitObj.othertype;
    finalSubmitObj.location_id = locationField;

    if (finalSubmitObj.type === "other") {
      finalSubmitObj.type = postObj.othertype;
    }

    setIsLoading(
      true
    ); /** Disable form, show loading so that it cannot be double-submitted **/
    let okay = true;

    setIssues([]);
    if (locationField.trim() === "") {
      okay = false;
      setIssues((oldIssues) => [...oldIssues, "Please specify a location"]);
    }

    if (okay) {
      postNewWorkOrderRedirect(finalSubmitObj, "/workorders", navigate);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Loader loading={isLoading === true}>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex justify-between">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Customer Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Enter basic information for this customer
            </p>
          </div>
          {/* <div>
              <Button
                type="lg"
                color="blue"
                onClick={() => {
                  navigate("/workorders/masscreate");
                }}
                className="mr-2 mt-2"
              >
                Create Mass Work Order
              </Button>
          </div> */}
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <DescriptionListRowWithLabel label={"Location"}>
              <div className="mt-1">
                <span
                  className="text-blue-500 cursor-pointer"
                  onClick={() => {
                    setLocationModalOpen(true);
                  }}
                >
                  Click here to select a
                  {locationNamePreview.trim() !== "" ? " different" : null}{" "}
                  location
                </span>
              </div>

              {locationNamePreview.trim() !== "" && (
                <div className="mt-1">
                  <span className="text-gray-500">
                    Currently Selected:{" "}
                    {locationNamePreview ? locationNamePreview : "(Missing Address)"}
                  </span>
                </div>
              )}
            </DescriptionListRowWithLabel>

            <DescriptionListRowWithLabel label={"Status"}>
              <select
                id="status"
                name="status"
                className="relative block w-full rounded-none rounded-t-md rounded-b-md border-gray-300 bg-transparent focus:z-10 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                onChange={(e) =>
                  setPostObj({ ...postObj, status: parseInt(e.target.value) })
                }
                value={postObj.status}
              >
                <option value="0">Not Started</option>
                <option value="1">In Progress</option>
                <option value="2">Completed</option>
              </select>
            </DescriptionListRowWithLabel>

            <DescriptionListRowWithLabel label={"Job Type"}>
              <select
                id="status"
                name="status"
                className="relative block w-full rounded-none rounded-t-md rounded-b-md border-gray-300 bg-transparent focus:z-10 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                onChange={(e) =>
                  setPostObj({ ...postObj, type: e.target.value })
                }
                value={postObj.type}
              >
                <option value="spring startup">Spring Startup</option>
                <option value="fall shutdown">Fall Shutdown</option>
                <option value="service">Service</option>
                <option value="other">Other</option>
              </select>
            </DescriptionListRowWithLabel>

            {postObj.type !== "spring startup" &&
              postObj.type !== "fall shutdown" &&
              postObj.type !== "service" && (
                <DescriptionListRowWithLabel label={"Job Type"}>
                  <TextInput
                    name="email"
                    placeholder="(Enter other type here...)"
                    value={postObj.othertype}
                    onChange={(e) =>
                      setPostObj({ ...postObj, othertype: e.target.value })
                    }
                  />
                </DescriptionListRowWithLabel>
              )}

            <DescriptionListRowWithLabel label={"Due Date"}>
              <input
                type={"date"}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                name="duedate"
                placeholder="(Enter due date here...)"
                value={postObj.date_due}
                onChange={(e) =>
                  setPostObj({ ...postObj, date_due: e.target.value })
                }
              />
            </DescriptionListRowWithLabel>

            <DescriptionListRowWithLabel label={"Scheduled Date"}>
              <input
                type={"date"}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                name="scheduleddate"
                placeholder="(Enter scheduled date here...)"
                value={postObj.date_scheduled}
                onChange={(e) =>
                  setPostObj({ ...postObj, date_scheduled: e.target.value })
                }
              />
            </DescriptionListRowWithLabel>

            <DescriptionListRowWithLabel label={"About"}>
              <textarea
                id="about"
                name="about"
                rows={3}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                placeholder="Write about this customer for the customer hub page"
                value={postObj.notes}
                onChange={(e) =>
                  setPostObj({ ...postObj, notes: e.target.value })
                }
              />
            </DescriptionListRowWithLabel>

            <DescriptionListRowWithLabel label={"Assign Job To Driver"}>
              <Loader loading={userList === "loading"}>
                {userList && userList !== "loading" && (
                  <select
                    id="status"
                    name="status"
                    className="relative block w-full rounded-none rounded-t-md rounded-b-md border-gray-300 bg-transparent focus:z-10 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    onChange={(e) =>
                      setPostObj({
                        ...postObj,
                        selected_driver: e.target.value,
                      })
                    }
                    value={postObj.selected_driver}
                  >
                    <option key={"base"} value={""}>
                      Select A Driver...
                    </option>
                    {userList.content.map(
                      (user) =>
                        user.account_level < 3 && (
                          <option key={user._id} value={user._id}>
                            {user.name}
                          </option>
                        )
                    )}
                  </select>
                )}
              </Loader>
            </DescriptionListRowWithLabel>

            <DescriptionListRowWithLabel label={"Actions"}>
              {issues.length > 0 && (
                <div className={"mb-3"}>
                  <ErrorBox errorsArr={issues} />
                </div>
              )}
              <Button
                type="lg"
                color="blue"
                onClick={() => handleSubmission()}
                className="mr-2"
              >
                Save
              </Button>
              <Button
                type="lg"
                color="red"
                onClick={() => navigate("/workorders")}
              >
                Cancel
              </Button>
            </DescriptionListRowWithLabel>
          </dl>
        </div>
      </div>
      <LocationSelectModal
        setLocationModalOpen={setLocationModalOpen}
        locationModalOpen={locationModalOpen}
        allLocations={allLocations.content || []}
        locationSearchField={locationSearchField}
        setLocationSearchField={setLocationSearchField}
        openModal={function () {}}
        setLocationField={setLocationField}
        setLocatioNamePreview={setLocationNamePreview}
      />
    </Loader>
  );
};

export default CreateNewWorkOrder;
