import PartDataProvider from "../context/PartDataProvider";
import {Outlet} from "react-router-dom";
import WorkOrderDataProvider from "../context/WorkOrderDataProvider";
import UserDataProvider from "../context/UserDataProvider";
import LocationDataProvider from "../context/LocationDataProvider";

const WorkOrdersOutlet = () => {
    return (
        <WorkOrderDataProvider>
            <PartDataProvider>
                <LocationDataProvider>
                    <UserDataProvider>
                        <Outlet/>
                    </UserDataProvider>
                </LocationDataProvider>
            </PartDataProvider>
        </WorkOrderDataProvider>
    );
}

export default WorkOrdersOutlet;
