import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const SimplePagination = ({
  fetchFunc,
  searchParams,
  setFunc,
  pageCount,
  page,
  maxPage,
}) => {
  return (
    <div className="py-5 flex justify-between items-center border-b">
      {page > 1 && (
        <button
          onClick={() => fetchFunc(setFunc, searchParams, pageCount, page - 1)}
          className="relative inline-flex items-center px-2 py-2 text-sm text-gray-400 ring-gray-300 focus:z-20 focus:none"
        >
          <ChevronLeftIcon
            className="mt-1 h-5 w-5 text-gray-400 hover:text-gray-600"
            aria-hidden="true"
          />
          <span className="text-base">Previous</span>
        </button>
      )}
      {page == 1 && <div></div>}
      {page < maxPage && (
        <button
          onClick={() => fetchFunc(setFunc, searchParams, pageCount, page + 1)}
          className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-gray-300 focus:z-20 focus:none"
        >
          <span className="text-base">Next</span>
          <ChevronRightIcon
            className="mt-1 h-5 w-5 text-gray-400 hover:text-gray-600"
            aria-hidden="true"
          />
        </button>
      )}
    </div>
  );
};

export default SimplePagination;
