const ContentPageHeader = (props) => {
    return (
        <div
            className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-6xl lg:px-8">
            <div className="flex items-center space-x-5">
                <div>
                    <h1 className="text-2xl font-bold text-gray-900">{props.name}</h1>
                    <p className="text-sm font-medium text-gray-500">
                        {props.children ? props.children : 'Customer Since'}{' '}
                        <time dateTime={props.dateAdded}>{new Date(props.dateAdded).toDateString()}</time>
                    </p>
                </div>
            </div>
            {props.rightCol && <div
                className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">{props.rightCol}</div>}
        </div>
    );
}

export default ContentPageHeader;
