import React, { Fragment, useContext, useEffect, useState } from "react";
import { CheckIcon, HomeIcon, PencilIcon, UserIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../atoms/Loader";
import { UserContext } from "../../../context/UserProvider";
import { WorkOrderDataContext } from "../../../context/WorkOrderDataProvider";
import { PartDataContext } from "../../../context/PartDataProvider";
import EmptyContentPage from "../../templates/EmptyContentPage";
import ContentPageHeader from "../../organisms/ContentPageHeader";
import ModalForm1 from "../../molecules/ModalForm1";
import { Dialog, Transition } from "@headlessui/react";
import TextInput from "../../atoms/Inputs/TextInput";
import ErrorBox from "../../molecules/ErrorBox";
import { NavigateJobsComponent } from "./DriverApp/navigateJobsComponent";

export default function WorkOrderProfile() {
    let { workorderID } = useParams();

    const [userData, setUserData] = useState("loading");
    const [workOrderComments, setWorkOrderComments] = useState("loading");
    const [workOrderLocations, setWorkOrderLocations] = useState("loading");
    const [workOrderAssignments, setWorkOrderAssignments] = useState("loading");
    const [userList, setUserList] = useState("loading");
    const [userSearchVal, setUserSearchVal] = useState("");
    const [openModal, SetOpenModal] = useState(true);
    const [statusDriverModal, setDriverModal] = useState(false);
    const [inputNote, setInputNote] = useState("");
    const [selectedLocationList, setSelectedLocationList] = useState([]);
    const [locationSearch, setLocationSearch] = useState("");

    const [checkMeterOpen, setCheckMeterOpen] = useState(false);

    /** Parts Select Modal **/
    const [partsSelectModal, setPartSelectModal] = useState(false);
    const [partsModalSearch, setPartsModalSearch] = useState("");
    const [partsModalData, setPartsModalData] = useState("loading");
    const [partsListWorkOrderPage, setPartsListWorkOrderPage] = useState("loading");

    /** Parts Create Modal **/
    const [partsCreateModalOpen, setPartsCreateModalOpen] = useState(false);
    const [itemDescription, setItemDescription] = useState("");
    const [issues, setIssues] = useState([]);

    /** Parts Delete Modal **/
    const [deleteModal, setDeleteModal] = useState(false);
    const [commentToDelete, setCommentToDelete] = useState(null);
    let [isOpen, setIsOpen] = useState(false);

    function closeDelete() {
        setDeleteModal(false);
    }

    function openDeleteModal() {
        setIsOpen(true);
    }

    function closeDeleteModal() {
        setIsOpen(false);
    }

    const {
        fetchWorkOrderAsync,
        fetchAllWorkOrderNotesAsync,
        postWorkOrderCommentAsync,
        deleteWorkOrderCommentAsync,
        fetchAllWorkOrderAssignmentAsync,
        deleteWorkOrderAssignmentAsyncNoRedirect,
        fetchAllUsersAsync,
        postWorkOrderAssignmentAsync,
        modifyWorkOrderAsyncNoRedirect,
        fetchAllPartsAsync,
        fetchPartsWorkOrderAsync,
        postPartsWorkOrderAsync,
        modifyPartsWorkOrderAsyncNoRedirect,
        deletePartsWorkOrderAsyncNoRedirect,
        editWorkOrderCommentAsync,
    } = useContext(WorkOrderDataContext);
    const { postNewPart } = useContext(PartDataContext);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const [commentToEditID, setCommentToEditID] = useState("");
    const [commentChangeContent, setCommentChangeContent] = useState("");

    useEffect(() => {
        fetchWorkOrderAsync(setUserData, workorderID);
        // scheduleAutoRefresh(() => {
        //   fetchWorkOrderAsync(setUserData, workorderID);
        // }, 30); // Run every 30 seconds.
        fetchAllWorkOrderNotesAsync(setWorkOrderComments, workorderID);
        // scheduleAutoRefresh(() => {
        //   fetchAllWorkOrderNotesAsync(setWorkOrderComments, workorderID);
        // }, 30); // Run every 30 seconds.
        fetchAllWorkOrderAssignmentAsync(setWorkOrderAssignments, workorderID);
        // scheduleAutoRefresh(() => {
        //   fetchAllWorkOrderAssignmentAsync(setWorkOrderAssignments, workorderID);
        // }, 60); // Run every 60 seconds.
        fetchAllUsersAsync(setUserList); // Do we need to refresh this list, truly? or is it a waste of bandwidth?
        fetchAllPartsAsync(setPartsModalData);
        // scheduleAutoRefresh(() => {
        //   fetchAllPartsAsync(setPartsModalData);
        // }, 120); // Ensure this is always accurate every 120 seconds.
        fetchPartsWorkOrderAsync(setPartsListWorkOrderPage, workorderID);
        // scheduleAutoRefresh(() => {
        //   fetchPartsWorkOrderAsync(setPartsListWorkOrderPage, workorderID);
        // }, 120); // Ensure this is always accurate every 120 seconds.
    }, []);

    useEffect(() => {
        /* Scroll to bottom of notes section whenever it updates. Always */
        if (document.getElementById("scrollbox")) {
            /* Prevent issues with div not being rendered yet */
            document.getElementById("scrollbox").scrollTop = document.getElementById("scrollbox").scrollHeight;
        }
    }, [workOrderComments]);

    const submitWorkOrderComment = (workorderID, message) => {
        postWorkOrderCommentAsync(setWorkOrderComments, {
            user_ref: user._id,
            workorder_ref: workorderID,
            content: message,
        });
        setInputNote("");
    };

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    return (
        <Loader loading={userData === "loading"}>
            <ModalForm1 open={checkMeterOpen} setOpen={setCheckMeterOpen}>
                <p className="my-5 text-center font-semibold ">Did You Check The Meter?</p>
                <div className="w-full max-w-xs">
                    <div className="mt-5 float-right">
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-8 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={async () => {
                                await modifyWorkOrderAsyncNoRedirect(workorderID, {
                                    status: 2,
                                });
                                await setUserData((oldUserData) => {
                                    return {
                                        content: [{ ...oldUserData.content[0], status: 2 }],
                                    };
                                });
                                await setCheckMeterOpen(false);
                            }}
                        >
                            Yes
                        </button>
                        <button
                            type="button"
                            className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-red-600 px-8 py-2 text-sm font-medium text-white hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                            onClick={() => setCheckMeterOpen(false)}
                        >
                            No
                        </button>
                    </div>
                </div>
            </ModalForm1>
            <ModalForm1 setOpen={setDriverModal} open={statusDriverModal}>
                <div className="mb-5">
                    <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                            Select Driver
                        </Dialog.Title>
                        <div className="mt-2" style={{ maxHeight: "450px", overflowY: "scroll" }}>
                            <Loader loading={userList === "loading"}>
                                {userList !== "loading" && (
                                    <>
                                        <div className="my-3 mx-2">
                                            <TextInput
                                                name="searchUsers"
                                                id="searchUsers"
                                                placeholder="Search Website Users"
                                                value={userSearchVal}
                                                onChange={(e) => setUserSearchVal(e.target.value)}
                                            />
                                        </div>
                                        <ul
                                            role="list"
                                            className="divide-y divide-gray-200 rounded-md border border-gray-200"
                                        >
                                            {userList.content &&
                                                userList.content
                                                    .filter((user) =>
                                                        user.name.toLowerCase().includes(userSearchVal.toLowerCase())
                                                    )
                                                    .filter((user) => user.account_level < 3)
                                                    .map((user) => (
                                                        <li
                                                            key={user.name}
                                                            className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                                                        >
                                                            <div className="flex w-0 flex-1 items-center">
                                                                <UserIcon
                                                                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                                <span className="ml-2 w-0 flex-1 truncate">
                                                                    {user.name}
                                                                </span>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                {workOrderAssignments !== "loading" && (
                                                                    <button
                                                                        type="button"
                                                                        className="inline-flex items-center rounded border border-transparent bg-blue-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                                        onClick={() => {
                                                                            /** TODO: Make server populate for this specific assignment before sending back the data */
                                                                            postWorkOrderAssignmentAsync(
                                                                                setWorkOrderAssignments,
                                                                                {
                                                                                    workorder_id: workorderID,
                                                                                    user_id: user._id,
                                                                                }
                                                                            ); // Does not populate
                                                                            fetchAllWorkOrderAssignmentAsync(
                                                                                setWorkOrderAssignments,
                                                                                workorderID
                                                                            ); // This one has populate
                                                                            setDriverModal(false);
                                                                        }}
                                                                    >
                                                                        Select Driver
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </li>
                                                    ))}
                                        </ul>
                                    </>
                                )}
                            </Loader>
                        </div>
                    </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 col-span-2 sm:mt-0 sm:text-sm"
                        onClick={() => setDriverModal(false)}
                    >
                        Done
                    </button>
                </div>
            </ModalForm1>
            <ModalForm1 setOpen={setPartSelectModal} open={partsSelectModal}>
                <div className="mb-5">
                    <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                            Select From Database
                        </Dialog.Title>
                        <div className="mt-2" style={{ maxHeight: "450px", overflowY: "scroll" }}>
                            <Loader loading={partsModalData === "loading"}>
                                {partsModalData !== "loading" && partsListWorkOrderPage.content && (
                                    <>
                                        <div className="my-3 mx-2">
                                            <TextInput
                                                name="searchParts"
                                                id="searchParts"
                                                placeholder="Search parts or repairs..."
                                                value={partsModalSearch}
                                                onChange={(e) => setPartsModalSearch(e.target.value)}
                                            />
                                            <span
                                                onClick={() => setPartsCreateModalOpen(true)}
                                                className={"text-blue-500 cursor-pointer block mt-2 mb-4"}
                                            >
                                                Don't see what you're looking for? Click here.
                                            </span>
                                        </div>
                                        <ul
                                            role="list"
                                            className="divide-y divide-gray-200 rounded-md border border-gray-200"
                                        >
                                            {partsModalData.content &&
                                                partsModalData.content
                                                    .filter((part) =>
                                                        part.itemDescription
                                                            .toLowerCase()
                                                            .includes(partsModalSearch.toLowerCase())
                                                    )
                                                    .filter((part) => {
                                                        let okay = true;
                                                        partsListWorkOrderPage.content.forEach((partOnPage) => {
                                                            if (partOnPage.part_ref._id === part._id) {
                                                                okay = false;
                                                            }
                                                        });
                                                        if (okay) {
                                                            return part;
                                                        }
                                                    })
                                                    .map((part) => (
                                                        <li
                                                            key={part.itemDescription}
                                                            className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                                                        >
                                                            <div className="flex w-0 flex-1 items-start">
                                                                <span className=" w-0 flex-1 items-start truncate text-left">
                                                                    {" "}
                                                                    {part.itemDescription}
                                                                </span>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <button
                                                                    type="button"
                                                                    className="inline-flex items-center rounded border border-transparent bg-blue-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                                    onClick={async () => {
                                                                        await console.log(
                                                                            "pre-state",
                                                                            partsListWorkOrderPage
                                                                        );
                                                                        await postPartsWorkOrderAsync(
                                                                            setPartsListWorkOrderPage,
                                                                            {
                                                                                workorder_ref: workorderID,
                                                                                part_ref: part._id,
                                                                            }
                                                                        );
                                                                        setTimeout(() => {
                                                                            console.log(
                                                                                "post-state",
                                                                                partsListWorkOrderPage
                                                                            );
                                                                        }, 3000);
                                                                        await setPartSelectModal(false);
                                                                    }}
                                                                >
                                                                    Add Item
                                                                </button>
                                                            </div>
                                                        </li>
                                                    ))}
                                        </ul>
                                    </>
                                )}
                            </Loader>
                        </div>
                    </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:col-start-1 col-span-2 sm:mt-0 sm:text-sm"
                        onClick={() => setPartSelectModal(false)}
                    >
                        Done
                    </button>
                </div>
            </ModalForm1>
            <ModalForm1 setOpen={setPartsCreateModalOpen} open={partsCreateModalOpen}>
                <div className="mb-5">
                    <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                            Create New Part / Repair
                        </Dialog.Title>
                        <div className={"text-left"}>
                            <label htmlFor="name" className="mt-3 block text-sm font-medium text-gray-700">
                                Item Name
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={(e) => setItemDescription(e.target.value)}
                                    value={itemDescription}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    placeholder="Enter Item Name"
                                />
                            </div>

                            {issues.length > 0 && <ErrorBox errorsArr={issues} />}
                        </div>
                        <div className={"mt-4 float-right"}>
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={() => {
                                    setPartsCreateModalOpen(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                onClick={() => {
                                    let okay = true;
                                    setIssues([]);

                                    /** Validation **/
                                    if (itemDescription.trim() === "") {
                                        setIssues((oldIssues) => [...oldIssues, "Specify a item name"]);
                                        okay = false;
                                    }
                                    /** Submission **/
                                    if (okay) {
                                        postNewPart(setPartsModalData, {
                                            itemDescription,
                                        });
                                        setItemDescription("");
                                        setIssues([]);
                                        setPartsCreateModalOpen(false);
                                    }
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </ModalForm1>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeDelete}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className="my-5 text-center font-semibold">
                                        <p>Are you sure?</p>
                                    </div>

                                    <div className="mt-10 flex justify-center  ">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-8 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeDeleteModal}
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-red-600 px-8 py-2 text-sm font-medium text-white hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                            onClick={async () => {
                                                if (commentToDelete) {
                                                    setWorkOrderComments((oldComments) => ({
                                                        ...oldComments,
                                                        content: oldComments.content.filter(
                                                            (_comment) => _comment._id !== commentToDelete._id
                                                        ),
                                                    }));
                                                    await deleteWorkOrderCommentAsync(commentToDelete._id);
                                                    closeDeleteModal();
                                                }
                                            }}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

            {userData.content && (
                <EmptyContentPage
                    gridCols={2}
                    rightCol={
                        <section aria-labelledby="notes-title">
                            <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg lg:w-96">
                                <div className="divide-y divide-gray-200">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                            Notes
                                        </h2>
                                    </div>
                                    <Loader loading={workOrderComments === "loading"}>
                                        {workOrderComments.content &&
                                            workOrderComments.content.length > 0 &&
                                            workOrderComments !== "loading" && (
                                                <div
                                                    className="px-4 py-6 sm:px-6 max-h-60 min-h-60 overflow-y-auto"
                                                    id={"scrollbox"}
                                                >
                                                    <ul role="list" className="space-y-8">
                                                        {workOrderComments.content.map((comment) => {
                                                            return (
                                                                <li key={comment._id}>
                                                                    <div className="flex space-x-3">
                                                                        <div className="flex justify-between w-full">
                                                                            {" "}
                                                                            {/* Add this div */}
                                                                            <div>
                                                                                <div className="text-sm">
                                                                                    <a
                                                                                        href="#"
                                                                                        className="font-medium text-gray-900"
                                                                                    >
                                                                                        {comment.user_ref.name}
                                                                                    </a>
                                                                                </div>
                                                                                {commentToEditID !== comment._id && (
                                                                                    <>
                                                                                        <div className="mt-1 text-sm text-gray-700">
                                                                                            <p>{comment.content}</p>
                                                                                        </div>
                                                                                        <div className="mt-2 space-x-2 text-sm">
                                                                                            <span className="font-medium text-gray-500">
                                                                                                {new Date(
                                                                                                    comment.dateAdded
                                                                                                ).toDateString()}{" "}
                                                                                                (
                                                                                                {new Date(
                                                                                                    comment.dateAdded
                                                                                                ).toLocaleTimeString()}
                                                                                                )
                                                                                                {/*{JSON.stringify(user)}*/}
                                                                                            </span>{" "}
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            {(user.account_level > 1 ||
                                                                                user._id === comment.user_ref._id) && (
                                                                                <div className={"flex flex-row"}>
                                                                                    {commentToEditID !==
                                                                                        comment._id && (
                                                                                        <PencilIcon
                                                                                            className="h-4 w-4 mr-3 text-gray-400 cursor-pointer" // Adjust size and color here
                                                                                            onClick={() => {
                                                                                                setCommentToEditID(
                                                                                                    comment._id
                                                                                                );
                                                                                                setCommentChangeContent(
                                                                                                    comment.content
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                    {commentToEditID ===
                                                                                        comment._id && (
                                                                                        <CheckIcon
                                                                                            className="h-4 w-4 mr-3 text-gray-400 cursor-pointer" // Adjust size and color here
                                                                                            onClick={() => {
                                                                                                editWorkOrderCommentAsync(
                                                                                                    comment._id,
                                                                                                    {
                                                                                                        content:
                                                                                                            commentChangeContent,
                                                                                                    }
                                                                                                );
                                                                                                comment.content =
                                                                                                    commentChangeContent;
                                                                                                setCommentToEditID("");
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                    <XMarkIcon
                                                                                        className="h-4 w-4 text-gray-400 cursor-pointer" // Adjust size and color here
                                                                                        onClick={() => {
                                                                                            setCommentToDelete(comment);
                                                                                            openDeleteModal();
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {commentToEditID === comment._id && (
                                                                        <>
                                                                            <div
                                                                                className={
                                                                                    "mt-2 w-full block space-x-2"
                                                                                }
                                                                            >
                                                                                <textarea
                                                                                    id="about"
                                                                                    name="about"
                                                                                    rows={3}
                                                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                                    onChange={(e) =>
                                                                                        setCommentChangeContent(
                                                                                            e.target.value
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {commentChangeContent}
                                                                                </textarea>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            )}

                                        {workOrderComments.content && workOrderComments.content.length === 0 && (
                                            <>
                                                <div className="text-center py-8">
                                                    <svg
                                                        className="mx-auto h-12 w-12 text-gray-400"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            vectorEffect="non-scaling-stroke"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                                        />
                                                    </svg>
                                                    <h3 className="mt-2 text-sm font-medium text-gray-900">No Notes</h3>
                                                    <p className="mt-1 text-sm text-gray-500">
                                                        This work order has no active notes on profile.
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                    </Loader>
                                </div>
                                <div className="bg-gray-50 px-4 py-6 sm:px-6">
                                    <div className="flex space-x-3">
                                        <div className="min-w-0 flex-1">
                                            <form action="#">
                                                <div>
                                                    <label htmlFor="comment" className="sr-only">
                                                        About
                                                    </label>
                                                    <textarea
                                                        id="comment"
                                                        name="comment"
                                                        rows={3}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                        placeholder="Add a note"
                                                        value={inputNote}
                                                        onChange={(e) => setInputNote(e.target.value)}
                                                        defaultValue={""}
                                                    />
                                                </div>
                                                <div className="mt-3 flex items-center justify-between">
                                                    <button
                                                        type="submit"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            submitWorkOrderComment(workorderID, inputNote);
                                                        }}
                                                        className="inline-flex items-end ml-auto justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                    >
                                                        Submit new note
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                    header={
                        <ContentPageHeader
                            name={`Work Order: #${userData.content[0]._id.slice(-5).toUpperCase()}`}
                            dateAdded={userData.content[0].date_due}
                            rightCol={
                                <>
                                    {user.account_level > 1 && (
                                        <button
                                            type="button"
                                            onClick={() => navigate(`/workOrders/edit/${workorderID}`)}
                                            className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                        >
                                            Edit
                                        </button>
                                    )}
                                    {userData.content[0].status < 2 && (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setCheckMeterOpen(true);
                                            }}
                                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                        >
                                            Mark Job Complete
                                        </button>
                                    )}
                                    {userData.content[0].status === 2 && (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                modifyWorkOrderAsyncNoRedirect(workorderID, {
                                                    status: 0,
                                                });
                                                setUserData((oldUserData) => {
                                                    return {
                                                        content: [{ ...oldUserData.content[0], status: 0 }],
                                                    };
                                                });
                                            }}
                                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                        >
                                            Mark Job Incomplete
                                        </button>
                                    )}
                                </>
                            }
                        >
                            Work Order Due:
                        </ContentPageHeader>
                    }
                >
                    {/* Description list*/}
                    <section aria-labelledby="applicant-information-title max-w-full">
                        <div className="bg-white shadow sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <h2
                                    id="applicant-information-title"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Information
                                </h2>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">Details of this work order.</p>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                {user.account_level < 2 && (
                                    <NavigateJobsComponent thisJob={userData.content[0]._id} navigate={navigate} />
                                )}
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Address</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {userData !== "loading" && userData.content && (
                                                <b className={"mb-2"}>{userData.content[0].location_id.name}</b>
                                            )}
                                            <br />
                                            {userData !== "loading" &&
                                                userData.content &&
                                                `${userData.content[0].location_id.mailing_address}${
                                                    userData.content[0].location_id.mailing_address_2 &&
                                                    "," + userData.content[0].location_id.mailing_address_2
                                                }, ${userData.content[0].location_id.mailing_city}, ${
                                                    userData.content[0].location_id.mailing_state
                                                } ${userData.content[0].location_id.mailing_zip}`}
                                        </dd>
                                        <dd className={"mt-1 text-sm text-gray-900"}>
                                            <span
                                                onClick={() =>
                                                    window.open(
                                                        `https://maps.google.com/?q=${
                                                            userData.content[0].location_id.mailing_address
                                                        }${
                                                            userData.content[0].location_id.mailing_address_2 &&
                                                            "," + userData.content[0].location_id.mailing_address_2
                                                        }, ${userData.content[0].location_id.mailing_city}, ${
                                                            userData.content[0].location_id.mailing_state
                                                        } ${userData.content[0].location_id.mailing_zip}`
                                                    )
                                                }
                                                className="inline-flex cursor-pointer items-center rounded border border-transparent bg-blue-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            >
                                                Navigate
                                            </span>
                                        </dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Work Order Status</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {userData.content[0].status === 0 && <>Not Started</>}
                                            {userData.content[0].status === 1 && <>In Progress</>}
                                            {userData.content[0].status === 2 && <>Completed</>}
                                        </dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Work Type</dt>
                                        <dd className="mt-1 text-sm text-gray-900">{userData.content[0].type}</dd>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">
                                            Time Spent On This Work Order
                                        </dt>
                                        {userData.content[0].status !== 2 && !userData.content[0].start_time && (
                                            <dd className="mt-1 text-sm text-gray-900">Not Started Yet</dd>
                                        )}
                                        {userData.content[0].status === 2 && !userData.content[0].start_time && (
                                            <dd className="mt-1 text-sm text-gray-900">Time not tracked on this job</dd>
                                        )}
                                        {userData.content[0].start_time && (
                                            <dd className="mt-1 text-sm text-gray-900">
                                                Start Time:{" "}
                                                {new Date(userData.content[0].start_time).toLocaleTimeString()}
                                            </dd>
                                        )}
                                        {userData.content[0].end_time && (
                                            <dd className="mt-1 text-sm text-gray-900">
                                                End Time: {new Date(userData.content[0].end_time).toLocaleTimeString()}
                                            </dd>
                                        )}

                                        {/*{userData.content[0].end_time &&*/}
                                        {/*    <dd className="mt-1 text-sm text-gray-900">*/}
                                        {/*        End Time: {new Date(userData.content[0].end_time).toLocaleTimeString()}*/}
                                        {/*    </dd>}*/}
                                        {userData.content[0].status !== 2 && !userData.content[0].start_time && (
                                            <dd className="mt-3 text-sm text-gray-900">
                                                <button
                                                    onClick={() => {
                                                        modifyWorkOrderAsyncNoRedirect(workorderID, {
                                                            status: 1,
                                                            start_time: new Date().toISOString(),
                                                        });
                                                        setUserData((oldUserData) => {
                                                            return {
                                                                content: [
                                                                    {
                                                                        ...oldUserData.content[0],
                                                                        status: 1,
                                                                        start_time: new Date().toISOString(),
                                                                    },
                                                                ],
                                                            };
                                                        });
                                                    }}
                                                    className="inline-flex items-center rounded border border-transparent bg-green-400 px-2.5 py-1.5 text-xs font-medium text-black shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                                >
                                                    Start Timer
                                                </button>
                                            </dd>
                                        )}

                                        {userData.content[0].status !== 2 &&
                                            userData.content[0].start_time &&
                                            !userData.content[0].end_time && (
                                                <dd className="mt-3 text-sm text-gray-900">
                                                    <button
                                                        onClick={() => {
                                                            modifyWorkOrderAsyncNoRedirect(workorderID, {
                                                                status: 1,
                                                                end_time: new Date().toISOString(),
                                                            });
                                                            setUserData((oldUserData) => {
                                                                return {
                                                                    content: [
                                                                        {
                                                                            ...oldUserData.content[0],
                                                                            status: 1,
                                                                            end_time: new Date().toISOString(),
                                                                        },
                                                                    ],
                                                                };
                                                            });
                                                        }}
                                                        className="inline-flex items-center rounded border border-transparent bg-red-400 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                    >
                                                        Stop Timer
                                                    </button>
                                                </dd>
                                            )}
                                        {user.account_level > 1 && userData.content[0].status !== 2 && (
                                            <dd className="mt-3 text-sm text-gray-900">
                                                <span
                                                    onClick={() => {
                                                        modifyWorkOrderAsyncNoRedirect(workorderID, {
                                                            status: 0,
                                                            start_time: null,
                                                            end_time: null,
                                                        });
                                                        setUserData((oldUserData) => {
                                                            return {
                                                                content: [
                                                                    {
                                                                        ...oldUserData.content[0],
                                                                        status: 0,
                                                                        start_time: null,
                                                                        end_time: null,
                                                                    },
                                                                ],
                                                            };
                                                        });
                                                    }}
                                                    className="cursor-pointer font-medium text-blue-600 hover:text-blue-500"
                                                >
                                                    [Admin Only] Reset Timer
                                                </span>
                                            </dd>
                                        )}
                                    </div>
                                    {user.account_level > 1 && (
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Date Scheduled</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                {!userData.content[0].date_scheduled && <>Date not scheduled</>}
                                                {userData !== "loading" &&
                                                    userData.content[0].date_scheduled &&
                                                    userData.content && (
                                                        <span>
                                                            {(() => {
                                                                const previousDate = new Date(
                                                                    userData.content[0].date_scheduled
                                                                );
                                                                previousDate.setDate(previousDate.getDate() + 1);
                                                                return previousDate.toDateString();
                                                            })()}
                                                        </span>
                                                    )}
                                            </dd>
                                        </div>
                                    )}

                                    {user.account_level > 1 && (
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Filed Status</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                {!userData.content[0].filed_status && (
                                                    <>
                                                        <div className={"mb-3"}>Not Filed</div>
                                                        <button
                                                            onClick={() => {
                                                                modifyWorkOrderAsyncNoRedirect(workorderID, {
                                                                    filed_status: true,
                                                                });
                                                                setUserData((oldUserData) => {
                                                                    return {
                                                                        content: [
                                                                            {
                                                                                ...oldUserData.content[0],
                                                                                filed_status: true,
                                                                            },
                                                                        ],
                                                                    };
                                                                });
                                                            }}
                                                            className="inline-flex items-center rounded border border-transparent bg-green-400 px-2.5 py-1.5 text-xs font-medium text-black shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                                        >
                                                            Mark As Filed
                                                        </button>
                                                    </>
                                                )}
                                                {userData.content[0].filed_status && (
                                                    <>
                                                        <div className={"mb-3"}>Filed</div>
                                                        <button
                                                            onClick={() => {
                                                                modifyWorkOrderAsyncNoRedirect(workorderID, {
                                                                    filed_status: false,
                                                                });
                                                                setUserData((oldUserData) => {
                                                                    return {
                                                                        content: [
                                                                            {
                                                                                ...oldUserData.content[0],
                                                                                filed_status: false,
                                                                            },
                                                                        ],
                                                                    };
                                                                });
                                                            }}
                                                            className="inline-flex items-center rounded border border-transparent bg-red-400 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                                        >
                                                            Mark Not Filed
                                                        </button>
                                                    </>
                                                )}
                                            </dd>
                                        </div>
                                    )}
                                    <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">About</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <pre>{userData.content[0].notes}</pre>
                                        </dd>
                                    </div>
                                    {user.account_level > 1 && (
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500 flex items-center justify-between">
                                                Attached Location
                                                {/* <button
                                        type="button"
                                        className="inline-flex items-center rounded border border-transparent bg-blue-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                    >
                                        Add Location
                                    </button> */}
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                <Loader loading={userData === "loading"}>
                                                    {userData !== "loading" && userData.content && (
                                                        <ul
                                                            role="list"
                                                            className="divide-y divide-gray-200 rounded-md border border-gray-200"
                                                        >
                                                            <li
                                                                key={userData.content[0].location_id._id}
                                                                className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                                                            >
                                                                <div className="flex w-0 flex-1 items-center">
                                                                    <HomeIcon
                                                                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                                                                        aria-hidden="true"
                                                                    />
                                                                    <span className="ml-2 w-0 flex-1 truncate">
                                                                        {
                                                                            userData.content[0].location_id
                                                                                .mailing_address
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className="ml-4 flex-shrink-0">
                                                                    <Link
                                                                        to={`/locations/${userData.content[0].location_id._id}`}
                                                                        className="font-medium text-blue-600 hover:text-blue-500"
                                                                    >
                                                                        View
                                                                    </Link>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    )}

                                                    {workOrderLocations !== "loading" &&
                                                        workOrderLocations.length < 1 && (
                                                            <>
                                                                <div className="text-center py-8">
                                                                    <svg
                                                                        className="mx-auto h-12 w-12 text-gray-400"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor"
                                                                        aria-hidden="true"
                                                                    >
                                                                        <path
                                                                            vectorEffect="non-scaling-stroke"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            strokeWidth={2}
                                                                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                                                        />
                                                                    </svg>
                                                                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                                                                        No Locations
                                                                    </h3>
                                                                    <p className="mt-1 text-sm text-gray-500">
                                                                        This workOrder has no locations on profile.
                                                                    </p>
                                                                </div>
                                                            </>
                                                        )}
                                                </Loader>
                                            </dd>
                                        </div>
                                    )}

                                    {user.account_level > 1 && (
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500 flex items-center justify-between">
                                                Assigned Driver
                                                <button
                                                    type="button"
                                                    className="inline-flex items-center rounded border border-transparent bg-blue-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                    onClick={() => {
                                                        setDriverModal(true);
                                                    }}
                                                >
                                                    Add Driver
                                                </button>
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                <Loader loading={workOrderAssignments === "loading"}>
                                                    {workOrderAssignments !== "loading" &&
                                                        workOrderAssignments.content &&
                                                        workOrderAssignments.content.length > 0 && (
                                                            <>
                                                                <ul
                                                                    role="list"
                                                                    className="divide-y divide-gray-200 rounded-md border border-gray-200"
                                                                >
                                                                    {workOrderAssignments.content.map(
                                                                        (workOrderAssignment) => (
                                                                            <>
                                                                                <li
                                                                                    key={workOrderAssignment._id}
                                                                                    className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                                                                                >
                                                                                    <div className="flex w-0 flex-1 items-center">
                                                                                        <XCircleIcon
                                                                                            className="h-5 w-5 flex-shrink-0 text-gray-400 cursor-pointer"
                                                                                            aria-hidden="true"
                                                                                            onClick={() => {
                                                                                                setWorkOrderAssignments(
                                                                                                    (
                                                                                                        oldAssignments
                                                                                                    ) => ({
                                                                                                        content:
                                                                                                            oldAssignments.content.filter(
                                                                                                                (
                                                                                                                    item
                                                                                                                ) =>
                                                                                                                    item._id !==
                                                                                                                    workOrderAssignment._id
                                                                                                            ),
                                                                                                    })
                                                                                                );
                                                                                                deleteWorkOrderAssignmentAsyncNoRedirect(
                                                                                                    workOrderAssignment._id
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                        <span className="ml-2 w-0 flex-1 truncate">
                                                                                            {
                                                                                                workOrderAssignment
                                                                                                    .user_id.name
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="ml-4 flex-shrink-0">
                                                                                        <Link
                                                                                            to={`/users/${workOrderAssignment.user_id._id}`}
                                                                                            className="font-medium text-blue-600 hover:text-blue-500"
                                                                                        >
                                                                                            View
                                                                                        </Link>
                                                                                    </div>
                                                                                </li>
                                                                            </>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </>
                                                        )}

                                                    {workOrderAssignments !== "loading" &&
                                                        workOrderAssignments.content &&
                                                        workOrderAssignments.content.length < 1 && (
                                                            <>
                                                                <div className="text-center py-8">
                                                                    <svg
                                                                        className="mx-auto h-12 w-12 text-gray-400"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor"
                                                                        aria-hidden="true"
                                                                    >
                                                                        <path
                                                                            vectorEffect="non-scaling-stroke"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            strokeWidth={2}
                                                                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                                                        />
                                                                    </svg>
                                                                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                                                                        No Drivers Assigned
                                                                    </h3>
                                                                    <p className="mt-1 text-sm text-gray-500">
                                                                        Click "Add Driver" to assign a driver to this
                                                                        Work Order.
                                                                    </p>
                                                                </div>
                                                            </>
                                                        )}
                                                </Loader>
                                            </dd>
                                        </div>
                                    )}

                                    <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500 flex items-center justify-between">
                                            Parts & Repairs On This Work Order
                                            <button
                                                type="button"
                                                className="inline-flex items-center rounded border border-transparent bg-blue-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                onClick={() => {
                                                    setPartSelectModal(true);
                                                }}
                                            >
                                                Add Part / Repair
                                            </button>
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            <Loader loading={partsListWorkOrderPage === "loading"}>
                                                {partsListWorkOrderPage !== "loading" &&
                                                    partsListWorkOrderPage.content &&
                                                    partsListWorkOrderPage.content.length > 0 && (
                                                        <>
                                                            <ul
                                                                role="list"
                                                                className="divide-y divide-gray-200 rounded-md border border-gray-200"
                                                            >
                                                                {partsListWorkOrderPage.content.map(
                                                                    (workOrderPart, i) => (
                                                                        <>
                                                                            <li
                                                                                key={workOrderPart.part_ref._id}
                                                                                className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                                                                            >
                                                                                <div className="flex w-0 flex-1 items-center">
                                                                                    <XCircleIcon
                                                                                        className="h-5 w-5 flex-shrink-0 text-gray-400 cursor-pointer"
                                                                                        aria-hidden="true"
                                                                                        onClick={() => {
                                                                                            setPartsListWorkOrderPage(
                                                                                                (oldData) => {
                                                                                                    return {
                                                                                                        content: [
                                                                                                            ...oldData.content.filter(
                                                                                                                (
                                                                                                                    item
                                                                                                                ) =>
                                                                                                                    item._id !==
                                                                                                                    workOrderPart._id
                                                                                                            ),
                                                                                                        ],
                                                                                                    };
                                                                                                }
                                                                                            );
                                                                                            deletePartsWorkOrderAsyncNoRedirect(
                                                                                                workOrderPart._id
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    <span className="ml-2 w-0 flex-1 truncate">
                                                                                        {
                                                                                            workOrderPart.part_ref
                                                                                                .itemNumber
                                                                                        }{" "}
                                                                                        {
                                                                                            workOrderPart.part_ref
                                                                                                .itemDescription
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div className="ml-4 flex-shrink-0">
                                                                                    <span>
                                                                                        <div className="relative mt-2 rounded-md shadow-sm">
                                                                                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"></div>
                                                                                            <input
                                                                                                type="text"
                                                                                                value={
                                                                                                    partsListWorkOrderPage
                                                                                                        .content[i]
                                                                                                        .quantity
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    setPartsListWorkOrderPage(
                                                                                                        () => {
                                                                                                            let newPartsModified =
                                                                                                                partsListWorkOrderPage.content;
                                                                                                            newPartsModified[
                                                                                                                i
                                                                                                            ].quantity =
                                                                                                                e.target.value;
                                                                                                            if (
                                                                                                                !e
                                                                                                                    .target
                                                                                                                    .value ||
                                                                                                                e.target.value.trim() ===
                                                                                                                    ""
                                                                                                            ) {
                                                                                                                e.target.value = 0;
                                                                                                            }
                                                                                                            modifyPartsWorkOrderAsyncNoRedirect(
                                                                                                                workOrderPart._id,
                                                                                                                {
                                                                                                                    quantity:
                                                                                                                        parseInt(
                                                                                                                            e
                                                                                                                                .target
                                                                                                                                .value
                                                                                                                        ),
                                                                                                                }
                                                                                                            );
                                                                                                            return {
                                                                                                                content:
                                                                                                                    [
                                                                                                                        ...newPartsModified,
                                                                                                                    ],
                                                                                                            };
                                                                                                        }
                                                                                                    );
                                                                                                }}
                                                                                                className="block max-w-sm w-full rounded-md border-0 py-1.5 pl-1 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-3"
                                                                                                style={{
                                                                                                    maxWidth: 112,
                                                                                                }}
                                                                                                placeholder="0"
                                                                                            />
                                                                                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                                                                <span className="text-gray-500 sm:text-sm">
                                                                                                    Used
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    )
                                                                )}
                                                            </ul>
                                                        </>
                                                    )}

                                                {partsListWorkOrderPage !== "loading" &&
                                                    partsListWorkOrderPage.content &&
                                                    partsListWorkOrderPage.content.length < 1 && (
                                                        <>
                                                            <div className="text-center py-8">
                                                                <svg
                                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    stroke="currentColor"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path
                                                                        vectorEffect="non-scaling-stroke"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth={2}
                                                                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                                                    />
                                                                </svg>
                                                                <h3 className="mt-2 text-sm font-medium text-gray-900">
                                                                    None Used
                                                                </h3>
                                                                <p className="mt-1 text-sm text-gray-500">
                                                                    Click "Add Parts / Repairs" to add to this Work
                                                                    Order.
                                                                </p>
                                                            </div>
                                                        </>
                                                    )}
                                            </Loader>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </section>
                </EmptyContentPage>
            )}
        </Loader>
    );
}
