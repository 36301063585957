import React from 'react';
import axios from 'axios';
import { fnFetchAllSubject, fnModifyAsyncRedirect, fnFetchSubjectID, fnDeleteSubjectID, fnPostToSubject } from './CrudContextProviderUtil';
export const CustomerDataContext = React.createContext();

const userAxios = axios.create();

userAxios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
})

export const fnCreateNewCustomerLocation = (axiosObj, path, errorStateSetter = function (e) {
    console.log(e);
}) => {
    return (postObj, navigation) => {
        axiosObj.post(`/api/${path}`, postObj)
            .then(res => {
                navigation(`/locations/${res.data}`);
            })
            .catch(err => errorStateSetter(err));
    }
}

export default function UserDataProvider(props) {
    return (
        <CustomerDataContext.Provider value={ 
            {
                modifyCustomerAsync: fnModifyAsyncRedirect(userAxios, "customer"),
                fetchAllCustomerAsync: fnFetchAllSubject(userAxios, "customer"),
                fetchCustomerAsync: fnFetchSubjectID(userAxios, "customer"),
                fetchAllCustomerComments: fnFetchSubjectID(userAxios, "customer/note/fetchall"),
                deleteCustomerComment: fnDeleteSubjectID(userAxios, "maplocationcustomer"),
                postCustomerComment: fnPostToSubject(userAxios, "customer/note"),
                postNewCustomer: fnPostToSubject(userAxios, "customer"),
                fetchCustomerLocationsAsync: fnFetchSubjectID(userAxios, "maplocationcustomer/customer"),
                createNewCustomerLocation: fnCreateNewCustomerLocation(userAxios, "customer/create"), /** Custom creation for location + Customer at same time **/
            } }>
            { props.children }
        </CustomerDataContext.Provider>
    )
}
