import { Fragment, useContext } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import { UserContext } from "../../context/UserProvider";

export default function ApplicationShell(props) {
  const location = useLocation();
  const { logout } = useContext(UserContext);
  const { user } = useContext(UserContext);
  // const user = {
  //   role: <Bars3Icon className="h-6 w-6" aria-hidden="true" />,
  //   email: "admin@irrigationplus.net",
  // };
  const currentPathName = (url) =>
    location.pathname.trim() === "/" + url.trim();
  const navigation = [
    // { name: "Dashboard", href: "/", current: currentPathName("") },
    {
      name: "Work Orders",
      href: "/workorders",
      current: currentPathName("workorders"),
      allowedRoles: [1, 2, 3],
    },
    {
      name: "Parts / Repairs",
      href: "/parts",
      current: currentPathName("parts"),
      allowedRoles: [2, 3],
    },
    // {
    //   name: "Customers",
    //   href: "/customers",
    //   current: currentPathName("customers"),
    //   allowedRoles: [2, 3],
    // },
    {
      name: "Customers",
      href: "/locations",
      current: currentPathName("locations"),
      allowedRoles: [2, 3],
    },
    {
      name: "Users",
      href: "/users",
      current: currentPathName("users"),
      allowedRoles: [2, 3],
    },
    // {
    //   name: "ReportsOutlet",
    //   href: "/reports",
    //   current: currentPathName("reports"),
    //   allowedRoles: [2, 3],
    // },
  ];
  const userNavigation = [
    // { name: "Your Profile", href: "#" },
    // { name: "Settings", href: "#" },
    {
      name: "Sign out",
      href: () => {
        logout();
      },
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-white shadow">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-20"
                        src={logo}
                        alt="Your Company"
                      />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => {
                          return (
                            <>
                              {item.allowedRoles.includes(
                                parseInt(user.account_level)
                              ) && (
                                <Link
                                  key={item.name}
                                  to={item.href}
                                  className={classNames(
                                    item.current
                                      ? "bg-gray-200 text-black"
                                      : "text-White hover:bg-gray-200 hover:text-black",
                                    "px-3 py-2 rounded-md text-sm font-bold"
                                  )}
                                  aria-current={
                                    item.current ? "page" : undefined
                                  }
                                >
                                  {item.name}
                                </Link>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="ml-4 flex items-right md:ml-6">
                    <button
                      onClick={() => logout()}
                      className="hidden md:inline-flex ml-3 items-right px-3 py-2 border border-transparent text-sm font-bold rounded-md text-gray-600 bg-white hover:text-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
                    >
                      Sign out
                    </button>
                  </div>

                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-600 hover:bg-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-100">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                  {navigation.map((item) => item.allowedRoles.includes(
                      parseInt(user.account_level)
                  ) && (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-gray-100 text-gray-600"
                          : "text-gray-800 hover:bg-gray-100 hover:text-black",
                        "block px-3 py-2 rounded-md text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-700 pt-0 pb-3">
                  <div className="flex items-center px-5 hidden md:block">
                    <div>
                      <div className="text-base font-medium leading-none text-black mb-1">
                        {user.role}
                      </div>
                      <div className="text-sm font-medium leading-none text-gray-400">
                        {user.email}
                      </div>
                    </div>
                    {/*<button*/}
                    {/*  type="button"*/}
                    {/*  className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white"*/}
                    {/*>*/}
                    {/*  <span className="sr-only">View notifications</span>*/}
                    {/*  <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                    {/*</button>*/}
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="span"
                        onClick={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-800 hover:bg-gray-700 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <header className="bg-none">
          <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-600">
              {props.title}
            </h1>
          </div>
        </header>
        <main>
          <div className="mx-auto max-w-7xl pb-2 sm:px-6 lg:px-8">
            <div className="z-0 x-4 pb-2 sm:px-0">{props.children}</div>
          </div>
        </main>
      </div>
    </>
  );
}
