import {Outlet} from "react-router-dom";
import PartDataProvider from "../context/PartDataProvider";

const ReportsOutlet = () => {
    return (
        <PartDataProvider>
            <Outlet/>
        </PartDataProvider>
    );
}

export default ReportsOutlet;
