import React, {useContext, useState} from "react";
import ModalForm1 from "../../molecules/ModalForm1";
import TextInput from "../../atoms/Inputs/TextInput";
import Button from "../../atoms/Buttons/Button";
import DescriptionListRowWithLabel from "../../molecules/DescriptionListRowWithLabel";
import {useNavigate} from "react-router-dom";
import ErrorBox from "../../molecules/ErrorBox";
import {CustomerDataContext} from "../../../context/CustomerDataProvider";
import Loader from "../../atoms/Loader";
import {LocationDataContext} from "../../../context/LocationDataProvider";

const CreateNewCustomerAndLocation = () => {

    const [postObj, setPostObj] = useState({
        /** Customer **/
        name: "",
        phone: "",
        email: "",
        about: "",

        /** Location **/
        mailing_address: "",
        mailing_address_2: "",
        mailing_city: "",
        mailing_state: "",
        mailing_zip: "",
        location_type: "Residential"

    });
    const [isLoading, setIsLoading] = useState(false);
    const [issues, setIssues] = useState([]);
    const navigate = useNavigate();

    const {fnPostAsyncRedirect} = useContext(LocationDataContext);

    const handleSubmission = () => {
        setIsLoading(true); /** Disable form, show loading so that it cannot be double-submitted **/
        let okay = true;

        setIssues([]);
        if(postObj.name.trim() === "") {
            okay = false; setIssues(oldIssues => [...oldIssues, "Please specify a Name"]);
        }
        if(postObj.mailing_address.trim() === "") {
            okay = false; setIssues(oldIssues => [...oldIssues, "Please specify a address"]);
        }
        if(postObj.mailing_city.trim() === "") {
            okay = false; setIssues(oldIssues => [...oldIssues, "Please specify a city"]);
        }
        if(postObj.mailing_state.trim() === "") {
            okay = false; setIssues(oldIssues => [...oldIssues,  "Please specify a state"]);
        }
        if(postObj.mailing_zip.trim() === "") {
            okay = false; setIssues(oldIssues => [...oldIssues, "Please specify a zip"]);
        }

        if(okay) {
            fnPostAsyncRedirect(postObj, "/locations", navigate);
        } else {
            setIsLoading(false);
        }
    }

    return (

        <Loader loading={isLoading === true}>
            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Customer Information</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Enter basic information for this customer</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                        <DescriptionListRowWithLabel label={"Name"}>
                            <TextInput name="name" placeholder="name" value={postObj.name} onChange={(e) => setPostObj({...postObj, name: e.target.value})}/>
                        </DescriptionListRowWithLabel>

                        <DescriptionListRowWithLabel label={"Phone Number"}>
                            <TextInput name="phone" placeholder="ex, 270-123-4567" value={postObj.phone} onChange={(e) => setPostObj({...postObj, phone: e.target.value})}/>
                        </DescriptionListRowWithLabel>

                        <DescriptionListRowWithLabel label={"Email"}>
                            <TextInput name="email" placeholder="ex, irrigation_plus@hotmail.com" value={postObj.email} onChange={(e) => setPostObj({...postObj, email: e.target.value})}/>
                        </DescriptionListRowWithLabel>

                        {/*Note: This might not be needed, just want to keep this in here incase they ask for it...*/}
                        {/*<DescriptionListRowWithLabel label={"Currently Servicing"}>*/}
                        {/*    <select*/}
                        {/*        id="servicing"*/}
                        {/*        name="servicing"*/}
                        {/*        className="mt-1 mb-3 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"*/}
                        {/*    >*/}
                        {/*        <option value="Yes"*/}
                        {/*        >Servicing*/}
                        {/*        </option>*/}
                        {/*        <option value="No">Not*/}
                        {/*            Servicing*/}
                        {/*        </option>*/}
                        {/*    </select>*/}
                        {/*</DescriptionListRowWithLabel>*/}

                        <DescriptionListRowWithLabel label={"About"}>
                         <textarea
                             id="about"
                             name="about"
                             rows={3}
                             className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                             placeholder="Write about this customer for the customer hub page"
                             value={postObj.about} onChange={(e) => setPostObj({...postObj, about: e.target.value})}
                         />
                        </DescriptionListRowWithLabel>

                    </dl>
                </div>
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">Address Information</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Enter address information for this customer</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">

                        <DescriptionListRowWithLabel label={"Address Line 1"}>
                            <TextInput name="mailing_address_1" placeholder="ex, 123 Main St." value={postObj.mailing_address} onChange={(e) => setPostObj({...postObj, mailing_address: e.target.value})}/>
                        </DescriptionListRowWithLabel>

                        <DescriptionListRowWithLabel label={"Address Line 2"}>
                            <TextInput name="mailing_address_2" placeholder="ex, Apt 22, ..." value={postObj.mailing_address_2} onChange={(e) => setPostObj({...postObj, mailing_address_2: e.target.value})}/>
                        </DescriptionListRowWithLabel>

                        <DescriptionListRowWithLabel label={"City"}>
                            <TextInput name="mailing_address_2" placeholder="ex, Brandenburg" value={postObj.mailing_city} onChange={(e) => setPostObj({...postObj, mailing_city: e.target.value})}/>
                        </DescriptionListRowWithLabel>

                        <DescriptionListRowWithLabel label={"State"}>
                            <TextInput name="mailing_address_2" placeholder="ex, KY" value={postObj.mailing_state} onChange={(e) => setPostObj({...postObj, mailing_state: e.target.value})}/>
                        </DescriptionListRowWithLabel>

                        <DescriptionListRowWithLabel label={"Zip"}>
                            <TextInput name="mailing_address_2" placeholder="ex, 40175" value={postObj.mailing_zip} onChange={(e) => setPostObj({...postObj, mailing_zip: e.target.value})}/>
                        </DescriptionListRowWithLabel>


                        <DescriptionListRowWithLabel label={"Location Type"}>
                            <select
                                id="locationType"
                                name="locationType"
                                className="mt-1 mb-3 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                                onChange={(e) => setPostObj({...postObj, location_type: e.target.value})}
                            >
                                {/*<option value="" disabled={true}>*/}
                                {/*    Chose one...*/}
                                {/*</option>*/}
                                <option value="Residential">Residential</option>
                                <option value="Commercial">Commercial</option>
                                <option value="Other">Other</option>
                            </select>
                        </DescriptionListRowWithLabel>


                        <DescriptionListRowWithLabel label={"Actions"}>
                            {issues.length > 0 && <div className={"mb-3"}>
                                <ErrorBox errorsArr={issues}/>
                            </div>}
                            <Button type="lg" color="blue" onClick={() => handleSubmission()}
                                    className="mr-2">Save</Button>
                            <Button type="lg" color="red" onClick={() => navigate("/locations")}>Cancel</Button>
                        </DescriptionListRowWithLabel>
                    </dl>
                </div>
            </div>
        </Loader>

    );
}

export default CreateNewCustomerAndLocation;
