import UserDataProvider from "../context/UserDataProvider";
import {Outlet} from "react-router-dom";

const ReportsOutlet = () => {
    return (
        <UserDataProvider>
            <Outlet/>
        </UserDataProvider>
    );
}

export default ReportsOutlet;
