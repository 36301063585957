import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Loader from "../../atoms/Loader";
import {UserContext} from "../../../context/UserProvider";
import {UserDataContext} from "../../../context/UserDataProvider";
import Unauthorized from "../Unauthorized";
import {Cog6ToothIcon, PencilSquareIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {getAccountAccessLabel} from "../../../utils/ReturnText";
import ModalForm1 from "../../molecules/ModalForm1";
import ErrorBox from "../../molecules/ErrorBox";

export default function UserProfile() {
    let {userID} = useParams();
    const [userData, setUserData] = useState("loading");
    const {errMsg, resetAuthErr} = useContext(UserContext);
    const {getUserById, fnDeleteSubjectID, fetchAllWorkOrdersToday} =
        useContext(UserDataContext);
    const [isOpen, setIsOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);
    const [userJobsToday, setUserjobsToday] = useState("loading");
    const navigate = useNavigate();

    function openModal() {
        setErrorMessage([]); // Reset
        setIsOpen(true);
    }

    const handleNoClick = () => {
        setIsOpen(false);
    };

    const handleYesClick = () => {
        setIsOpen(false);
        fnDeleteSubjectID(userID, "/users", navigate);
    };

    useEffect(() => {
        resetAuthErr();
        getUserById(userID, setUserData);
        fetchAllWorkOrdersToday(setUserjobsToday, userID);
    }, []);

    return (
        <Loader loading={userData === "loading"}>
            {userData !== "loading" && userData.errMsg ? (
                <Unauthorized/>
            ) : (
                <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="flex justify-end h-0">
                            <Link
                                to={`/users`}
                                className="mt-1 max-w-2xl text-sm text-blue-500 inline-flex justify-content-center"
                            >
                                Back
                            </Link>
                        </div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            User Information for {userData[0].name}
                        </h3>
                        <Link
                            to={`/users/edit/${userID}`}
                            className="mt-1 max-w-2xl text-sm text-blue-500 inline-flex justify-content-center mr-5 cursor-pointer"
                        >
                            <PencilSquareIcon className="w-4 inline mr-1"/> Edit
                        </Link>
                        <a
                            onClick={openModal}
                            className="mt-1 max-w-2xl text-sm text-red-500 inline-flex justify-content-center cursor-pointer"
                        >
                            <XCircleIcon className="w-4 inline mr-1"/> Delete
                        </a>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Personal details and login information.
                        </p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                        <dl className="sm:divide-y sm:divide-gray-200">
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Name</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {userData[0].name}
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Username</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {userData[0].username}
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                    Account Access
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {getAccountAccessLabel(userData[0].account_level)}
                                </dd>
                            </div>
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                    Member Since
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {new Date(userData[0].member_since).toDateString()}
                                </dd>
                            </div>

                            <ModalForm1 open={isOpen} setOpen={setIsOpen}>
                                <p className="my-5 text-center font-semibold ">Are you sure?</p>
                                <div className="w-full max-w-xs">
                                    <div className="mt-5 float-right">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-8 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={handleNoClick}
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-red-600 px-8 py-2 text-sm font-medium text-white hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                            onClick={handleYesClick}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </ModalForm1>

                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                    Work Orders For The Day ({new Date().toLocaleDateString()})
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {console.log(userJobsToday)}
                                    <Loader loading={userJobsToday === "loading"}>
                                        {userJobsToday !== "loading" &&
                                            userJobsToday.content.length === 0 && (
                                                <>
                                                    <div className="text-center py-8">
                                                        <svg
                                                            className="mx-auto h-12 w-12 text-gray-400"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                vectorEffect="non-scaling-stroke"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth={2}
                                                                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                                            />
                                                        </svg>
                                                        <h3 className="mt-2 text-sm font-medium text-gray-900">
                                                            No Routes
                                                        </h3>
                                                        <p className="mt-1 text-sm text-gray-500">
                                                            This user does not have any jobs scheduled for
                                                            today.
                                                        </p>
                                                    </div>
                                                </>
                                            )}
                                        {userJobsToday.content && userJobsToday.content.errMsg && (
                                            <>
                                                <ErrorBox mainMessage={"Issues Loading Work Orders"}
                                                          errorsArr={[userJobsToday.content.violations.length + " workorder(s) have incorrect addresses.", "Incorrect List (listed below): ", ...userJobsToday.content.violations.map((violation, i) => {
                                                              return (i > 0 ? ", " : " ") + violation.name + " (" + violation.mailing_address + ")";
                                                          })]}/>
                                            </>
                                        )}
                                        {userJobsToday !== "loading" && userJobsToday.content && userJobsToday.content.errMsg === undefined && (
                                            <ul
                                                role="list"
                                                className="divide-y divide-gray-200 rounded-md border border-gray-200"
                                            >
                                                {userJobsToday.content.errMsg === undefined && userJobsToday.content.map((userJob) => (
                                                    <>
                                                        <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                                            <div className="flex w-0 flex-1 items-center">
                                                                <Cog6ToothIcon
                                                                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                                <span className="ml-2 w-0 flex-1 truncate">

            {userJob.workorder_id.location_id.name}{" - "}
                                                                    {userJob.workorder_id.location_id.mailing_address}{" "}
                                                                    (#{userJob.workorder_id._id.slice(-5).toUpperCase()})
                </span>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <Link
                                                                    to={`/workorders/${userJob.workorder_id._id}`}
                                                                    className="font-medium text-blue-600 hover:text-blue-500"
                                                                >
                                                                    View Work Order
                                                                </Link>
                                                            </div>
                                                        </li>
                                                    </>
                                                ))}
                                            </ul>
                                        )}
                                    </Loader>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            )}
        </Loader>
    );
}
