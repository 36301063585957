import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

export default function Pagination({
  fetchFunc,
  setFunc,
  page,
  pageCount,
  total,
  searchParams,
}) {
  const maxPage = Math.ceil(total / pageCount);

  if (maxPage <= 1) return null;

  let items = [];
  let pagesToShow = [];

  if (page <= 3) {
    // For pages 1 to 3, show first four pages and last page
    pagesToShow = [1, 2, 3, 4, maxPage];
  } else if (page >= maxPage - 2) {
    // For the last three pages, show first page and last three pages
    pagesToShow = [1, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
  } else {
    // For other pages, show first page, current - 1, current, current + 1, and last page
    pagesToShow = [1, page - 1, page, page + 1, maxPage];
  }

  pagesToShow.forEach((pageNum) => {
    items.push(renderPageItem(pageNum, page, fetchFunc, setFunc, searchParams, pageCount));
  });

  let startResult = (page - 1) * pageCount + 1;
  let endResult = Math.min(page * pageCount, total);

  return (
    <div>
      <div className="flex items-center justify-center text-center border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            {page > 1 && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  fetchFunc(setFunc, searchParams, pageCount, page - 1);
                }}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-sm font-semibold text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            )}
            {items}
            {page < maxPage && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  fetchFunc(setFunc, searchParams, pageCount, page + 1);
                }}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-sm font-semibold text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </a>
            )}
          </nav>
        </div>
      </div>
      <div className="text-center text-sm font-medium py-2">
        Displaying results {startResult} - {endResult} of {total}
      </div>
    </div>
  );
}

function renderPageItem(i, currentPage, fetchFunc, setFunc, searchParams, pageCount) {
  return (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        fetchFunc(setFunc, searchParams, pageCount, i);
      }}
      aria-current={currentPage === i ? "page" : undefined}
      className={
        currentPage === i
          ? "relative z-10 inline-flex items-center bg-blue-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
      }
      key={i}
    >
      {i}
    </a>
  );
}
