import React, {useContext, useEffect, useState} from "react";
import {
    CheckBadgeIcon,
    UserIcon,
    XCircleIcon,
} from "@heroicons/react/20/solid";
import {Link, useNavigate, useParams} from "react-router-dom";
import Loader from "../../atoms/Loader";
import {UserContext} from "../../../context/UserProvider";
import TextInput from "../../atoms/Inputs/TextInput";
import ModalForm1 from "../../molecules/ModalForm1";
import {Dialog, Switch} from "@headlessui/react";
import {LocationDataContext} from "../../../context/LocationDataProvider";
import DescriptionListRowWithLabel from "../../molecules/DescriptionListRowWithLabel";
import ErrorBox from "../../molecules/ErrorBox";
import Button from "../../atoms/Buttons/Button";

export default function EditUserProfile() {
    let {locationID} = useParams();
    const [userData, setUserData] = useState("loading");
    const [customerList, setCustomerList] = useState("loading");
    const [selectedCustomerList, setSelectedCustomerList] = useState("loading");
    const {errMsg, resetAuthErr} = useContext(UserContext);
    const {fetchCustomerDataEditPage, fnDeleteSubjectID, fnModifyCustomerData} = useContext(LocationDataContext);
    const [isUpdating, setIsUpdating] = useState(false);
    const navigate = useNavigate();
    const [openModal, SetOpenModal] = useState(false);
    const [customerSearch, setCustomerSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [issues, setIssues] = useState([]);

    function openDeleteModal() {
        setErrorMessage([]); // Reset
        setIsOpen(true);
    }

    const handleNoClick = () => {
        setIsOpen(false);
    };

    const handleYesClick = () => {
        setIsOpen(false);
        fnDeleteSubjectID(locationID, "/locations", navigate);
    };

    const handleSubmission = () => {
        setIsLoading(
            true
        );
        /** Disable form, show loading so that it cannot be double-submitted **/

        let okay = true;
        setIssues([]);

        if (userData.content[0].name.trim() === "") {
            okay = false;
            setIssues(oldIssues => [...oldIssues, "Please specify a Name"]);
        }
        if (userData.content[0].mailing_address.trim() === "") {
            okay = false;
            setIssues(oldIssues => [...oldIssues, "Please specify a address"]);
        }
        if (userData.content[0].mailing_city.trim() === "") {
            okay = false;
            setIssues(oldIssues => [...oldIssues, "Please specify a city"]);
        }
        if (userData.content[0].mailing_state.trim() === "") {
            okay = false;
            setIssues(oldIssues => [...oldIssues, "Please specify a state"]);
        }
        if (userData.content[0].mailing_zip.trim() === "") {
            okay = false;
            setIssues(oldIssues => [...oldIssues, "Please specify a zip"]);
        }

        if (okay) {
            fnModifyCustomerData(locationID, userData.content[0], navigate);
        } else {
            setIsLoading(false);
        }
    };

    let ArrOptionsForStatus = ["Not Paid", "Paid", "On", "Off"];

    useEffect(() => {
        resetAuthErr();
        fetchCustomerDataEditPage(setUserData, locationID);
    }, []);

    return (
        <Loader loading={isLoading === true}>
            {userData && userData.content && userData.content.length > 0 && (
                <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="flex justify-between items-center">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Customer Information
                                </h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                    Enter basic information for this customer.
                                </p>
                            </div>
                            <button
                                className="bg-red-400 text-white px-2 py-1 rounded hover:bg-red-500"
                                onClick={openDeleteModal}
                            >
                                Delete
                            </button>
                            <ModalForm1 open={isOpen} setOpen={setIsOpen}>
                                <p className="my-5 text-center font-semibold ">Are you sure?</p>
                                <div className="w-full max-w-xs">
                                    <div className="mt-5 float-right">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-8 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={handleNoClick} // Replace with your desired function
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="ml-5 inline-flex justify-center rounded-md border border-transparent bg-red-600 px-8 py-2 text-sm font-medium text-white hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                            onClick={handleYesClick} // Replace with your desired function
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </ModalForm1>
                        </div>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                        <dl className="sm:divide-y sm:divide-gray-200">
                            <DescriptionListRowWithLabel label={"Name"}>
                                <TextInput
                                    name="name"
                                    placeholder="name"
                                    value={userData.content[0].name}
                                    onChange={(e) =>
                                        setUserData({
                                            content: [
                                                {...userData.content[0], name: e.target.value},
                                            ],
                                        })
                                    }
                                />
                            </DescriptionListRowWithLabel>

                            <DescriptionListRowWithLabel label={"Phone Number"}>
                                <TextInput
                                    name="phone"
                                    placeholder="ex, 270-123-4567"
                                    value={userData.content[0].phone}
                                    onChange={(e) =>
                                        setUserData({
                                            content: [
                                                {...userData.content[0], phone: e.target.value},
                                            ],
                                        })
                                    }
                                />
                            </DescriptionListRowWithLabel>

                            <DescriptionListRowWithLabel label={"Email"}>
                                <TextInput
                                    name="email"
                                    placeholder="ex, irrigation_plus@hotmail.com"
                                    value={userData.content[0].email}
                                    onChange={(e) =>
                                        setUserData({
                                            content: [
                                                {...userData.content[0], email: e.target.value},
                                            ],
                                        })
                                    }
                                />
                            </DescriptionListRowWithLabel>

                            <DescriptionListRowWithLabel label={"Status"}>

                                <select
                                    id="status"
                                    name="status"
                                    className="mt-1 mb-3 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                                    onChange={(e) =>
                                        setUserData({
                                            content: [
                                                {
                                                    ...userData.content[0],
                                                    status: e.target.value,
                                                },
                                            ],
                                        })
                                    }
                                >
                                    {ArrOptionsForStatus.map(item => <option
                                            value={item}
                                            selected={userData.content[0].status && userData.content[0].status === item}
                                        >
                                            {item}
                                        </option>
                                    )}
                                </select>

                            </DescriptionListRowWithLabel>

                            {/*Note: This might not be needed, just want to keep this in here incase they ask for it...*/}
                            {/*<DescriptionListRowWithLabel label={"Currently Servicing"}>*/}
                            {/*    <select*/}
                            {/*        id="servicing"*/}
                            {/*        name="servicing"*/}
                            {/*        className="mt-1 mb-3 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"*/}
                            {/*    >*/}
                            {/*        <option value="Yes"*/}
                            {/*        >Servicing*/}
                            {/*        </option>*/}
                            {/*        <option value="No">Not*/}
                            {/*            Servicing*/}
                            {/*        </option>*/}
                            {/*    </select>*/}
                            {/*</DescriptionListRowWithLabel>*/}

                            <DescriptionListRowWithLabel label={"About"}>
                <textarea
                    id="about"
                    name="about"
                    rows={3}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    placeholder="Write about this customer for the customer hub page"
                    value={userData.content[0].about}
                    onChange={(e) =>
                        setUserData({
                            content: [
                                {...userData.content[0], about: e.target.value},
                            ],
                        })
                    }
                />
                            </DescriptionListRowWithLabel>
                        </dl>
                    </div>
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Address Information
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Enter address information for this customer
                        </p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                        <dl className="sm:divide-y sm:divide-gray-200">
                            <DescriptionListRowWithLabel label={"Address Line 1"}>
                                <TextInput
                                    name="mailing_address_1"
                                    placeholder="ex, 123 Main St."
                                    value={userData.content[0].mailing_address}
                                    onChange={(e) =>
                                        setUserData({
                                            content: [
                                                {
                                                    ...userData.content[0],
                                                    mailing_address: e.target.value,
                                                },
                                            ],
                                        })
                                    }
                                />
                            </DescriptionListRowWithLabel>

                            <DescriptionListRowWithLabel label={"Address Line 2"}>
                                <TextInput
                                    name="mailing_address_2"
                                    placeholder="ex, Apt 22, ..."
                                    value={userData.content[0].mailing_address_2}
                                    onChange={(e) =>
                                        setUserData({
                                            content: [
                                                {
                                                    ...userData.content[0],
                                                    mailing_address_2: e.target.value,
                                                },
                                            ],
                                        })
                                    }
                                />
                            </DescriptionListRowWithLabel>

                            <DescriptionListRowWithLabel label={"City"}>
                                <TextInput
                                    name="mailing_address_2"
                                    placeholder="ex, Brandenburg"
                                    value={userData.content[0].mailing_city}
                                    onChange={(e) =>
                                        setUserData({
                                            content: [
                                                {
                                                    ...userData.content[0],
                                                    mailing_city: e.target.value,
                                                },
                                            ],
                                        })
                                    }
                                />
                            </DescriptionListRowWithLabel>

                            <DescriptionListRowWithLabel label={"State"}>
                                <TextInput
                                    name="mailing_address_2"
                                    placeholder="ex, KY"
                                    value={userData.content[0].mailing_state}
                                    onChange={(e) =>
                                        setUserData({
                                            content: [
                                                {
                                                    ...userData.content[0],
                                                    mailing_state: e.target.value,
                                                },
                                            ],
                                        })
                                    }
                                />
                            </DescriptionListRowWithLabel>

                            <DescriptionListRowWithLabel label={"Zip"}>
                                <TextInput
                                    name="mailing_address_2"
                                    placeholder="ex, 40175"
                                    value={userData.content[0].mailing_zip}
                                    onChange={(e) =>
                                        setUserData({
                                            content: [
                                                {...userData.content[0], mailing_zip: e.target.value},
                                            ],
                                        })
                                    }
                                />
                            </DescriptionListRowWithLabel>

                            <DescriptionListRowWithLabel label={"Location Type"}>
                                <select
                                    id="locationType"
                                    name="locationType"
                                    className="mt-1 mb-3 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                                    onChange={(e) =>
                                        setUserData({
                                            content: [
                                                {
                                                    ...userData.content[0],
                                                    location_type: e.target.value,
                                                },
                                            ],
                                        })
                                    }
                                >
                                    {/*<option value="" disabled={true}>*/}
                                    {/*    Chose one...*/}
                                    {/*</option>*/}
                                    <option
                                        value="Residential"
                                        selected={
                                            userData.content[0].location_type == "Residential"
                                        }
                                    >
                                        Residential
                                    </option>
                                    <option
                                        value="Commercial"
                                        selected={userData.content[0].location_type == "Commercial"}
                                    >
                                        Commercial
                                    </option>
                                    <option
                                        value="Other"
                                        selected={userData.content[0].location_type == "Other"}
                                    >
                                        Other
                                    </option>
                                </select>
                            </DescriptionListRowWithLabel>

                            <DescriptionListRowWithLabel label={"Actions"}>
                                {issues.length > 0 && <div className={"mb-3"}>
                                    <ErrorBox errorsArr={issues}/>
                                </div>}
                                <Button
                                    type="lg"
                                    color="blue"
                                    onClick={() => handleSubmission()}
                                    className="mr-2"
                                >
                                    Save
                                </Button>
                                <Button
                                    type="lg"
                                    color="red"
                                    onClick={() => navigate("/locations")}
                                >
                                    Cancel
                                </Button>
                            </DescriptionListRowWithLabel>
                        </dl>
                    </div>
                </div>
            )}
        </Loader>
    );
}
